import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../reducers';
import CustomMetrics from '@features/custom-metrics';
import LoadingPlaceholder from '../../../components/LoaderContainer/LoadingPlaceholder';
import { AddonButton } from '../components/payment-details/AddonButton';
import { FeatureCode } from '@g17eco/core';
import { TrialModal } from '../components/payment-details/TrialModal';
import { CustomMetricUpgradeModal } from '../components/custom-metrics/CustomMetricUpgradeModal';
import { useGetInitiativeCustomMetricsUsageQuery } from '@api/custom-metrics';
import { DEFAULT_CUSTOM_METRICS_USAGE } from '@components/custom-metrics/utils';

export const CustomMetricManagementRoute = () => {
  const { initiativeId, groupId, view } = useParams<{ initiativeId: string; groupId?: string; view?: string }>();
  const {data: customMetricsUsage = DEFAULT_CUSTOM_METRICS_USAGE, isFetching, refetch} = useGetInitiativeCustomMetricsUsageQuery(initiativeId, { skip: !initiativeId })

  const globalData = useAppSelector((state) => state.globalData);

  if (!globalData.loaded || isFetching) {
    return <LoadingPlaceholder height={300} isLoading={true} />;
  }

  const buttons = [
    <AddonButton
      color='primary'
      outline={true}
      rootOrg={globalData.organization}
      appConfig={globalData.data.appConfig}
      featureCode={FeatureCode.CustomMetrics}
      TrialModalComponent={({ toggle }) => (
        <TrialModal toggle={toggle} initiativeId={globalData.organization._id}>
          During the free trial, the option to buy additional custom metrics is not available. To buy more custom
          metrics, first add credit card details or another payment method.
        </TrialModal>
      )}
      UpgradeModal={CustomMetricUpgradeModal}
    >
      Buy +10 metrics
    </AddonButton>,
  ];

  return (
    <CustomMetrics
      customMetricsUsage={customMetricsUsage}
      refetchMetricsUsage={() => refetch()}
      initiativeId={initiativeId}
      groupId={groupId}
      view={view}
      buttons={buttons}
    />
  );
};
