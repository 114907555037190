import { Button } from 'reactstrap';
import SimpleTooltip from '../simple-tooltip';
import { Tab } from './ContentTabs';

interface Props {
  tabs: Tab[];
  activeTab: Tab['navId'];
  handleChangeTab: (tab: Tab['navId']) => void;
}

export const ViewRadioButtons = ({ tabs, activeTab, handleChangeTab }: Props) => {
  return (
    <div className='flex-grow-1 d-flex gap-2'>
      {tabs.map((button) => (
        <SimpleTooltip
          key={button.navId}
          className='flex-grow-1'
          text={button.disabled ? `This metric does not support ${button.name}` : ''}
        >
          <Button
            disabled={button.disabled ?? false}
            color='primary'
            className={
              activeTab === button.navId ? 'background-ThemeInfoDark border-ThemeAccentExtradark w-100' : 'w-100'
            }
            outline={!(activeTab === button.navId)}
            onClick={() => handleChangeTab(button.navId)}
          >
            {button.name}
          </Button>
        </SimpleTooltip>
      ))}
    </div>
  );
};
