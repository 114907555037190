import { checkIsVideo } from '@features/custom-dashboard/utils/dashboard-utils';
import { MEDIA_TITLE_HEIGHT } from '@features/custom-dashboard/utils/media-utils';
import { GridDashboardMediaItem } from '@g17eco/types/insight-custom-dashboard';

interface Props {
  item: GridDashboardMediaItem;
  isEditing: boolean;
}

export const MediaWidget = ({ item, isEditing }: Props) => {
  const className = isEditing ? '' : 'border border-ThemeBorderDefault border-1 rounded-1';
  // Support showing 1 file for now.
  const file = item.files?.[0];
  if (!file) {
    return null;
  }

  return (
    <div className={`${className} w-100 h-100 d-flex flex-column`}>
      {checkIsVideo(file) ? (
        <video className='w-100 flex-grow-1' style={{ minHeight: 0 }} key={file.name} src={file.url} controls />
      ) : (
        <img
          style={{ objectFit: 'cover', objectPosition: 'center', minHeight: 0 }}
          className='w-100 flex-grow-1'
          key={file.name}
          src={file.url}
          alt={file.name}
        />
      )}
      {item.title ? (
        <div
          className='text-ThemeHeadingDark background-ThemeBgExtralight px-3 d-flex align-items-center'
          style={{ height: `${MEDIA_TITLE_HEIGHT}px` }}
        >
          <span className='text-truncate'>{item.title}</span>
        </div>
      ) : null}
    </div>
  );
};
