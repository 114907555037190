import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import { DataPeriods } from '../../../types/universalTracker';
import { getMonthOptions, getPeriodOptions, getYearDropdownOptions } from '../../../utils/date';
import { getFirstMonth, getFirstYear } from '../../../utils/surveyData';
import { ReactSelectOptions } from '../../survey/form/input/selectUtils';
import { ConfigFormData } from '../types';
import { DEFAULT_YEAR_RANGE, YEARS_BACK, YEARS_NEXT } from '../../../constants/date';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';

export const OPTIONS = {
  PERIOD: getPeriodOptions(),
  MONTH: getMonthOptions(),
  FIRST_YEAR: getYearDropdownOptions(DEFAULT_YEAR_RANGE),
  LAST_YEAR: getYearDropdownOptions(YEARS_BACK, YEARS_NEXT),
};
export interface OnChangeReportingProps {
  option: { value: DataPeriods | number | string } | null;
  code: string;
}

interface SurveyReportingSettingsProps {
  formDisabled?: boolean;
  isDisabled?: boolean;
  form: Pick<ConfigFormData, 'month' | 'year'> & { period?: DataPeriods };
  onChange?: ({ option, code }: OnChangeReportingProps) => void;
  reportingTitles?: {
    type: string;
    lastMonth: string;
    firstMonth: string;
  };
}

const defaultReportingTitles = {
  type: 'Reporting type',
  lastMonth: 'Reporting last month',
  firstMonth: 'Reporting first month',
};

export const SurveyReportingSettings = (props: SurveyReportingSettingsProps) => {
  const { formDisabled, isDisabled, form, onChange = () => { }, reportingTitles = defaultReportingTitles } = props;

  const selectedPeriod = OPTIONS.PERIOD.find((o: ReactSelectOptions) => o.value === form.period);
  const selectedLastMonth = OPTIONS.MONTH.find((o: ReactSelectOptions) => o.value === Number(form.month));
  const selectedLastYear = OPTIONS.LAST_YEAR.find((o) => o.value === form.year);

  const firstMonth = form.period && getFirstMonth(form.month, form.period);
  const selectedFirstMonth = OPTIONS.MONTH.find((o: ReactSelectOptions) => o.value === firstMonth);

  const firstYear = form.period && getFirstYear(form.month, form.year, form.period);
  const selectedFirstYear = OPTIONS.FIRST_YEAR.find((o) => o.value === firstYear);

  return (
    <FormGroup className='mt-4' disabled={formDisabled}>
      <h5>{reportingTitles.type}</h5>

      <div className='mt-2 row'>
        <div className='col-12 col-md-6' data-testid="survey-type-select">
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            isSearchable={false}
            isDisabled={isDisabled}
            placeholder={'Monthly, Quarterly, Annual'}
            className='w-100'
            onChange={(option) => onChange({ option, code: 'period' })}
            value={selectedPeriod}
            options={OPTIONS.PERIOD}
          />
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-12 col-md-6'>
          <h5>{reportingTitles.lastMonth}</h5>
          <div className='row mt-2'>
            <div className='col-12 col-md-6' data-testid="survey-month-select">
              <SelectFactory
                selectType={SelectTypes.SingleSelect}
                isDisabled={isDisabled}
                placeholder={'Select Month'}
                className='w-100'
                onChange={(option) => onChange({ option, code: 'month' })}
                value={selectedLastMonth}
                isSearchable={true}
                options={OPTIONS.MONTH}
              />
            </div>
            <div className='col-12 col-md-6' data-testid="survey-year-select">
              <SelectFactory
                selectType={SelectTypes.SingleSelect}
                isDisabled={isDisabled}
                placeholder={'Select Year'}
                className='w-100'
                onChange={(option) => onChange({ option, code: 'year' })}
                value={selectedLastYear}
                isSearchable={true}
                options={OPTIONS.LAST_YEAR}
              />
            </div>
          </div>
        </div>
        <div className={`col-12 col-md-6 ${form.period === DataPeriods.Monthly ? 'd-none' : ''}`}>
          <h5>{reportingTitles.firstMonth}</h5>
          <div className='row mt-2'>
            <div className='col-12 col-md-6'>
              <SelectFactory
                selectType={SelectTypes.SingleSelect}
                placeholder={'Select last month'}
                className='w-100'
                isDisabled={true}
                value={selectedFirstMonth}
                options={OPTIONS.MONTH}
              />
            </div>
            <div className='col-12 col-md-6'>
              <SelectFactory
                selectType={SelectTypes.SingleSelect}
                placeholder={'Select last year'}
                className='w-100'
                isDisabled={true}
                value={selectedFirstYear}
                options={OPTIONS.FIRST_YEAR}
              />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};
