import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { Answer } from './Answer';
import { DashboardDivider } from '@g17eco/atoms';
import { AIDisclaimer } from '@g17eco/molecules';
import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { FurtherNotes } from './FurtherNotes';
import './styles.scss';

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'valueValidation'>;
  utrv: SurveyModelMinimalUtrv;
}

export const AIAssistant = ({ utrv, utr }: Props) => {
  return (
    <div className='question-assistant__ai d-flex flex-column gap-3'>
      <Answer utrv={utrv} utr={utr} />      
      <FurtherNotes utrv={utrv} />
      <DashboardDivider className='' />
      <AIDisclaimer />
    </div>
  );
};
