import SimpleTooltip from '@components/simple-tooltip';
import Select, { Props as BaseSelectProps, GroupBase, OptionProps, components } from 'react-select';
import { DropdownIndicator, HiddenDropdownIndicator, ClearIndicator, Option, getStyles, SelectStyleProps } from './SelectFactory';

export type SelectProps<T = string | null> = BaseSelectProps<Option<T> | null, false, GroupBase<Option<T> | null>> &
  SelectStyleProps;

const CustomOption = <T = string | null>(props: OptionProps<Option<T> | null, false, GroupBase<Option<T> | null>>) => {
  const { tooltip } = props.data ?? {};

  return tooltip ? (
    <SimpleTooltip placement='bottom' text={tooltip}>
      <components.Option {...props} />
    </SimpleTooltip>
  ) : (
    <components.Option {...props} />
  );
};

export const SingleSelect = <T = string | null>(props: SelectProps<T>) => {
  const { components, styles, isTransparent, isFlexibleSize, showDropdownIndicator = true, ...rest } = props;

  return (
    <Select
      components={{
        DropdownIndicator: showDropdownIndicator ? DropdownIndicator : HiddenDropdownIndicator,
        ClearIndicator,
        Option: CustomOption,
        ...components,
      }}
      styles={{
        ...getStyles<Option<T> | null>({
          active: Boolean(!props.isDisabled),
          isMulti: false,
          isTransparent,
          isFlexibleSize,
        }),
        ...styles,
      }}
      {...rest}
    />
  );
};
