import { Button } from 'reactstrap';
import { useToggle } from '../../../hooks/useToggle';
import { SharingModal } from './SharingModal';
import { InsightDashboard } from '../../../types/insight-custom-dashboard';

type Props = {
  dashboard: InsightDashboard;
};

export const SharingButton = ({ dashboard }: Props) => {
  const [isOpen, toggle] = useToggle();

  const active = dashboard.share?.some(s => s.enabled);
  const text = active ? 'Shared' : 'Share';
  return (
    <>
      <Button color='secondary' active={active} onClick={toggle} className='px-2 ml-2'>
        <i className='fas fa-share fs-6 mr-1' /> {text}
      </Button>
      <SharingModal isOpen={isOpen} toggle={toggle} dashboard={dashboard} />
    </>
  );
};
