import { useContext, useState } from 'react';
import { deleteMetric } from '../../actions/initiative';
import { CustomMetric, useGetInitiativeCustomMetricsQuery } from '../../api/custom-metrics';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { BasicAlert } from '../alert/BasicAlert';
import { DashboardDivider, DashboardSection, DashboardSectionTitle } from '../dashboard';
import LoadingPlaceholder from '../LoaderContainer/LoadingPlaceholder';
import { CustomMetricContext } from './CustomMetricContainer';
import { CustomMetricManageTable } from './CustomMetricManageTable';
import { EditMetricModal, EditMetricModalProps } from './EditMetricModal';
import SimpleTooltip from '../simple-tooltip';
import { Button } from 'reactstrap';
import { useAppSelector } from '../../reducers';
import { QUESTION } from '@constants/terminology';
import { isStaff } from '../../selectors/user';
import { CustomMetricImportExport } from './CustomMetricImportExport';
import { ExtraFeature, FeatureStability } from '../../molecules/feature-stability';
import { DeleteMetricModal } from './DeleteMetricModal';
import { CustomMetricsUsage } from '@g17eco/types/metricGroup';
import { hasHitQuestionLimit } from './utils';

interface CustomMetricManageProps {
  initiativeId: string;
  customMetricsUsage: CustomMetricsUsage;
  handleReload: () => Promise<void>;
}

export const CustomMetricManage = ({ initiativeId, customMetricsUsage, handleReload }: CustomMetricManageProps) => {
  const {
    data: customMetrics,
    isFetching,
    error,
    refetch,
  } = useGetInitiativeCustomMetricsQuery(initiativeId, {
    skip: !initiativeId,
  });
  const isUserStaff = useAppSelector(isStaff);
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const { blueprintQuestions, buttons } = useContext(CustomMetricContext);
  const [deleteUtr, setDeleteUtr] = useState<CustomMetric | undefined>(undefined);
  const [selectedUtr, setSelectedUtr] = useState<{ id?: string, action: 'create' | 'edit' | 'readonly' }>();
  const [isImporting, setIsImporting] = useState(false);

  const handleCancelDeleteUtr = () => setDeleteUtr(undefined);

  const handleReloadCustomMetrics = async () => {
    await refetch();
    handleReload();
  };

  const handleDeleteUtr = () => {
    if (!deleteUtr) {
      return;
    }
    deleteMetric(initiativeId, deleteUtr._id)
      .then(() => {
        addSiteAlert({
          content: (
            <>
              <strong>Success!</strong> Custom metric has been removed
            </>
          ),
          color: SiteAlertColors.Primary,
        });
      })
      .catch((e) => addSiteError(e))
      .finally(() => {
        handleCancelDeleteUtr();
        handleReloadCustomMetrics();
      });
  };

  const editMetricModalProps: EditMetricModalProps = {
    isOpen: !!selectedUtr?.action,
    editUtrId: selectedUtr?.id,
    questions: blueprintQuestions,
    handleReload: handleReloadCustomMetrics,
    handleCancel: () => setSelectedUtr(undefined),
    isReadOnly: selectedUtr?.action === 'readonly'
  };

  const getCustomMetricManageTableProps = (isUsed: boolean = false) => ({
    handleOpenEditUtr: (utrId: string) => setSelectedUtr({ id: utrId, action: 'edit' }),
    handleReadOnlyUtr: (utrId: string) => setSelectedUtr({ id: utrId, action: 'readonly' }),
    handleOpenDeleteUtr: (utr: CustomMetric) => setDeleteUtr(utr),
    questions: isUsed ? customMetrics?.used : customMetrics?.created,
    isUsed,
  });

  const handleGoToImportExport = () => setIsImporting(true);

  if (isImporting) {
    const onComplete = async () => {
      setIsImporting(false);
      handleReloadCustomMetrics();
    }
    return <CustomMetricImportExport
      initiativeId={initiativeId}
      onCancel={() => setIsImporting(false)}
      onComplete={onComplete} />;
  }

  return (
    <>
      <DashboardSectionTitle
        title='Manage custom metrics'
        buttons={[
          ...buttons,
          isUserStaff ? (
            <Button onClick={handleGoToImportExport}>
              Import/Export <FeatureStability feature={ExtraFeature.CustomMetricImports} />
            </Button>
          ) : (
            <></>
          ),
        ]}
      />
      <div className='col-12 px-3 mb-3'>
        <div className='d-flex justify-content-end align-items-center'>
          <div>Can’t find your {QUESTION.SINGULAR}:</div>
          <SimpleTooltip
            text={hasHitQuestionLimit(customMetricsUsage) ? 'Limit reached. Please upgrade if more than custom metrics are needed' : ''}
          >
            <Button
              color={'secondary'}
              outline
              disabled={hasHitQuestionLimit(customMetricsUsage)}
              className='ml-3'
              onClick={() => setSelectedUtr({ action: 'create' })}
            >
              Create a custom {QUESTION.SINGULAR}
            </Button>
          </SimpleTooltip>
        </div>
      </div>
      {error ? (
        <BasicAlert type='danger'>{error.message}</BasicAlert>
      ) : (
        <DashboardSection>
          <LoadingPlaceholder count={2} height={37} className='mt-1 background-ThemeTextWhite' isLoading={isFetching}>
            <div className='h5 flex-fill mt-0 d-flex justify-content-between align-items-center'>
              <span>Unused</span>
              <div className='text-right'>
                <p className='text-xs text-ThemeTextMedium mb-1'>
                  Organisational {QUESTION.PLURAL}: <span className='ml-2'>{customMetricsUsage.organisationCurrentUsage}/{customMetricsUsage.organisationLimit}</span>
                </p>
                <p className='text-xs text-ThemeTextMedium mb-1'>
                  Subsidiary {QUESTION.PLURAL}: <span className='ml-2'>{customMetricsUsage.subsidiaryCurrentUsage}/{customMetricsUsage.organisationLimit}</span>
                </p>
              </div>
            </div>
            <CustomMetricManageTable {...getCustomMetricManageTableProps()} />
            <DashboardDivider />
            <div className='h5 flex-fill mt-3'>Used</div>
            <CustomMetricManageTable {...getCustomMetricManageTableProps(true)} />
          </LoadingPlaceholder>
        </DashboardSection>
      )}

      <EditMetricModal {...editMetricModalProps} />
      {deleteUtr ? (
        <DeleteMetricModal
          key={deleteUtr._id}
          utr={deleteUtr}
          handleDelete={handleDeleteUtr}
          handleCancel={handleCancelDeleteUtr}
        />
      ) : null}
    </>
  );
};
