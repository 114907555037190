import { getUnitByTypeOptions, getUnitLabel, UnitTypeMapValue } from '@features/question-configuration';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Label } from 'reactstrap';
import { OverrideUnitAlert } from './OverrideUnitAlert';
import { UnitTypes } from '@utils/units';

export type UnitConfigType = Record<string, string>;

interface Props {
  unitTypeMap: Map<string, UnitTypeMapValue>;
  unitConfig: UnitConfigType | undefined;
  onSelectUnit: (unitType: string, value: string | undefined) => void;
}
export const OverrideUnitForm = (props: Props) => {
  const { unitTypeMap, unitConfig, onSelectUnit } = props;
  const selectedUnitTypes = [...unitTypeMap.keys()].reduce((acc, key) => {
    // move number scale to top selection
    if (key === UnitTypes.numberScale) {
      acc.unshift(key);
      return acc;
    }
    acc.push(key);
    return acc;
  }, [] as string[]);

  if (!selectedUnitTypes.length || !unitConfig) {
    return null;
  }

  return (
    <div className='pb-3'>
      <div className='fw-bold mb-2'>Preferred unit</div>
      {selectedUnitTypes.map((unitType) => {
        const options = getUnitByTypeOptions(unitType);
        const { defaultValue = '', utrIds } = unitTypeMap.get(unitType) ?? {};

        return (
          <div key={unitType} className='mb-2'>
            <Label for={unitType} className='mb-1' style={{ fontWeight: 500 }}>
              {`${getUnitLabel(unitType)} (system default is ${defaultValue})`}
            </Label>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              options={options}
              value={options.find((op) => op.value === unitConfig[unitType])}
              onChange={(op) => onSelectUnit(unitType, op?.value)}
            />
            <OverrideUnitAlert show={(utrIds?.size ?? 0) > 1} />
          </div>
        );
      })}
    </div>
  );
};
