import {
  Document,
  ImageRun,
  SectionType,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TableLayoutType,
  Header,
  AlignmentType,
  TextRun,
  VerticalPositionRelativeFrom,
  VerticalPositionAlign,
  UnderlineType,
} from 'docx';
import { blueStyles, numberingStyles } from '../styles';
import {
  heading,
  heading2,
  heading3,
  paragraph,
  imageWrapper,
  footer,
  pagebreak,
  Styles,
  spacer,
  bulletList,
  getBorders,
  spacedParagraph,
  infoParagraph,
  arrayToTableRow,
  convertImgToBase64URL,
} from '../document-structure';
import { DATE, formatDate } from '../../../utils/date';
import { SurveyInitiative } from '../../../types/survey';
import { sdgReportData, SDGReportGoal } from '../reportApi';
import { getImgGoalsB64, getImgGoalsB64Inverted } from '../../sdg-icon/utils';
import { DataSource, Esg30DataSources } from '.';
import { ReportData, ReportOptions } from '../../../types/reportData';
import { VisibilityStatus } from '../../../types/download';
import { simpleTable } from '../group-builder';
import { getHealthTable } from './data/health-table';
import { getUtrvData } from '../reportData';
import { getAntiCorruptionTraining } from './data/anti-corruption-training';
import SDGCircle from '../../../images/reports/sdg_circle.png';
import { frameworks } from '@g17eco/core';

const STYLES = {
  TABLE_WIDTH: 9000,
  TABLE_HEADER_SHADING: {
    fill: 'f5f5f0'
  },
  TABLE_BORDER_COLOUR: '6a6c70',
  LINE_SPACING: 300,
  INFO_TEXT: {
    color: 'BBBBBB'
  }
};

const tableHeaderStyle: Styles = {
  shading: {
    fill: STYLES.TABLE_HEADER_SHADING.fill
  },
  style: 'bold',
};

const MARGINS_SM = {
  left: 75,
  right: 75,
  top: 75,
  bottom: 75,
};

export interface ESG30ReportGeneratorData {
  survey: SurveyInitiative;
  dataSources: Esg30DataSources;
  questionData: ReportData[];
  options?: ReportOptions;
  visibility: VisibilityStatus;
}

export const ESG30ReportGenerator = async (generatorData: ESG30ReportGeneratorData): Promise<Document> => {
  const { survey, questionData, dataSources, options, visibility } = generatorData;
  const data = await sdgReportData(survey._id, options);
  const periodCovered = formatDate(survey.effectiveDate, DATE.MONTH_YEAR, true);
  const companyName = survey.initiative.name;

  const highMaterialitySDGs = Object.keys(data.materiality).filter(k => data.materiality[Number(k)] === 100);
  const mediumMaterialitySDGs = Object.keys(data.materiality).filter(k => data.materiality[Number(k)] === 66);

  const highMaterialityGoals = data.goals.filter(d => highMaterialitySDGs.includes(d.goal));

  const sdgCircleBase64 = await convertImgToBase64URL(SDGCircle);

  const esg30LogoBase64 = await convertImgToBase64URL(frameworks.esg30.src);

  const formatterSettings = {
    maximumFractionDigits: 2,
  };

  const formatNumber = (n: number): string => {
    if (isNaN(n)) {
      return '0';
    }
    return Number(n ?? 0).toLocaleString('en-GB', formatterSettings)
  }

  const getNumericValue = ({ code, valueListCode }: { code: string, valueListCode?: string }) => {
    const numericValue = getUtrvData({
      reportData: questionData,
      utrCode: code,
      tableColumn: valueListCode,
      visibility,
    }).numericValue;
    return formatNumber(numericValue);
  }

  const getImageRunFromBase64 = (base64Img: string, width: number, styles?: Styles) => {
    return imageWrapper(
      new ImageRun({
        data: base64Img,
        transformation: {
          width: width,
          height: width,
        }
      }),
      styles
    )
  }

  const imgGoalsB64 = await getImgGoalsB64();

  const imgGoalsB64Inverted = await getImgGoalsB64Inverted();

  const getSDGGoalImage = (code: string, width: number = 100, outline: boolean = false, styles?: Styles) => {
    const img = outline ? imgGoalsB64Inverted[Number(code) - 1] : imgGoalsB64[Number(code) - 1];
    if (!img) {
      return paragraph(`Fail for ${code}`);
    }
    return getImageRunFromBase64(img, width, styles);
  }

  const totalSdgContribution = data.contribution.actual ? Math.round(data.contribution.actual) : 0;

  const esgIntroductionPage = [
    heading('INTRODUCTION'),
    spacer(100),
    new Paragraph({
      children: [
        new TextRun({
          text: companyName,
          bold: true,
          style: 'bold'
        }),
        new TextRun(' steadfastly upholds a culture of transparency and accountability, particularly in our journey towards sustainable growth and responsibility. In this spirit, we are proud to present this sustainability report. This document serves as a holistic overview of our environmental, social, and governance (ESG) performance, reflecting our ongoing efforts and commitment in these critical areas.')
      ],
      spacing: {
        line: STYLES.LINE_SPACING
      }
    }),
    spacer(),
    spacedParagraph(`This report not only showcases our achievements and milestones in sustainability but also underscores our dedication to continuous improvement. By rigorously adhering to these metrics, ${companyName} aims to provide a clear and accurate representation of our impact on the environment, our contribution to society, and the integrity of our governance structures.`),
    spacer(),
    spacedParagraph('In the following pages, you will find a comprehensive analysis of our environmental stewardship, including our strides in reducing emissions, conserving energy, managing water usage efficiently, and minimising waste. This section underscores our commitment to safeguarding the planet for future generations.'),
    spacer(),
    spacedParagraph(`Moreover, the social aspect of this report highlights ${companyName}'s unwavering commitment to our employees, customers, and communities. It showcases our efforts in promoting diversity and inclusion, ensuring workplace safety, and fostering ethical and transparent business practices.`),
    spacer(),
    spacedParagraph(`Lastly, the governance section of this report reflects ${companyName}'s dedication to ethical leadership, responsible decision-making, and comprehensive risk management. It underscores our commitment to upholding the highest standards of corporate governance and accountability.`),
    spacer(),
    spacedParagraph(`Together, these elements provide a transparent and in-depth view of our ESG performance, illustrating ${companyName}'s commitment to sustainability and our unwavering resolve to make a positive impact in the world.`),
    pagebreak(),
  ]

  const sdgOverviewPage = [
    heading('SDG OVERVIEW'),
    spacer(100),
    new Paragraph({
      children: [
        new TextRun('The UN set out 17 Sustainable Development Goals to achieve by 2030 – covering people, suppliers, environmental, and community issues. '),
        new TextRun({
          text: companyName,
          bold: true,
          style: 'bold'
        }),
        new TextRun(' is taking responsibility for delivering on those goals.')
      ],
      spacing: {
        line: STYLES.LINE_SPACING
      }
    }),
    spacer(),
    new Paragraph({
      children: [
        new TextRun(`The overall measured ${companyName} contribution to all 17 Goals is `),
        new TextRun({
          text: `${totalSdgContribution}%`,
          color: totalSdgContribution > 0 ? '3BB056' : totalSdgContribution < 0 ? 'FF0000' : '000000'
        }),
      ]
    }),
    spacer(720),
    imageWrapper(
      new ImageRun({
        data: Uint8Array.from(atob(sdgCircleBase64), c => c.charCodeAt(0)),
        transformation: {
          width: 240,
          height: 240,
        },
        floating: {
          horizontalPosition: {
            offset: 2600000
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PARAGRAPH,
            align: VerticalPositionAlign.CENTER,
            offset: 3000000
          },
        },
      })
    ),
    new Paragraph({
      children: [
        new TextRun({
          text: `${totalSdgContribution}%`,
          color: totalSdgContribution > 0 ? '3BB056' : totalSdgContribution < 0 ? 'FF0000' : '000000',
          size: 52
        }),
      ],
      alignment: AlignmentType.CENTER,
      spacing: {
        before: 1200
      }
    }),
    spacer(1600),
    new Paragraph({
      children: [
        new TextRun({
          text: 'You can view all the goals at ',
          size: 15
        }),
        new TextRun({
          text: 'www.globalgoals.org',
          size: 15,
          color: '0000FF',
          underline: {
            type: UnderlineType.SINGLE
          }
        })
      ],
      alignment: AlignmentType.CENTER
    }),
    spacer(),
    infoParagraph('SDG contribution is the effort an organisation is making through internal and external initiatives, as well as across the value chain, towards the successful delivery of the SDGs.'),
    pagebreak(),
  ]

  const sdgGoalGrid = () => {

    const isMaterial = (id: string) => highMaterialitySDGs.includes(id) || mediumMaterialitySDGs.includes(id);

    const cell = (id: number) => {
      const goal = data.goals[id];
      const code = goal.contribution.sdgCode;
      return new TableCell({
        children: [
          isMaterial(`${id + 1}`) ? getSDGGoalImage(code, 90) : getSDGGoalImage(code, 90, true)
        ],
        margins: MARGINS_SM,
        borders: getBorders('FFFFFF'),
      });
    }

    return new Table({
      rows: [
        new TableRow({
          children: [0, 1, 2, 3, 4, 5].map(cell)
        }),
        new TableRow({
          children: [6, 7, 8, 9, 10, 11].map(cell)
        }),
        new TableRow({
          children: [12, 13, 14, 15, 16].map(cell)
        })
      ],
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
      columnWidths: Array.from({ length: 2 }, (_) => Math.floor(STYLES.TABLE_WIDTH / 6)),
    });
  }

  const materialityCount = highMaterialitySDGs.length + mediumMaterialitySDGs.length;

  const keyFocusAreasPage = [
    heading2('KEY FOCUS AREAS'),
    spacer(),
    spacedParagraph(`While we can impact all 17 global Sustainable Development Goals, there are ${materialityCount} which are most material to our industry.`),
    spacer(),
    sdgGoalGrid(),
    pagebreak(),
  ]

  const topSDGs = [...highMaterialityGoals].sort((a, b) => {
    const av = a.contribution.actual || 0;
    const bv = b.contribution.actual || 0;
    return av < bv ? 1 : av > bv ? -1 : 0
  });

  const sdgInfoTable = (goals: SDGReportGoal[]) => {
    const colWidth = Math.floor(STYLES.TABLE_WIDTH / 2);
    return new Table({
      rows: goals
        .sort((a, b) => Number(a.goal) > Number(b.goal) ? 1 : Number(a.goal) < Number(b.goal) ? -1 : 0)
        .map(d => {
          const code = d.goal;
          const sdgGoalLogo = new TableCell({
            children: [getSDGGoalImage(code, 80)],
            margins: MARGINS_SM,
            borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
          })

          const description = new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: d.contribution.officialName,
                    size: 26,
                    color: STYLES.TABLE_HEADER_SHADING.fill,
                  })
                ],
                spacing: {
                  line: STYLES.LINE_SPACING
                }
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: d.contribution.description ?? '',
                    size: 20,
                  })
                ],
                spacing: {
                  before: 200,
                  after: 200,
                  line: STYLES.LINE_SPACING
                }
              }),
            ],
            margins: MARGINS_SM,
            borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
          })

          return new TableRow({
            children: [
              sdgGoalLogo,
              description
            ]
          });
        }),
      columnWidths: Array.from({ length: 2 }, (_) => colWidth),
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
    });
  }

  const highMaterialityGoalsPage = [
    heading2('HIGH MATERIALITY'),
    spacer(),
    spacedParagraph(`Of these ${materialityCount}, ${highMaterialityGoals.length} have been identified as having the most importance or highest materiality for our industry sector.`),
    spacer(),
    sdgInfoTable(topSDGs),
    pagebreak(),
  ]

  const [topSDG] = topSDGs;
  const keyStrengthPage = !topSDG ? [] : [
    heading2('KEY STRENGTH'),
    spacer(),
    topSDGs[0] ? getSDGGoalImage(topSDGs[0].goal, 250, true, { alignment: AlignmentType.CENTER }) : spacer(),
    spacer(),
    heading3(`SDG ${topSDGs[0].contribution.sdgCode}:`),
    heading3('Ensure availability and sustainable management of water and sanitation for all'),
    spacer(),
    spacedParagraph(`Our contribution to this goal is: ${formatNumber(topSDGs[0].contribution.actual ?? 0)}%`),
    spacer(),
    spacedParagraph(`This is the Sustainable Development Goal which combines high materiality for our industry and where ${companyName} is strongest and makes the most contribution.`),
    pagebreak(),
  ]

  const getChart = (dataSource: DataSource) => {
    if (!dataSource.chart) {
      return paragraph('No data');
    }

    return new ImageRun({
      data: Uint8Array.from(atob(dataSource.chart), c => c.charCodeAt(0)),
      transformation: {
        width: dataSource.width ?? 100,
        height: dataSource.height ?? 100,
      }
    });
  }

  const ghgEmissions = formatNumber(dataSources.ghgEmissions.totalEmissions ?? 0);
  const ghgChart = getChart(dataSources.ghgEmissions);

  const environmentalPage = [
    heading('ENVIRONMENTAL'),
    spacer(STYLES.LINE_SPACING),
    heading2('GREENHOUSE GAS EMISSIONS'),
    spacer(10),
    new Paragraph({
      children: [
        new TextRun('In its steadfast pursuit of a greener footprint, '),
        new TextRun({
          text: companyName,
          bold: true,
          style: 'bold'
        }),
        new TextRun(`'s comprehensive breakdown of total emissions is a testament to its transparency and dedication to the environment. The chart captures ${companyName}'s emissions profile, distinctly categorizing direct emissions under Scope 1, energy indirect emissions under Scope 2, and all other indirect emissions under Scope 3. This chart not only reflects ${companyName}'s current environmental impact but also guides its strategies for reduction across all areas of operation. It is an integral part of ${companyName}'s sustainability narrative, highlighting its commitment to rigorous monitoring and continuous improvement in the journey towards reduced carbon emissions.`)
      ],
      spacing: {
        line: STYLES.LINE_SPACING
      }
    }),
    spacer(),
    spacedParagraph('Total emissions', { textRun: { bold: true } }),
    spacer(100),
    spacedParagraph(ghgEmissions.toLocaleString(), { alignment: AlignmentType.CENTER, textRun: { size: 40, color: '1a4791' } }),
    spacedParagraph('metric tonnes', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    spacedParagraph('co2', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    spacer(100),
    spacedParagraph('Scope 1, 2, and 3 emissions', { textRun: { bold: true } }),
    spacer(10),
    imageWrapper(ghgChart, { alignment: AlignmentType.CENTER }),
    spacer(),
    spacedParagraph(`Air emissions are a critical environmental metric for ${companyName}, as they have significant implications for air quality and public health.`),
    spacer(),
    spacedParagraph(`In the past year, ${companyName} has implemented various initiatives aimed at reducing emissions from our operations. This includes investing in cleaner production technologies, improving energy efficiency, and switching to less polluting fuels and energy sources. These actions are in line with our commitment to mitigate our impact on the environment and to contribute to cleaner air for the communities in which we operate.`),
    spacer(),
    spacedParagraph(`${companyName} ongoing efforts to manage and reduce air emissions are documented through meticulous tracking and reporting practices, ensuring compliance with environmental standards and regulations. By transparently sharing our progress and challenges in this area, we aim to demonstrate our dedication to environmental stewardship and to building trust with our stakeholders.`),
    spacer(400),
    spacedParagraph('Scope 1: Direct Emissions Overview', { textRun: { bold: true } }),
    spacer(),
    spacedParagraph(`${companyName} takes direct accountability for emissions from our own operations. In managing our Scope 1 emissions, we focus on reducing the output from our facilities and company vehicles to minimise our environmental impact.`),
    spacer(),
    spacedParagraph('Scope 2: Indirect Energy Emissions Insight', { textRun: { bold: true } }),
    spacer(),
    spacedParagraph(`${companyName} also addresses emissions from the energy we purchase. Our Scope 2 strategies involve increasing energy efficiency and investing in renewable energy sources to lower our indirect carbon footprint.`),
    spacer(),
    spacedParagraph('Scope 3:', { textRun: { bold: true } }),
    spacer(),
    spacedParagraph('Scope 3 emissions represent the indirect greenhouse gas (GHG) emissions that are a consequence of an organisation\'s actions, but which occur outside its own facilities and result from sources that they do not own or directly control. This includes upstream activities such as the production of purchased goods and services, as well as downstream impacts like the use of sold products.'),
    spacer(),
    spacedParagraph('Our commitment is to encourage a sustainable supply chain and in order to mitigate the environmental impact of our products throughout their lifecycle.'),
    spacer(),
    spacedParagraph('Notes', { textRun: { bold: true } }),
    infoParagraph('Greenhouse gases are gases that trap heat in the earth’s atmosphere, causing climate change. They include carbon dioxide (co2), methane and nitrous oxide. There are three different categories of emissions:'),
    spacer(400),
    bulletList('Scope 1 – the emissions that a company makes directly e.g. company vehicles, boilers.', { textRun: STYLES.INFO_TEXT }),
    spacer(),
    bulletList('Scope 2 – the emissions a company makes indirectly e.g. energy purchase, such as electricity.', { textRun: STYLES.INFO_TEXT }),
    spacer(),
    bulletList('Scope 3 – the emissions that are associated with the organisation e.g. business related travel and energy use of staying in hotels, emissions related to purchased goods from suppliers, staff commuting.', { textRun: STYLES.INFO_TEXT }),
    pagebreak(),
  ];

  const energyConsumptionChart = getChart(dataSources.energyConsumption);
  const energyIntensityRatioChart = getChart(dataSources.energyIntensityRatio);
  const energyUseTable = () => {
    const colWidth = Math.floor(STYLES.TABLE_WIDTH / 2);
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [imageWrapper(energyConsumptionChart, { alignment: AlignmentType.CENTER })],
              margins: {
                left: 0,
                right: 10,
                top: 0,
                bottom: 0
              },
              borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
            }),
            new TableCell({
              children: [imageWrapper(energyIntensityRatioChart, { alignment: AlignmentType.CENTER })],
              borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
            }),
          ]
        })
      ],
      columnWidths: Array.from({ length: 1 }, (_) => colWidth),
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
    });
  }

  const energyPage = [
    heading2('ENERGY'),
    spacer(20),
    spacedParagraph(`At ${companyName}, we are mindful of the energy our operations consume and the implications this has for both the environment and our business efficiency. Our comprehensive energy management strategy is reflective of this dual concern, aiming to minimise our carbon footprint while enhancing performance.`),
    spacer(),
    spacedParagraph(`In the last reporting period, our total energy consumption stood at ${dataSources.energyConsumption.consumption} ${dataSources.energyConsumption.unit}, a figure that encompasses all forms of energy used across our company. This number captures the direct energy used to power our machinery, equipment, and facilities, as well as the indirect energy consumed in the form of electricity and heating that supports our business activities.`),
    spacer(),
    spacedParagraph(`To provide a more nuanced view of our energy use, we also track our energy intensity ratio. This ratio expresses our energy consumption relative to an output metric, such as production volume or revenue, offering insights into the efficiency of our energy use. For instance, an energy intensity ratio of ${dataSources.energyIntensityRatio.ratio} indicates the amount of energy needed to produce a unit of output, reflecting the effectiveness of our energy-saving measures and operational improvements.`),

    spacer(100),
    energyUseTable(),
    spacer(100),

    spacedParagraph(`Our journey towards reduced energy consumption and improved energy efficiency is ongoing and remains a central pillar of our commitment to sustainability. Through continued investment, innovation, and improvement, ${companyName} aims to lead by example in energy stewardship.`),
    pagebreak(),
  ];

  const waterConsumptionChart = getChart(dataSources.waterConsumption);
  const waterPage = [
    heading2('WATER'),
    spacer(20),
    spacedParagraph(`${companyName} recognizes that water is a precious resource, vital to ecosystems, communities, and businesses alike. Our commitment to sustainability includes a conscientious approach to managing our water usage.`),
    spacer(),
    spacedParagraph(`During the last reporting period, ${companyName}'s operations consumed a total of ${dataSources.waterConsumption.consumption} ${dataSources.waterConsumption.unit}. This consumption reflects water used in all processes across our facilities, from manufacturing and cooling to sanitation and landscape maintenance.`),

    spacer(100),
    imageWrapper(waterConsumptionChart, { alignment: AlignmentType.CENTER }),
    spacer(100),

    spacedParagraph('We understand that reducing our water footprint is not only beneficial for the environment but also essential for the long-term viability of our business. To this end, we have implemented a water management strategy that emphasises conservation, efficiency, and recycling. Our efforts include the deployment of water-saving fixtures, the integration of water-efficient practices in our operational procedures, and the investment in technologies that allow us to reuse wastewater whenever possible.'),
    spacer(),
    spacedParagraph(`The challenge of responsible water stewardship is amplified by the growing demands of our business and the necessity to operate within the means of our local water basins. ${companyName} is dedicated to continually assessing and improving our water use practices to ensure we remain within sustainable withdrawal limits, thereby supporting both our business objectives and the health of the communities and environments in which we operate.`),
    spacer(),
    spacedParagraph(`In the coming years, ${companyName} will persist in our pursuit of reducing water consumption, setting ambitious targets and transparently reporting our progress. Through mindful management and strategic initiatives, we aim to achieve a balance that fulfils our operational needs while preserving this essential resource for future generations.`),
    pagebreak()
  ]

  const wasteGeneratedChart = getChart(dataSources.wasteGenerated);
  const wastePage = [
    heading2('WASTE'),
    spacer(20),
    spacedParagraph(`At ${companyName}, we understand that waste management is a critical component of sustainable operations. Our waste generation metrics for the reporting period are as presented in table below.`),
    spacer(),
    spacedParagraph('These figures reflect the total weight of waste material produced across all our activities. In our efforts to mitigate environmental impact, we prioritize reducing waste at its source, enhancing recycling initiatives, and seeking sustainable waste disposal methods.'),

    spacer(100),
    imageWrapper(wasteGeneratedChart, { alignment: AlignmentType.CENTER }),
    spacer(100),

    spacedParagraph('Hazardous waste poses particular challenges due to its potential impact on health and the environment. We handle such waste with the utmost care, adhering to strict regulations and best practices to ensure safe treatment, storage, and disposal.'),
    spacer(),
    spacedParagraph('In contrast, non-hazardous waste, while less risky, still requires efficient management to minimize our ecological footprint. We have implemented recycling programs and are exploring opportunities for waste-to-energy conversion to address this.'),
    spacer(),
    spacedParagraph(`${companyName} is committed to not just managing but actively reducing waste generation. We are continually exploring innovative approaches to waste reduction, such as process optimization, material substitution, and promoting a circular economy within our operations.`),
    pagebreak()
  ]

  const genderMalePc = formatNumber(dataSources.genderSplitPc.maleEmployeesPc ?? 0);
  const genderFemalePc = formatNumber(dataSources.genderSplitPc.femaleEmployeesPc ?? 0);
  const genderRatio = formatNumber(dataSources.genderSplitPc.genderRatio ?? 0);
  const genderSplit = getChart(dataSources.genderSplit);
  const genderSplitPc = getChart(dataSources.genderSplitPc);

  const traningChart = getChart(dataSources.averageTraining);

  const socialPage = [
    heading('SOCIAL'),
    spacer(20),
    spacedParagraph(`In our pursuit of social responsibility, ${companyName} is committed to creating a diverse, inclusive, and healthy workplace. This section of the Sustainability Report sheds light on our efforts towards fostering gender diversity, addressing the gender pay gap, and prioritizing the health and well-being of our employees. By recognizing and addressing these crucial social aspects, we aim to create an environment where everyone feels valued, supported, and empowered. Through transparency and meaningful action, we strive to build a company culture that champions equality, fairness, and the overall welfare of our workforce.`),
    spacer(400),

    heading2('GENDER'),
    spacer(30),
    imageWrapper([genderSplit, genderSplitPc], { alignment: AlignmentType.CENTER }),
    spacer(200),
    spacedParagraph(`Within ${companyName}, the gender split is ${genderMalePc}% male and ${genderFemalePc}% female.`),
    spacer(),
    spacedParagraph(`In addition, the turnover rate of women to men is ${genderRatio} meaning women are ${genderRatio} times more likely to leave the organization in any given time period than men.`),

    pagebreak(),

    heading2('GENDER PAY GAP'),
    ...(
      dataSources.genderPayGap.employeeCategories?.map((c: any) => [
        spacer(),
        heading3(c.name),
        spacedParagraph(`On average women are paid ${formatNumber(c.payRatio)}% of what men are paid - in '${c.name}' roles.`),
        imageWrapper(getChart(c), { alignment: AlignmentType.CENTER }),
        pagebreak(),
      ]) ?? [spacer()]).flat(),

    heading3('Training'),
    spacer(200),
    imageWrapper(traningChart, { alignment: AlignmentType.CENTER }),

    pagebreak(),

    heading2('HEALTH'),
    spacer(20),
    spacedParagraph(`At ${companyName}, we monitor the health and well-being of our employees. We believe that a healthy workforce fosters productivity, creativity, and overall job satisfaction. Our commitment to workplace health includes implementing robust safety protocols, providing access to healthcare benefits, and promoting work-life balance. By investing in the physical and mental well-being of our employees, we create a positive and supportive work environment that contributes to their overall success and contributes to a sustainable, thriving organization.`),
    spacer(),
    heading3('Health and safety in the workplace'),

    spacer(30),
    simpleTable([
      arrayToTableRow(['', 'Unit', periodCovered], tableHeaderStyle),
      ...getHealthTable(questionData, visibility).map(row => arrayToTableRow(row)),
    ]),
    spacer(),
    heading3('Healthcare benefits'),

    spacer(30),
    spacedParagraph(
      `${getNumericValue({ code: 'gri/2020/403-1/b', valueListCode: 'pc_family_covered' })}%`,
      { alignment: AlignmentType.CENTER, textRun: { size: 40, color: '1a4791' } }
    ),
    spacedParagraph('of healthcare benefits covering families', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    pagebreak()
  ];

  const boardGenderChart = getChart(dataSources.boardGender);
  const boardDiversityChart = getChart(dataSources.boardDiversity);
  const femalePercentage = dataSources.boardGender.female ?? 0;
  const malePercentage = dataSources.boardGender.male ?? 0;

  const antiCorruptionPercentage = getAntiCorruptionTraining(questionData).antiTraningPercentage;

  const governancePage = [
    heading('GOVERNANCE'),
    spacer(30),
    spacedParagraph('Strong governance is essential for a company\'s sustainability. It promotes transparency, accountability, and risk management. It drives innovation, responsible supply chain practices, and long-term value creation. Good governance ensures that sustainability is integrated into strategic decision-making and helps companies thrive while contributing to a more sustainable future.'),
    spacer(),
    spacedParagraph('Part of good governance includes board diversity.'),
    spacedParagraph(`Our company is fully dedicated to enhancing board diversity. We recognize that diversity brings different perspectives, drives innovation, improves risk management, enhances stakeholder representation, boosts reputation, and helps us adapt to market dynamics. At present, the board is made up of ${formatNumber(femalePercentage)}% of women, and ${formatNumber(malePercentage)}% of men.`),
    spacer(),
    spacedParagraph(`${formatNumber(femalePercentage)}%`, { alignment: AlignmentType.CENTER, textRun: { size: 40, color: '1a4791' } }),
    spacedParagraph('of the board are women', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    spacer(10),
    heading3('BOARD DIVERSITY'),
    spacer(10),
    imageWrapper([boardGenderChart, boardDiversityChart], { alignment: AlignmentType.CENTER }),

    pagebreak(),

    heading3('ANTI-CORRUPTION TRAINING'),
    spacer(10),
    spacedParagraph(`${formatNumber(antiCorruptionPercentage)}% of our board members, and members on governance bodies, have received anti-corruption training. This training plays a crucial role in fostering a culture of ethical conduct within our organization, an important aspect of our commitment to governance and integrity.`),
  ]

  const document = new Document({
    styles: {
      paragraphStyles: blueStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        properties: { type: SectionType.NEXT_PAGE, },
        headers: { default: new Header({ children: [] }), },
        footers: { default: footer() },
        children: [
          imageWrapper(
            new ImageRun({
              data: Uint8Array.from(atob(esg30LogoBase64), c => c.charCodeAt(0)),
              transformation: {
                width: 650,
                height: 650,
              },
              floating: {
                horizontalPosition: {
                  offset: 4300000
                },
                verticalPosition: {
                  offset: -1300000
                },
              },
            })
          ),
          new Paragraph({
            text: '',
            spacing: {
              before: 6000
            }
          }),
          heading2(periodCovered),
          heading2(companyName),
          spacer(400),
          heading('ESG'),
          heading('INSIGHTS REPORT'),
          pagebreak(),
        ],
      },
      {
        properties: {
          type: SectionType.NEXT_PAGE,
        },
        headers: {
          default: new Header({
            children: [new Paragraph({
              text: 'G17.ECO',
              alignment: AlignmentType.LEFT,
            })],
          }),
        },
        footers: {
          default: footer()
        },
        children: [
          ...esgIntroductionPage,
          ...sdgOverviewPage,
          ...keyFocusAreasPage,
          ...highMaterialityGoalsPage,
          ...keyStrengthPage,
          ...environmentalPage,
          ...energyPage,
          ...waterPage,
          ...wastePage,
          ...socialPage,
          ...governancePage,
        ]
      },
    ]
  });

  return document;
}
