import { RadioInput } from '@components/form/RadioInput';
import { EditorTemplate } from '../EditorTemplate';
import { Button } from 'reactstrap';
import { TextData, TextEditorProps, TextItem } from '../../types';
import { useState } from 'react';
import { GridDashboardItemCreate, InsightDashboardItemType, TextSubType } from '@g17eco/types/insight-custom-dashboard';
import { FreeText } from './FreeText';
import classNames from 'classnames';
import { MetricText } from './MetricText';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { emptyUtrData, isValidUtrVariable } from '../../utils/dashboard-utils';

const textStyleOptions = [
  { code: InsightDashboardItemType.Headline, name: 'Headline' },
  { code: InsightDashboardItemType.Text, name: 'Paragraph/text' },
];

const getTextData = (item: TextItem): TextData => {
  return {
    type: item.type,
    subType: item.subType ?? TextSubType.Free,
    text: item.text ?? '',
    metric: Object.values(item.variables ?? {})?.[0] ?? emptyUtrData,
  };
};

const Tabs: Record<TextSubType, string> = {
  [TextSubType.Free]: 'Free text',
  [TextSubType.Metric]: 'Existing metric',
};

export const TextEditor = ({ handleCancel, updateItem, editingItem, initiativeId }: TextEditorProps) => {
  const [widgetChanges, setWidget] = useState<TextData>(getTextData(editingItem));

  const changeWidget = (key: keyof TextData, value: TextData[keyof TextData]) =>
    setWidget((widget) => ({ ...widget, [key]: value }));
  const handleChangeType = ({ value }: { value: TextData['type'] }) => changeWidget('type', value);
  const handleChangeText = (value: TextData['text']) => changeWidget('text', value);
  const handleChangeTab = (tab: TextSubType) => {
    changeWidget('subType', tab);
  };
  const handleSelectQuestion = (data: QuestionData) => {
    changeWidget('metric', data);
  };
  const handleSubmit = async () => {
    if (widgetChanges.subType === TextSubType.Free) {
      updateItem({
        ...editingItem,
        type: widgetChanges.type,
        subType: widgetChanges.subType,
        text: widgetChanges.text.trim(),
        variables: undefined,
      } as GridDashboardItemCreate);
    }

    if (widgetChanges.subType === TextSubType.Metric && isValidUtrVariable(widgetChanges.metric)) {
      updateItem({
        ...editingItem,
        type: widgetChanges.type,
        subType: widgetChanges.subType,
        variables: { a: widgetChanges.metric },
        text: undefined,
      } as GridDashboardItemCreate);
    }
  };

  const isDisabled =
    (widgetChanges.subType === TextSubType.Free && !widgetChanges.text) ||
    (widgetChanges.subType === TextSubType.Metric && !isValidUtrVariable(widgetChanges.metric));

  return (
    <EditorTemplate isDisabled={isDisabled} handleCancel={handleCancel} handleSubmit={handleSubmit}>
      <div className='my-3 d-flex justify-content-between align-items-center'>
        {(Object.keys(Tabs) as TextSubType[]).map((tab, index) => (
          <Button
            className={classNames('w-50', index ? '' : 'mr-2')}
            key={tab as string}
            color='primary'
            outline={widgetChanges.subType !== tab}
            onClick={() => handleChangeTab(tab)}
            active={widgetChanges.subType === tab}
          >
            {Tabs[tab]}
          </Button>
        ))}
      </div>
      <p className='text-medium mb-2'>Styling</p>
      <RadioInput
        groupCode='text-widget'
        value={widgetChanges.type}
        options={textStyleOptions}
        onChange={handleChangeType}
        className='d-flex'
        formGroupClassName='mr-5'
      />

      {widgetChanges.subType === TextSubType.Free ? (
        <FreeText value={widgetChanges.text} handleChange={handleChangeText} />
      ) : null}
      {widgetChanges.subType === TextSubType.Metric ? (
        <MetricText
          initiativeId={initiativeId}
          handleSelectQuestion={handleSelectQuestion}
          questionData={widgetChanges.metric}
        />
      ) : null}
    </EditorTemplate>
  );
};
