import { useEffect, useState } from 'react';
import { SafeUser } from '../../../constants/users';
import { ReduxStateLoadable } from '../../../reducers';
import G17Client from '../../../services/G17Client';
import { BasicAlert } from '../../alert/BasicAlert';
import Loader from '../../loader';
import SimpleTooltip from '../../simple-tooltip';
import Table from '../../table/Table';

type State = ReduxStateLoadable<SafeUser[], []>

const title = 'Assurance Team';
const organizationMembersState: State = {
  loading: false,
  loaded: false,
  errored: false,
  data: [],
};

const getUserText = (user: SafeUser) => {
  if (user.active) {
    return (<span>{user.firstName} {user.surname} </span>);
  }

  return <span className={'user-inactive'}>
    {user.firstName} {user.surname} <SimpleTooltip
      id={`optionTooltip-${user._id}`}
      text={'User is not active'}
      component={<i className='fas fa-info-circle' />} />
  </span>;
}

export const ManageOrganizationMembers = ({ organizationId }: { organizationId: string }) => {
  const [organizationMembersData, setOrganizationMembersData] = useState<State>(organizationMembersState);

  useEffect(() => {
    if (!organizationMembersData.loading && !organizationMembersData.loaded && !organizationMembersData.errored) {
      setOrganizationMembersData({ ...organizationMembersData, loading: true });
      G17Client.getAssuranceOrganizationMembers(organizationId)
        .then(data => setOrganizationMembersData({
          ...organizationMembersData,
          data,
          loaded: true,
          loading: false
        }))
        .catch(e => {
          setOrganizationMembersData({
            ...organizationMembersData,
            loading: false,
            errored: true,
            errorMessage: e.message
          });
        });
    }
  }, [organizationMembersData, organizationId]);

  if (!organizationMembersData.loaded) {
    return <Loader />;
  }

  return (
    <div className={'add-stakeholder'}>
      <div className='add-member mb-1'>
        <BasicAlert type={'danger'}>{organizationMembersData.errorMessage}</BasicAlert>
      </div>
      <div>
        <div>
          <label className={'strong'}>Existing Team Members</label>
          <p>All the Assurers currently assigned access to the Assurance Tracker</p>
        </div>
        <div className='mt-3'>
          <div className='pb-2 pb-lg-0 pr-lg-0'>
            <div className='user-list'>
              <Table data={organizationMembersData.data} columns={[{
                Header: title,
                resizable: false,
                id: 'name',
                sortable: true,
                accessor: (user: SafeUser) => <span className='dont_translate'>{getUserText(user)}</span>
              }]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
