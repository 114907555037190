import { getGroup } from '@g17eco/core';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { hasRequiredTags, isGroupEnabled } from '../../constants/groups';
import { useAppSelector } from '../../reducers';
import { getRootOrg } from '../../selectors/initiative';
import { isOrgManager } from '../../selectors/user';
import G17Client from '../../services/G17Client';
import { addSiteAlert, SiteAlertColors } from '../../slice/siteAlertsSlice';
import SimpleTooltip from '../simple-tooltip';
import { PACK, SURVEY } from '@constants/terminology';

interface BuySurveyButtonProps {
  scopeTag: string;
  tooltip?: string;
}

export const BuySurveyButton = ({ scopeTag, tooltip }: BuySurveyButtonProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isManager = useAppSelector(isOrgManager);
  const scopeConfig = useAppSelector(state => state.globalData.loaded ? state.globalData.data?.config?.survey.scope : undefined);
  const rootInitiative = useAppSelector(getRootOrg);

  const group = getGroup('standards-and-frameworks', scopeTag);
  const canAccess = isGroupEnabled(group, scopeConfig);

  if (canAccess) {
    return null;
  }

  const productCodes = group?.requiredTags;
  if (!hasRequiredTags(productCodes)) {
    return null;
  }

  const canUpgrade = rootInitiative && isManager && productCodes.length > 0;
  const displayTooltip = !canUpgrade ? tooltip : '';
  const premiumClick = !canUpgrade
    ? undefined
    : () =>
      G17Client.createCheckoutSession({
        initiativeId: rootInitiative._id,
        productCodes,
        returnUrl: location.pathname,
      })
        .then((s) => window.location.href = s.url)
        .catch((e) =>
          dispatch(
            addSiteAlert({
              content: e.message,
              color: SiteAlertColors.Danger,
            })
          )
        );

  return (
    <SimpleTooltip text={displayTooltip}>
      <Button
        outline
        disabled={!canUpgrade}
        className='card-grid-button buy-button'
        onClick={premiumClick}
      >
        <span>Buy {SURVEY.ADJECTIVE} {PACK.SINGULAR}</span>
      </Button>
    </SimpleTooltip>
  );
};
