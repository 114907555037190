import { useToggle } from '../../hooks/useToggle';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { SurveyUserRoles } from '../../constants/users';
import {
  filterExplicitUsers,
  filterUsersByRole,
  hasUserRolePermission,
  putCurrentUserToTop,
  surveyRoles,
} from './ultis';
import { roles } from '../../constants/roles';
import { DelegatedUsersTableProps, User } from './types';
import Table from '../table/Table';
import { UserRow } from './UserRow';

export const DelegatedUsersTable = (props: DelegatedUsersTableProps) => {
  const { canAccessVerification, isReadonly } = props;
  const [showExplicitDelegateOnly, toggleShowExplicitDelegateOnly] = useToggle(true);
  return (
    <>
      <ShowAllDelegatedUsersSwitch shown={showExplicitDelegateOnly} toggle={toggleShowExplicitDelegateOnly} />
      <div className='row user-lists mt-3'>
        {canAccessVerification ? (
          surveyRoles.map((userRole) => (
            <UsersTable key={userRole} {...{ ...props, userRole, showExplicitDelegateOnly}} />
          ))
        ) : (
          <UsersTable {...{ ...props, userRole: SurveyUserRoles.Stakeholder, showExplicitDelegateOnly }} />
        )}
      </div>
      {isReadonly ? (
        <p className='text-center mb-0 theme-TextMedium'>
          This survey has been completed and is locked. Delegation is not possible.
        </p>
      ) : null}
    </>
  );
};

const ShowAllDelegatedUsersSwitch = ({ shown, toggle }: { shown: boolean; toggle: () => void }) => {
  return (
    <FormGroup switch className='d-flex align-items-center justify-content-end pr-1'>
      <Input type='switch' checked={!shown} id='view-all-users' className='m-0' onChange={toggle} />
      <Label className='ml-1' check>
        Include system delegated metrics
      </Label>
    </FormGroup>
  );
};

const UsersTable = (
  props: DelegatedUsersTableProps & { userRole: SurveyUserRoles; showExplicitDelegateOnly: boolean }
) => {
  const { canAccessVerification, userRole, startDelegating, currentUser, survey, utrvs, initiativeId, isReadonly } = props;
  const className = !canAccessVerification ? 'col' : 'col-12 col-lg-6 pb-2 pb-lg-0 pr-lg-0';

  return (
    <div key={`delegation-modal-${userRole}`} className={className}>
      <UserList {...props} />
      {hasUserRolePermission(userRole, utrvs, survey, currentUser, initiativeId) && !isReadonly ? (
        <AddBtn userRole={userRole} handleAdd={() => startDelegating(userRole)} />
      ) : null}
    </div>
  );
};

const AddBtn = ({ userRole, handleAdd }: { handleAdd: () => void; userRole: SurveyUserRoles }) => {
  const title = `Add a ${roles[userRole].shortName}`;

  return (
    <div className='p-1 text-right'>
      <Button color='secondary' onClick={handleAdd}>
        {title}
      </Button>
    </div>
  );
};

const UserList = (
  props: DelegatedUsersTableProps & { showExplicitDelegateOnly: boolean; userRole: SurveyUserRoles }
) => {
  const { showExplicitDelegateOnly, userRole, users: rawUsers, currentUser, utrvs, handleRemoveUser, isReadonly } = props;

  const filteredUsers = filterUsersByRole(rawUsers, userRole);

  const users = showExplicitDelegateOnly ? filterExplicitUsers(userRole, filteredUsers) : filteredUsers;

  const sortedUsers = putCurrentUserToTop(userRole, users, currentUser);

  const title = `${roles[userRole].shortName}s`;

  return (
    <div className='user-list m-1'>
      <Table
        data={sortedUsers}
        defaultSortIcon={null}
        columns={[
          {
            Header: () => (
              <div className='d-flex justify-content-between align-items-center'>
                <span className='text-ThemeTextDark text-uppercase mr-2'>{title}</span>
                <span className='fw-normal'>
                  {sortedUsers.length} / {filteredUsers.length}
                </span>
              </div>
            ),
            resizable: false,
            id: 'name',
            accessor: (user: User) => (
              <UserRow userRole={userRole} user={user} utrvs={utrvs} handleRemoveUser={handleRemoveUser} isReadonly={isReadonly}/>
            ),
            disableSortBy: true,
          },
        ]}
      />
    </div>
  );
};

