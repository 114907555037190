import { UtrValueType } from '@g17eco/types/universalTracker';

// types that doesn't include value list, table.
export const SIMPLE_TYPES: string[] = [
  UtrValueType.Number,
  UtrValueType.Percentage,
  UtrValueType.Date,
  UtrValueType.Text,
  UtrValueType.Sample,
];

export enum MetaAction {
  Select = 'select-option',
  Clear = 'clear',
}

export const getQuestionOptionLabel = ({ label, isAllowed }: { label: string; isAllowed: boolean }) => {
  if (isAllowed) {
    return label;
  }
  return (
    <div className='d-flex align-items-center justify-content-between gap-2'>
      <div className='text-truncate'>{label}</div>
      <div className='text-xs text-nowrap text-ThemeDangerExtradark'>Not supported</div>
    </div>
  );
};
