export const OverrideUnitAlert = ({ show }: { show: boolean }) => {
  if (!show) {
    return null;
  }
  return (
    <div className='text-xs text-ThemeWarningDark mt-1'>
      WARNING: You have multiple units set for this unit type. Please be aware updating this unit will update for all
      metrics.
    </div>
  );
};
