import { useHistory } from 'react-router-dom';
import {
  useCreatePortfolioInsightDashboardMutation,
  useGetInsightDashboardsByPortfolioQuery,
} from '../../../../../api/portfolio-insight-dashboards';
import { ROUTES } from '../../../../../constants/routes';
import { useAppSelector } from '../../../../../reducers';
import { isStaff } from '../../../../../selectors/user';
import { generateUrl } from '../../../../util';
import { AddDashboardOption, InsightDashboardOption, getDashboardOption } from '../../utils/sidebar';
import { InsightsSidebar } from './InsightsSidebar';
import { useSiteAlert } from '../../../../../hooks/useSiteAlert';
import Loader from '@components/loader';
import { DashboardSettingsSidebar } from '@routes/custom-dashboard/dashboard-settings/DashboardSettingsSidebar';
import { useToggle } from '@hooks/useToggle';
import { DEFAULT_FILTERS } from '@routes/custom-dashboard/dashboard-settings/utils';
import {
  GridDashboardItem,
  InsightDashboard,
  InsightDashboardFilters,
  InsightDashboardItemType,
  InsightDashboardType
} from '@g17eco/types/insight-custom-dashboard';
import { generateGridDashboardItem } from '@routes/custom-dashboard/utils';

interface PortfolioInsightsSidebarProps {
  portfolioId: string;
  dashboardId?: string;
}

export const PortfolioInsightsSidebar = ({ portfolioId, dashboardId }: PortfolioInsightsSidebarProps) => {
  const history = useHistory();

  const isUserStaff = useAppSelector(isStaff);
  const addDashboardOption = isUserStaff ? getDashboardOption(true) : undefined;
  const { addSiteError } = useSiteAlert();

  const { data: dashboards, isFetching } = useGetInsightDashboardsByPortfolioQuery(portfolioId, { skip: !isUserStaff });
  const [createInsightDashboard, { isLoading }] = useCreatePortfolioInsightDashboardMutation();

  const [openSettingsSidebar, toggleSettingsSidebar] = useToggle(false);

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
  }));

  const options: (InsightDashboardOption | AddDashboardOption)[] = [
    {
      label: 'SDG Insights',
      value: '',
    },
    ...dashboardOptions,
    ...(addDashboardOption ? [addDashboardOption] : []),
  ];

  const handleAddDashboard = () => {
    if (!portfolioId) {
      return;
    }
    toggleSettingsSidebar();
  };

  const handleSave = async (changes: Partial<InsightDashboard>) => {
    if (!portfolioId) {
      return;
    }

    const items: GridDashboardItem[] = [];
    if (changes.filters?.sdgContribution?.enabled) {
      const item = generateGridDashboardItem({ type: InsightDashboardItemType.SDGContributionChart }, items);
      items.push(item);
    }

    await createInsightDashboard({
      initiativeId: portfolioId,
      ...changes,
      items,
      title: changes.title ?? 'Custom Dashboard',
    })
      .unwrap()
      .then((dashboard) => {
        handleNavigateCustom(dashboard._id);
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const handleNavigate = () => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHTS, { portfolioId });
    history.push(url);
  };

  const handleNavigateCustom = (dashboardId: string) => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHT_DASHBOARDS, { portfolioId, dashboardId });
    history.push(url);
  };

  const handleClickOption = (item: InsightDashboardOption | AddDashboardOption) => () => {
    if (item.disabled) {
      return;
    }
    if (isUserStaff && item.value === addDashboardOption?.value) {
      return handleAddDashboard();
    }
    if (isUserStaff && item.isCustom) {
      return handleNavigateCustom(item.value);
    }
    return handleNavigate();
  };

  const currentPage = dashboardId ?? '';

  return (
    <>
      {isLoading || isFetching ? <Loader /> : null}
      <InsightsSidebar options={options} currentPage={currentPage} handleClickOption={handleClickOption} />
      <DashboardSettingsSidebar
        isOpenSidebar={openSettingsSidebar}
        toggleSidebar={toggleSettingsSidebar}
        dashboard={{
          title: 'Custom Dashboard',
          filters: DEFAULT_FILTERS,
          type: InsightDashboardType.Custom,
          items: [],
        }}
        handleSave={handleSave}
        hideOptions={[
          InsightDashboardFilters.ShareWithSubsidiaries,
          InsightDashboardFilters.BaselinesTargets,
          InsightDashboardFilters.Period,
          InsightDashboardFilters.Survey,
          InsightDashboardFilters.Privacy,
        ]}
        availablePeriods={[]}
      />
    </>
  );
};
