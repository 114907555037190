import { Input } from 'reactstrap';

interface Props {
  value: string;
  handleChange: (value: string) => void;
}
export const FreeText = ({ value, handleChange }: Props) => {
  return (
    <>
      <p className='text-medium mb-2'>Enter headline, description, or explanation</p>
      <Input
        type='textarea'
        placeholder='Enter text here...'
        value={value}
        onChange={(e) => handleChange(e.currentTarget.value)}
        rows={8}
      />
    </>
  );
};
