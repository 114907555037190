import { InitiativeMin, SafeInitiativeFields } from '../types/initiative';
import {
  GridDashboardItem,
  GridDashboardItemCreate,
  InsightDashboard,
  OverviewDashboard,
  SharingItem,
} from '../types/insight-custom-dashboard';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { UtrVariable } from '@routes/summary/insights/utils/constants';
import { DashboardItemFilters, HistoricalUtrs } from './insights';
import { createFormData } from '@utils/files';
import { formDataConfig } from '@services/G17Client';
import { IntegrationSource } from '@g17eco/types/integration';

export type CreateParam = Pick<InsightDashboard, 'title' | 'initiativeId' | 'items'>;

type UpdatingDashboardItem = Omit<GridDashboardItem, 'files'> & { files?: { documentId: string; ratio?: number }[] };
export type UpdateParam = Pick<InsightDashboard, '_id' | 'title' | 'filters' | 'initiativeId'> & {
  deletedDocumentIds?: string[];
  items: UpdatingDashboardItem[];
};

type UpdateDataParam = Partial<Pick<InsightDashboard, 'title' | 'filters' | 'items'>>;

export type ByIdParam = {
  initiativeId: string;
  dashboardId: string;
};

type ByIdParamWithQuery = ByIdParam & { queryParams: DashboardItemFilters };

export type DashboardItemCreateParam = GridDashboardItemCreate & {
  initiativeId: string;
  dashboardId: string;
  utrVariables?: UtrVariable[];
};

type ShareParam = ByIdParam & Pick<SharingItem, 'enabled'>;

type OverviewItemCreateParam = {
  initiativeId: string;
  item: GridDashboardItem;
};

type OverviewItemRemoveParam = {
  initiativeId: string;
  itemId: GridDashboardItem['_id'];
};

export type UploadFileParam = ByIdParam & {
  files: { [key: string]: File[] };
};

export interface UploadedDashboardFile {
  documentId: string;
  name: string;
  itemId: string;
}

export type CreateDashboardItemResponse = {
  item: GridDashboardItem;
  utrsData?: HistoricalUtrs[];
};

const getUrl = ({ initiativeId, dashboardId = '' }: { initiativeId: string; dashboardId?: string }) =>
  `/initiatives/${initiativeId}/insight-dashboards/${dashboardId}`;

const getDashboardItemUrl = (initiativeId: string, dashboardId: string, itemId?: string) =>
  `/initiatives/${initiativeId}/insight-dashboards/${dashboardId}/item${itemId ? `/${itemId}` : ''}`;

export const INSIGHT_DASHBOARDS_TAG = 'insight-dashboards';
const INSIGHT_DASHBOARD_TAG = 'insight-dashboard';
const TAGS = [INSIGHT_DASHBOARDS_TAG];
const OVERVIEW_TAGS = ['overview-insight'];
const SUMMARY_TAG = ['summary-insight'];

export const insightDashboardsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [...TAGS, INSIGHT_DASHBOARD_TAG, ...OVERVIEW_TAGS],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInsightDashboardsByInitiative: builder.query<InsightDashboard[], InitiativeMin['_id']>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl({ initiativeId }),
          method: 'get',
        }),
        providesTags: TAGS,
      }),
      getInsightDashboardById: builder.query<InsightDashboard, ByIdParamWithQuery>({
        transformResponse,
        query: ({ initiativeId, dashboardId, queryParams }) => ({
          url: getUrl({ initiativeId, dashboardId }),
          method: 'get',
          params: queryParams,
        }),
        providesTags: (_result, _error, agrs) => [{ type: INSIGHT_DASHBOARD_TAG, id: agrs.dashboardId }],
      }),
      createInsightDashboard: builder.mutation<InsightDashboard, CreateParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: getUrl({ initiativeId }),
          method: 'post',
          data,
        }),
      }),
      updateInsightDashboard: builder.mutation<InsightDashboard, UpdateParam>({
        invalidatesTags: (_result, _error, agrs) => [...TAGS, { type: INSIGHT_DASHBOARD_TAG, id: agrs._id }],
        transformResponse,
        query: ({ _id: dashboardId, initiativeId, ...rest }) => ({
          url: getUrl({ initiativeId, dashboardId }),
          method: 'put',
          data: rest,
        }),
      }),
      deleteInsightDashboard: builder.mutation<InsightDashboard, ByIdParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId, dashboardId }) => {
          return {
            url: getUrl({ initiativeId, dashboardId }),
            method: 'delete',
          };
        },
      }),
      createInsightDashboardItem: builder.mutation<CreateDashboardItemResponse, DashboardItemCreateParam>({
        invalidatesTags: (_result, _error, agrs) => [{ type: INSIGHT_DASHBOARD_TAG, id: agrs.dashboardId }],
        transformResponse,
        query: ({ initiativeId, dashboardId, ...data }) => ({
          url: getDashboardItemUrl(initiativeId, dashboardId),
          method: 'post',
          data,
        }),
      }),
      shareInsightDashboard: builder.mutation<InsightDashboard, ShareParam>({
        invalidatesTags: (_result, _error, agrs) => [
          { type: INSIGHT_DASHBOARD_TAG, id: agrs.dashboardId },
          ...TAGS,
          ...SUMMARY_TAG,
        ],
        transformResponse,
        query: ({ dashboardId, initiativeId, enabled }) => ({
          url: `${getUrl({ initiativeId, dashboardId })}/share`,
          method: 'put',
          data: { enabled },
        }),
      }),
      getSharableInsightDashboardById: builder.query<
        { dashboard: InsightDashboard; initiative?: SafeInitiativeFields },
        Pick<ByIdParamWithQuery, 'dashboardId'> & Pick<SharingItem, 'token'>
      >({
        transformResponse,
        query: ({ dashboardId, token }) => ({
          url: `o/insight-dashboards/${dashboardId}/token/${token}`,
          method: 'get',
        }),
        providesTags: TAGS,
      }),
      duplicateInsightDashboard: builder.mutation<InsightDashboard, ByIdParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ dashboardId, initiativeId }) => ({
          url: `${getUrl({ initiativeId, dashboardId })}/duplicate`,
          method: 'post',
        }),
      }),
      getSummaryInsightDashboard: builder.query<InsightDashboard | OverviewDashboard, ByIdParamWithQuery>({
        transformResponse,
        query: ({ initiativeId, dashboardId, queryParams }) => ({
          url: `/initiatives/${initiativeId}/insight-dashboards/summary/${dashboardId}`,
          method: 'get',
          params: queryParams,
        }),
        providesTags: [...OVERVIEW_TAGS, ...SUMMARY_TAG],
      }),
      updateSummaryInsightDashboard: builder.mutation<
        InsightDashboard | OverviewDashboard,
        ByIdParam & UpdateDataParam
      >({
        transformResponse,
        query: ({ initiativeId, dashboardId, ...data }) => ({
          url: `/initiatives/${initiativeId}/insight-dashboards/summary/${dashboardId}`,
          method: 'put',
          data,
        }),
        invalidatesTags: SUMMARY_TAG,
      }),
      addOverviewDashboardItem: builder.mutation<GridDashboardItem, OverviewItemCreateParam>({
        invalidatesTags: OVERVIEW_TAGS,
        transformResponse,
        query: ({ initiativeId, item }) => ({
          url: `/initiatives/${initiativeId}/insight-dashboards/summary/overview/item`,
          method: 'post',
          data: item,
        }),
      }),
      removeOverviewDashboardItem: builder.mutation<GridDashboardItem, OverviewItemRemoveParam>({
        invalidatesTags: OVERVIEW_TAGS,
        transformResponse,
        query: ({ initiativeId, itemId }) => ({
          url: `/initiatives/${initiativeId}/insight-dashboards/summary/overview/item/${itemId}`,
          method: 'delete',
        }),
      }),
      uploadInsightDashboardMediaFiles: builder.mutation<UploadedDashboardFile[], UploadFileParam>({
        transformResponse,
        query: ({ files, initiativeId, dashboardId }) => ({
          url: `${getUrl({ initiativeId, dashboardId })}/upload`,
          method: 'post',
          data: createFormData(files),
          ...formDataConfig,
        }),
      }),
      getInsightDashboardIntegrations: builder.query<IntegrationSource[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/insight-dashboards/integrations`,
          method: 'get',
        }),
      }),
    }),
  });

export const {
  useCreateInsightDashboardMutation,
  useGetInsightDashboardByIdQuery,
  useUpdateInsightDashboardMutation,
  useDeleteInsightDashboardMutation,
  useGetInsightDashboardsByInitiativeQuery,
  useCreateInsightDashboardItemMutation,
  useShareInsightDashboardMutation,
  useGetSharableInsightDashboardByIdQuery,
  useDuplicateInsightDashboardMutation,
  useGetSummaryInsightDashboardQuery,
  useUpdateSummaryInsightDashboardMutation,
  useAddOverviewDashboardItemMutation,
  useRemoveOverviewDashboardItemMutation,
  useUploadInsightDashboardMediaFilesMutation,
  useGetInsightDashboardIntegrationsQuery,
} = insightDashboardsApi;
