import { useCallback, useEffect, useState } from 'react';
import { DownloadType, VisibilityStatus } from '../../types/download';
import { getAnalytics } from '../../services/analytics/AnalyticsService';
import { DownloadButton } from '../button/DownloadButton';
import { DownloadSettingModal, DownloadSettingPropType } from '../downloads/DownloadSettingModal';
import G17Client, { RequesterDownloadData } from '../../services/G17Client';
import { handleSettingsChange } from '../downloads';
import { convertToUtrvStatuses, DownloadSettingsType } from '../downloads/util/downloadReportHandler';
import { getDownloadScope, getScopeGroupData } from '../report-output/sgx-metric-report/downloadScope';
import { UtrvStatus } from '../../constants/status';
import { MetricGroup } from '../../types/metricGroup';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { getSGXMetric, getSustainabilityReport } from '../report-output/outputs';
import { DataSource, DataSources } from '../report-output/sustainability-report';
import { useAppDispatch } from '../../reducers';
import { addSiteAlert, SiteAlertColors } from '../../slice/siteAlertsSlice';
import { handleRouteError } from '../../logger';
import { useGetDataShareSurveyQuery } from '../../api/g17ecoApi';
import GenerateCharts from '../report-output/sgx-metric-report/GenerateCharts';
import { MainDownloadCode } from '../../config/app-config';
import { CombinedDataScopeAccess, DataScopeAccess, RequesterType } from '../../types/dataShare';
import { PACK, SURVEY } from '@constants/terminology';
import { getDefaultScope, getScopeModules } from '@utils/survey-scope';
import { Scope } from '@models/surveyData';

export interface DownloadButtonProps extends Pick<DownloadSettingPropType, 'titleOptions'> {
  initiativeId: string;
  requesterType: RequesterType;
  requesterId: string;
  surveyId: string;
  disabled?: boolean;
  primaryCode: MainDownloadCode;
  metricGroups: Pick<MetricGroup, '_id' | 'groupName' | 'universalTrackers'>[];
  combinedDataScopeAccess: CombinedDataScopeAccess | undefined;
}

// Create brand new state object to reset it
const getInitialDataSourcesState = () => ({
  ghgEmissions: {
    loaded: false,
  },
  ghgEmissionsIntensity: {
    loaded: false,
  },
  energyConsumption: {
    loaded: false,
  },
  waterConsumption: {
    loaded: false,
  },
  social: {
    loaded: false,
  },
  governance: {
    loaded: false,
  },
});

const getValidScope = (
  combinedDataScopeAccess: CombinedDataScopeAccess | undefined,
  surveyScope: Scope | undefined
) => {
  // @TODO [DATA-SHARE] Legacy missing dataScope, should always be set moving forward
  if (!combinedDataScopeAccess) {
    return surveyScope;
  }

  const access = combinedDataScopeAccess.access;
  if (access === DataScopeAccess.None) {
    return getDefaultScope();
  }

  if (access === DataScopeAccess.Full || !surveyScope) {
    return surveyScope;
  }

  const accessScope = combinedDataScopeAccess.scope ?? {};

  const surveyScopePacks = Object.values(surveyScope).flat();
  return Object.entries(accessScope).reduce((acc, [key, scopeGroup]) => {
    const validPacks = scopeGroup.filter((scopeItem) => surveyScopePacks.includes(scopeItem));
    if (validPacks.length > 0) {
      acc[key as keyof Scope] = validPacks;
    }
    return acc;
  }, getDefaultScope());
};

// Internal status, not be used anywhere else
enum DownloadStatus {
  // Start of the process
  Initial,
  // API data load
  Loaded,
  // Chart generated and data download (docx) completed
  Completed,
}

export const DataShareReportDownloadButton = ({
  surveyId,
  primaryCode,
  initiativeId,
  requesterId,
  requesterType,
  disabled,
  metricGroups,
  combinedDataScopeAccess,
}: DownloadButtonProps) => {
  const isSGX = primaryCode === 'sgx_metrics';

  // initial => load data => generate chart and mark as completed
  const [downloadStatus, setDownloadStatus] = useState(DownloadStatus.Initial);

  const [dataSourcesState, setData] = useState(getInitialDataSourcesState());
  const [downloadData, setDownloadData] = useState<RequesterDownloadData | undefined>();
  const [isAllChartsReady, setIsAllChartsReady] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsType>({
    selectedScopes: [], // loaded from loadSurveyScope below
    visibility: VisibilityStatus.ExcludeData,
  });

  const surveyResponse = useGetDataShareSurveyQuery(
    {
      initiativeId,
      requesterId,
      requesterType,
      surveyId,
    },
    { skip: !surveyId }
  );

  const dispatch = useAppDispatch();
  const analytics = getAnalytics();

  useEffect(() => {
    // Need to ensure we have all loaded (only need for SGX for now)
    const allLoaded = Object.keys(dataSourcesState).every((k) => dataSourcesState[k as keyof DataSources].loaded);
    setIsAllChartsReady(allLoaded);
  }, [dataSourcesState]);

  useEffect(() => {
    if (isAllChartsReady && downloadStatus === DownloadStatus.Loaded && downloadData) {
      const docProps = {
        survey: downloadData.survey,
        dataSources: dataSourcesState,
        questionData: downloadData.historical,
        targets: downloadData.targets,
        scopeGroupHistoricalData: getScopeGroupData(
          downloadData.historical,
          downloadSettings.selectedScopes,
          downloadData.blueprintContributions
        ),
        visibilityStatus: downloadSettings.visibility,
      };

      const promise = isSGX ? getSGXMetric(docProps) : getSustainabilityReport(docProps);

      promise
        .then(() => {
          setDownloadStatus(DownloadStatus.Completed);
          setData(getInitialDataSourcesState());
        })
        .catch((e) => console.log(e));
    }
  }, [dataSourcesState, surveyId, downloadStatus, downloadData, downloadSettings, isAllChartsReady, isSGX]);

  const downloadDocx = async () => {
    setDownloadStatus(DownloadStatus.Initial);

    return G17Client.reportDataWithHistoryScope({
      surveyId,
      initiativeId,
      requesterId,
      requesterType,
      downloadScope: {
        scope: getDownloadScope(downloadSettings.selectedScopes),
        targets: true, // Include targets
      },
    })
      .then((data) => {
        analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
          initiativeId,
          surveyId: surveyId,
          source: 'data_share_downloads_page',
          scopeValue: 'all',
          type: 'docx',
        });
        setDownloadData(data);
        setDownloadStatus(DownloadStatus.Loaded);
      })
      .catch((e) => {
        handleRouteError(e, { surveyId, initiativeId, requesterId, requesterType });
        dispatch(
          addSiteAlert({
            color: SiteAlertColors.Danger,
            content: 'There was a problem downloading this report',
          })
        );
      });
  };

  const handleCloseModal = () => {
    setOpenSettings(false);
  };

  const loadSurveyScope = () => {
    const { scope: surveyScope } = surveyResponse.data?.surveyData ?? {};
    const validScope = getValidScope(combinedDataScopeAccess, surveyScope);
    const selectedScopes = validScope ? getScopeModules({ scope: validScope, metricGroups }) : [];

    return setDownloadSettings({ ...downloadSettings, selectedScopes });
  };

  const updateData = useCallback(
    (key: string, data: DataSource) => {
      setData((dataSources) => ({
        ...dataSources,
        [key]: data,
      }));
    },
    [setData]
  );

  const openDownloadSettings = () => {
    loadSurveyScope();
    setOpenSettings(true);
  };

  const downloadModalProps: DownloadSettingPropType = {
    title: 'Sustainability report',
    titleOptions: {
      scopes: {
        tooltip: `Selected ${SURVEY.ADJECTIVE} ${PACK.PLURAL} will be included within the report.`,
      },
    },
    isOpen: openSettings,
    downloadSettings,
    handleClose: handleCloseModal,
    disabledOptions: ['detailed'],
    handleChange: (event) => handleSettingsChange(event, setDownloadSettings, downloadSettings),
    onDownload: async (type) => {
      switch (type) {
        case DownloadType.Docx:
          return downloadDocx();
        case DownloadType.Csv:
        case DownloadType.Xlsx:
        default:
          return G17Client.downloadDataShareSurveySimple({
            surveyId,
            requesterType,
            requesterId,
            initiativeId,
            type: DownloadType.Csv === type ? 'csv' : 'xlsx',
            downloadScope: {
              scope: getDownloadScope(downloadSettings.selectedScopes),
              visibility: downloadSettings.visibility,
              ...convertToUtrvStatuses(
                downloadSettings.assuranceStatus ?? downloadSettings.status ?? UtrvStatus.Verified
              ),
            },
          });
      }
    },
  };

  return (
    <div className='ctl-report-generator mt-5'>
      {downloadData && downloadData.historical.length > 0 ? (
        <GenerateCharts
          updateData={updateData}
          questionData={downloadData.historical}
          visibility={downloadSettings.visibility}
        />
      ) : null}
      <DownloadButton
        className='doc-btn'
        color='secondary'
        outline={true}
        disabled={disabled || surveyResponse.isLoading}
        onClick={openDownloadSettings}
      >
        Download report
        <i className='fas fa-file-download ml-2' />
      </DownloadButton>
      <DownloadSettingModal {...downloadModalProps} />
    </div>
  );
};
