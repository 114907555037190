import React from 'react';
import { Button, ButtonProps, DropdownItem } from 'reactstrap';
import SimpleTooltip from '../../simple-tooltip';
import { FlagProperties } from '../../../services/G17Client';
import { BulkActionToolbarSelectedUtrv } from './BulkActionToolbar';
import { QUESTION } from '@constants/terminology';

export const countQuestions = (questions: Pick<BulkActionToolbarSelectedUtrv, '_id'>[]) => {
  return new Set(questions.map(q => q._id)).size;
}

interface BulkButtonProps extends React.PropsWithChildren<ButtonProps> {
  component?: typeof DropdownItem | typeof Button;
  tooltip: string;
}

export const BulkButton = (props: BulkButtonProps) => {
  const { component = Button, ...rest } = props;

  return <SimpleTooltip tooltip={props.tooltip}>
    {React.createElement(component, {
      color: 'secondary',
      ...rest,
      className: props.className ?? 'my-1 ml-2'
    })}
  </SimpleTooltip>
}

export interface ToggleButtonProps {
  selectedQuestions: BulkActionToolbarSelectedUtrv[];
  field: keyof FlagProperties;
  action: string;
  reverseAction: string;
  icon?: string;
  handleBulkFlagChange: (changes: FlagProperties, ids: string[]) => void;
  className?: string;
  component?: typeof DropdownItem | typeof Button;
}

export const ToggledButton = (props: ToggleButtonProps) => {
  const { selectedQuestions, field, action, reverseAction, icon, handleBulkFlagChange, className } = props;

  const allHasFlag = selectedQuestions.every((v) => v[field] === true);
  const isSelected = countQuestions(selectedQuestions) > 0;
  const currentAction = isSelected && allHasFlag ? action : reverseAction;
  const changes = { [field]: !allHasFlag } as FlagProperties;

  return (
    <BulkButton
      tooltip={`Mark ${QUESTION.PLURAL} as ${currentAction}.`}
      component={props.component}
      onClick={() =>
        handleBulkFlagChange(
          changes,
          selectedQuestions.map((q) => q._id)
        )
      }
      className={className}
    >
      {icon ? <i className={`fa ${icon} mr-2`} /> : null}
      {currentAction}
    </BulkButton>
  );
};
