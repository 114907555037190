import { g17ecoApi, transformResponse } from '@api/g17ecoApi';
import { MetricGroup } from '@g17eco/types/metricGroup';

export interface UsedScopes {
  standards: Record<string, string[]>;
  frameworks: Record<string, string[]>;
}

export const organizationApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['organization-used-scopes', 'organization-metric-groups'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrganizationUsedScopes: builder.query<UsedScopes, string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/organizations/${initiativeId}/scopes/used`,
          method: 'get',
        }),
        providesTags: ['organization-used-scopes'],
      }),
      getOrganizationMetricGroups: builder.query<MetricGroup[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: `/organizations/${initiativeId}/metric-groups`,
          method: 'get',
        }),
        providesTags: ['organization-metric-groups'],
      }),
    }),
  });

export const { useGetOrganizationUsedScopesQuery, useGetOrganizationMetricGroupsQuery } = organizationApi;
