import { DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { UserStatsFiltersProps, UserStatsPermissionView } from '../types';
import React, { useMemo, useState } from 'react';
import { AdminUserTable } from './AdminUserTable';
import { useHistory } from 'react-router-dom';
import { MessageModal } from '@components/message-modal/MessageModal';
import { getUTCDateRange } from '@utils/date';
import { DateRangeType } from '@g17eco/types/common';
import { useGetUserStatsQuery, UserDelegationStats } from '@api/initiative-stats';
import { generateUsersFlexSearchMap } from '../utils';
import { Button } from 'reactstrap';
import { DebouncedSearchBox, Toggle } from '@g17eco/molecules';
import { MessageType } from '@components/message-modal/types';
import { BulkUsersMessageForm } from '@components/message-modal/partials/BulkUsersMessageForm';

interface Props {
  initiativeId: string;
  dateRange: DateRangeType;
  url: string;
  userId: string | null;
}

const defaultUserFilters: UserStatsFiltersProps = {
  view: UserStatsPermissionView.Explicit,
};

export const UserStatsTable = (props: Props) => {
  const { initiativeId, dateRange, url, userId } = props;
  const [userFilter, setUserFilter] = useState(defaultUserFilters);
  const [showBulkUsersMessage, setShowBulkUsersMessage] = useState<boolean>(false);

  const utcDateRange = getUTCDateRange(dateRange);

  const { data: userListStats = [], error } = useGetUserStatsQuery({ initiativeId, ...utcDateRange });

  const history = useHistory();

  const { searchText, view: filterView } = userFilter;
  const isPermissionFilter = filterView === UserStatsPermissionView.Permission;

  const users: UserDelegationStats[] = useMemo(() => {
      return userListStats.map((user) => ({
        ...user,
        delegationStats: filterView !== UserStatsPermissionView.Permission ? user.utrvDelegation : user.totalDelegation,
      }));
  }, [userListStats, filterView]);

  const filteredRows = useMemo(() => {
    if (searchText) {
      const searchIndex = generateUsersFlexSearchMap(users);
      const result = searchIndex.search(searchText);
      const matchedIds = new Set(result.map((item) => item.result).flat());
      return users.filter((user) => matchedIds.has(user._id));
    }
    return users;
  }, [users, searchText]);

  const handleToggle = () => {
    const view = isPermissionFilter ? UserStatsPermissionView.Explicit : UserStatsPermissionView.Permission;
    setUserFilter((prev) => ({ ...prev, view }));
  };

  const onFilterChange = (key: keyof UserStatsFiltersProps) => (value?: string) => {
    setUserFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <DashboardSectionTitle
        title='Users'
        buttons={
          users.length > 0
            ? [
                <Button className='py-1' onClick={() => setShowBulkUsersMessage(true)}>
                  <span className='lh-base'>
                    <i className='fa-light fa-envelope mr-2 fs-5' />
                    Bulk message users
                  </span>
                </Button>,
              ]
            : []
        }
      />
      <DashboardSection>
        <div className='d-flex align-items-center gap-4 mb-3'>
          <DebouncedSearchBox
            placeholder='Search...'
            onTextChange={onFilterChange('searchText')}
            classNames={{ wrapper: 'flex-fill' }}
          />
          <Toggle
            className={{ form: 'd-flex align-items-center pl-0 mb-0 justify-content-end' }}
            checked={isPermissionFilter}
            onChange={handleToggle}
            label='Include system delegated metrics'
          />
        </div>
        <AdminUserTable
          onUserClick={(user) => {
            history.push(`${url}/users/${user._id}`);
          }}
          initiativeId={initiativeId}
          highlightUserId={userId}
          users={filteredRows}
          error={error}
        />
      </DashboardSection>
      {showBulkUsersMessage ? (
          <MessageModal
            users={users}
            toggle={() => setShowBulkUsersMessage(false)}
            type={MessageType.User}
            Component={({ onChange, form }) => {
              return <BulkUsersMessageForm users={users} onChange={onChange} form={form} />;
            }}
          />
      ) : null}
    </>
  );
};
