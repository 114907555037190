import React from 'react';
import { useParams } from 'react-router-dom';
import CustomMetrics from '@features/custom-metrics';
import { DEFAULT_CUSTOM_METRICS_USAGE } from '@components/custom-metrics/utils';
import { useGetInitiativeCustomMetricsUsageQuery } from '@api/custom-metrics';
import LoadingPlaceholder from '@components/LoaderContainer/LoadingPlaceholder';

export const CustomMetricManagementRoute = () => {
  const { portfolioId, groupId, view } = useParams<{ portfolioId: string; groupId?: string; view?: string }>();
  const {data: customMetricsUsage = DEFAULT_CUSTOM_METRICS_USAGE, isFetching, refetch} = useGetInitiativeCustomMetricsUsageQuery(portfolioId, { skip: !portfolioId })

  if (isFetching) {
    return <LoadingPlaceholder height={300} isLoading={true} />;
  }

  return (
    <CustomMetrics
      customMetricsUsage={customMetricsUsage}
      refetchMetricsUsage={() => refetch()}
      initiativeId={portfolioId}
      groupId={groupId}
      view={view}
      isPortfolioTracker
    />
  );
};
