import React, { LegacyRef, useEffect } from 'react';
import { Cell, Column, useTable } from 'react-table';
import { getTableConfiguration } from '../../../../utils/universalTracker';
import classnames from 'classnames';
import SimpleTooltip from '../../../simple-tooltip';
import { formatCalculationNumber } from '../../../../utils/formula';
import { RowDataInfo, RowStatus } from '../../question/questionInterfaces';
import { valueListSelectors, loadValueListById } from '../../../../slice/valueListSlice';
import { useSelector, useDispatch, batch } from 'react-redux';
import { getUnitDescription, UnitTypes } from '../../../../utils/units';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { InputColumn, TableColumn, TableInputProps, ValueTable } from '../input/table/InputInterface';
import './TableInputView.scss'
import { hasMinAndMaxColumnType } from '../../../../types/universalTracker';
import { getClasses, getControlIcon } from './utils';
import classNames from 'classnames';
import { UnitConfig } from '@models/surveyData';

type TableInputColumn = Column<RowDataInfo> & { cellProps?: { className?: string } };

const getDisplayUnit = (c: TableColumn, unitConfig: TableInputProps['unitConfig'], fallback: string = ''): string => {
  switch (true) {
    case hasMinAndMaxColumnType(c.type, c.validation?.min, c.validation?.max):
      return '%';
    case c.unitType && c.unitType === UnitTypes.currency:
    case !c.unit:
      return fallback;
    default:
      return c.unitInput ?? unitConfig?.[c.unitType as keyof UnitConfig] ?? c.unit;
  }
}

const emptyData: RowDataInfo = {
  id: 0,
  rowStatus: RowStatus.original,
  data: [],
};

interface TableProps {
  columns: TableInputColumn[];
  rowData: RowDataInfo[];
  editRowId: number;
  hasBeforeAddon: boolean;
  hasAfterAddon: boolean;
}

function Table({ columns, rowData, editRowId, hasBeforeAddon, hasAfterAddon }: TableProps) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: rowData,
  })

  // Render the UI for your table
  return (
    <table
      {...getTableProps()}
      className={classNames({ 'before-addon-table': hasBeforeAddon, 'after-addon-table': hasAfterAddon })}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const isActive = row.original.id === editRowId;
          const classes = `${getClasses(row.original, editRowId)} ${isActive ? 'active-edit-row' : ''}`;
          return (
            <tr {...row.getRowProps({ className: classes })}>
              {row.cells.map((cell: Cell<RowDataInfo> & { column: { cellProps?: object } }) => {
                return <td {...cell.getCellProps(cell.column.cellProps)}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}


type RowUpdateFn = (row: RowDataInfo, id?: number) => void;

type TableInputViewProps = Pick<TableInputProps, 'universalTracker' | 'addons' | 'isDownloadBtnBottom' | 'unitConfig'> & {
  rowData: RowDataInfo[],
  editRowId: number;
  handleEditRow?: RowUpdateFn,
  handleCopyRow?: RowUpdateFn,
  handleDeleteRow?: RowUpdateFn,
  handleClearDeleteRow?: RowUpdateFn,
  disabled?: boolean,
  ignoreType?: boolean,
  ref?: LegacyRef<any>,

  className?: string,
  showTableDownload?: boolean;
  extractColumnCode?: string;
}

interface RowInfo {
  row: {
    id: number;
    original: RowDataInfo;
  }
}

type AccessorParams = { data: InputColumn[] | Record<string, number | string | undefined> };
const TableInputView = (props: TableInputViewProps) => {
  const {
    universalTracker,
    rowData,
    editRowId,
    handleEditRow,
    handleCopyRow,
    handleDeleteRow,
    handleClearDeleteRow,
    disabled,
    ignoreType,
    ref,
    showTableDownload = true,
    extractColumnCode,
    addons,
    isDownloadBtnBottom,
    unitConfig,
  } = props;

  const dispatch = useDispatch();
  const valueListMap = useSelector(valueListSelectors.selectEntities);
  const tableConfiguration: ValueTable | undefined = getTableConfiguration(universalTracker, ignoreType);
  useEffect(() => {
    const emptyValueList = tableConfiguration?.columns.filter(
      (column) => {
        const valueListId = column.listId;
        return valueListId && !valueListMap[valueListId]?.loading && !valueListMap[valueListId]?.loaded
      }
    );
    batch(() => {
      emptyValueList?.map((valueListItem) => valueListItem.listId && dispatch(loadValueListById(valueListItem.listId)));
    });
  }, [dispatch, tableConfiguration?.columns, valueListMap]);

  if (!tableConfiguration || !Array.isArray(rowData)) {
    return null;
  }
  const { columns } = tableConfiguration;
  const beforeAddons = addons?.before ?? [];
  const afterAddons = addons?.after ?? [];

  const actions = (info: RowInfo) => {
    const { id, original } = info.row;
    const isRemoved = original?.isRemoved;

    return <>
      {handleCopyRow ?
        <DropdownItem onClick={() => handleCopyRow(original, id)} ref={ref}>
          <i className='fa-regular fa-copy text-ThemeAccentMedium mr-2' />Copy row data
        </DropdownItem> : null
      }
      {handleEditRow ?
        <DropdownItem onClick={() => handleEditRow(original, id)} ref={ref}>
          <i className='fas fa-pencil-alt text-ThemeAccentMedium mr-2' />Edit data
        </DropdownItem> : null
      }
      {!isRemoved && handleDeleteRow ?
        <DropdownItem onClick={() => handleDeleteRow(original, id)}>
          <i className='fa fa-trash-alt text-ThemeDangerMedium mr-2' />Delete row
        </DropdownItem>
        : null
      }
      {
        isRemoved && handleClearDeleteRow ?
          <DropdownItem onClick={() => handleClearDeleteRow(original, id)}>
            <i className='fa fa-trash-alt mr-2' />Delete row
          </DropdownItem>
          : null
      }
    </>
  }

  const status = (info: RowInfo) => {
    const i = getControlIcon(info.row.original, editRowId);
    if (!i) {
      return '';
    }

    return (
      <div className='status-icon text-center'>
        <SimpleTooltip text={i.tooltip}>
          <i className={i.icon} />
        </SimpleTooltip>
      </div>
    );
  };

  const getValueFromOptions = (value: number | string | undefined, colCode: string, unit: string, decimal?: number) => {
    if (value === undefined) {
      return;
    }

    const c = columns.find(c => c.code === colCode);
    const hasOptions = c && c.options && c.options.length > 0;
    const hasList = c && c.listId;
    if (hasOptions || hasList) {
      const options = c.listId ? (valueListMap[c.listId]?.options ?? []) : (c.options ?? []);
      const op = options.find(o => o.code === value);
      return op ? op.name : value;
    }

    if (typeof decimal === 'number' && value !== '') {
      const formattedValue = parseFloat(String(value)).toLocaleString('en-GB', {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return `${formattedValue}${unit}`;
    }

    return `${value}${unit}`;
  }

  const getValueByColumn = (
    d: InputColumn[] | Record<string, string | number | undefined>,
    c: TableColumn
  ) => {
    if (Array.isArray(d)) {
      const column = d.find(col => col.code === c.code);
      return column ? column.value : '';
    }
    return d[c.code];
  }

  const getFormattedValueByColumn = (
    d: InputColumn[] | Record<string, string | number | undefined>,
    c: TableColumn,
    unitConfig: TableInputProps['unitConfig']
  ) => {
    const value = getValueByColumn(d, c);
    let numberScale: undefined | { plural: string };
    let unit = getDisplayUnit(c, unitConfig) ?? '';

    if (Array.isArray(d)) {
      const column = d.find(col => col.code === c.code);
      numberScale = column?.numberScale ? getUnitDescription(column.numberScale) : undefined;
      unit = column?.unit ?? unit;
    }
    const decimal = c.validation?.decimal;

    if (c?.calculation?.formula) {
      return formatCalculationNumber(value, decimal);
    }

    if (Array.isArray(value)) {
      return value.map(v => getValueFromOptions(v, c.code, '')).join(', ');
    }

    return `${getValueFromOptions(value, c.code, unit, decimal) ?? ''}${numberScale ? ` ${numberScale.plural}` : ''}`;
  }

  if (extractColumnCode) {
    const col = columns.find(c => c.code === extractColumnCode);
    if (col) {
      if (rowData[0]) {
        return <>
          {getFormattedValueByColumn(rowData[0].data, col, unitConfig)}
        </>;
      }
      return null;
    }
  }

  const tableColumns: TableInputColumn[] = [];

  if (beforeAddons && beforeAddons.length) {
    tableColumns.push({
      Header: () => <></>,
      id: 'beforeAddon',
      Cell: (info: RowInfo) => {
        // rowId means row index
        const rowIndex = info.row.id;
        const addon = beforeAddons.find(item => item.rowIndex === rowIndex);
        return addon ? addon.element : null;
      }
    })
  }

  if (!disabled) {
    tableColumns.push({
      Header: () => <></>,
      id: 'actions',
      cellProps: {
        className: 'table-input-view__actions',
      },
      Cell: (info: RowInfo) =>
        info.row.original.data.length ? (
          <UncontrolledDropdown direction='down'>
            <DropdownToggle tag='div'>
              <i className='fas fa-ellipsis' />
            </DropdownToggle>
            <DropdownMenu className='table-input-view__actions' container='body'>{actions(info)}</DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <div style={{ height: '20px' }} />
        ),
      accessor: (d: AccessorParams) => d.data,
    });

    tableColumns.push({
      Header: () => <></>,
      id: 'status',
      cellProps: {
        className: 'table-input-view__status',
      },
      Cell: (info: RowInfo) =>
        info.row.original.data.length ? (
          <div>
            {status(info)}
          </div>
        ) : (
          <div style={{ height: '20px' }} />
        ),
      accessor: (d: AccessorParams) => d.data,
    });
  }

  columns.forEach((c, i) => {
    tableColumns.push({
      Header: c.shortName ?? c.name,
      id: `${c.code}-${i}`,
      cellProps: {
        className: 'text-truncate dont_translate'
      },
      accessor: (row: AccessorParams) => {
        return getFormattedValueByColumn(row.data, c, unitConfig);
      }
    })
  });

  if (afterAddons && afterAddons.length) {
    tableColumns.push({
      Header: () => <></>,
      id: 'afterAddon',
      Cell: (info: RowInfo) => {
        // rowId means row index
        const rowIndex = Number(info.row.id);
        const afterAddon = afterAddons.find(item => item.rowIndex === rowIndex);
        return afterAddon ? afterAddon.element : null;
      }
    })
  }

  const getRowsData = () => {
    const rowHeaders = columns.map(col => col.shortName ?? col.name);
    const rowValues = rowData.map(row => {

      const lookupMap = new Map(row.data.map((valueColumn) => [valueColumn.code, valueColumn]))
      return columns.map(col => {
        const inputColumn = lookupMap.get(col.code);
        if (!inputColumn) {
          return undefined;
        }
        return Array.isArray(inputColumn.value) ?
          inputColumn.value.map(v => getValueFromOptions(v, col.code, '')).join(', ') :
          getValueFromOptions(inputColumn.value, col.code, '')
      })
    })
    return { rowHeaders, rowValues };
  }

  const downloadCSV = () => {
    const { rowHeaders, rowValues } = getRowsData();

    const data = {
      'fields': rowHeaders,
      'data': rowValues
    }

    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, 'table.csv');
  }
  const editClass = disabled ? 'view-mode' : 'edit-mode';

  return (
    <div className={classnames(props.className, 'table-input-view-container', editClass)}>
      <div className={classNames({ 'float-right': !isDownloadBtnBottom, 'download-btn--bottom': isDownloadBtnBottom })}>
        <SimpleTooltip text={`${rowData.length === 0 ? 'Add at least one row of data.' : ''}`}>
          {showTableDownload ?
            <Button color='link-secondary' className='p-0'
              onClick={downloadCSV}
              disabled={rowData.length === 0}
            >
              <i className='fal fa-file-csv' />
            </Button> : <></>}
        </SimpleTooltip>
      </div>
      <Table columns={tableColumns} rowData={rowData.length ? rowData : [emptyData]} editRowId={editRowId} hasBeforeAddon={!!beforeAddons?.length} hasAfterAddon={!!afterAddons?.length} />
    </div>
  );
};
export default TableInputView;
