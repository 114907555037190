import { TableColumn } from '@components/survey/form/input/table/InputInterface';
import { hasDataChanged, UnitTypeMapValue } from '@features/question-configuration';
import { useState } from 'react';
import { Button, FormGroup, Input, ModalBody, ModalFooter } from 'reactstrap';
import { useToggle } from '@hooks/useToggle';
import {
  DecimalInputProps,
  DecimalInputType,
  InitiativeUniversalTracker,
} from '@g17eco/types/initiativeUniversalTracker';
import { validateMinMax } from '@utils/universalTrackerValue';
import LoadingPlaceholder from '@components/LoaderContainer/LoadingPlaceholder';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { DecimalInput } from '@components/utr-decimal/DecimalInput';
import { DecimalTableInput } from '@components/utr-decimal/DecimalTableInput';
import { OverrideConfigAlert } from '@components/utr-decimal/OverrideConfigAlert';
import { OverrideUnitForm, UnitConfigType } from '../unit-override/OverrideUnitForm';
import {
  DECIMAL_MAX,
  DECIMAL_MIN,
  DecimalType,
  ErrorDecimalType,
  getInitialState,
  invalidInputMessage,
  isDecimalMultipleUpdate,
  isValidUpdateDecimal,
} from '@components/utr-decimal/utils';
import { isDefined } from '@utils/index';

interface MetricOverrideFormProps {
  isLoading: boolean;
  inputType: DecimalInputType;
  tableColumns: TableColumn[];
  selectedQuestions: BulkActionUtr[];
  questionsWithValidation: InitiativeUniversalTracker[];
  handleUpdate: (decimal: DecimalType, unitConfig?: UnitConfigType) => void;
  defaultDecimal: DecimalType;
  defaultUnitConfig: UnitConfigType;
  unitTypeMap: Map<string, UnitTypeMapValue>
}

const defaultState = { value: undefined };

export const MetricOverrideForm = ({
  isLoading,
  inputType,
  selectedQuestions,
  questionsWithValidation,
  tableColumns,
  handleUpdate,
  defaultDecimal,
  defaultUnitConfig,
  unitTypeMap
}: MetricOverrideFormProps) => {
  const [decimal, setDecimal] = useState<DecimalType>(defaultDecimal);
  const [unitConfig, setUnitConfig] = useState<UnitConfigType>(defaultUnitConfig);
  const [error, setError] = useState<ErrorDecimalType>(defaultState);
  const [isEnforced, toggleEnforced] = useToggle(Object.values(defaultDecimal).some((value) => isDefined(value)));

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError((prev) => ({
      ...prev,
      [name]: validateMinMax(value, DECIMAL_MIN, DECIMAL_MAX).errored ? invalidInputMessage : '',
    }));
    setDecimal((prev) => ({
      ...prev,
      [name]: value === '' ? undefined : parseInt(value),
    }));
  };

  const onToggleSwitch = () => {
    toggleEnforced();
    setDecimal(getInitialState({ inputType, tableColumns, valueValidation: undefined }));
    setError(defaultState);
  };

  const isMultipleUpdate = isDecimalMultipleUpdate({ selectedQuestions, questionsWithValidation });
  const isValidDecimal = isValidUpdateDecimal({ decimal, error, isEnforced });
  const hasAnythingChanged = hasDataChanged(defaultDecimal, decimal) || hasDataChanged(defaultUnitConfig, unitConfig);
  const isAllowedToUpdate = isValidDecimal && (hasAnythingChanged || isMultipleUpdate);

  const onClickUpdate = () => {
    if (!isAllowedToUpdate) {
      return;
    }
    if(!unitConfig || Object.values(unitConfig).length === 0) {
      return handleUpdate(decimal);
    }
    handleUpdate(decimal, unitConfig);
  };

  const inputProps = {
    decimal,
    error,
    disabled: !isEnforced,
    onChange,
  };

  const onSelectUnit = (unitType: string, unit: string | undefined) => {
    if (unit) {
      setUnitConfig((prev) => ({ ...prev, [unitType]: unit }));
    }
  };

  return (
    <>
      <ModalBody className='pt-3 overflow-scroll' style={{ maxHeight: '500px' }}>
        {isLoading ? <LoadingPlaceholder /> : null}
        <OverrideUnitForm unitConfig={unitConfig} unitTypeMap={unitTypeMap} onSelectUnit={onSelectUnit} />
        <div className='fw-bold mb-2'>Metric configuration</div>
        <OverrideConfigAlert
          showOverrideConfigAlert={isMultipleUpdate}
          questionCount={questionsWithValidation.length}
        />
        <FormGroup switch className='d-flex align-items-center' id='enforce-decimal'>
          <Input type='switch' className='mr-2' onChange={onToggleSwitch} checked={isEnforced} />
          <label htmlFor='enforce-decimal'>Enforce decimal place</label>
        </FormGroup>
        <DecimalForm inputType={inputType} tableColumns={tableColumns} inputProps={inputProps} />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          disabled={!isAllowedToUpdate || isLoading}
          onClick={onClickUpdate}
        >
          Update
        </Button>
      </ModalFooter>
    </>
  );
};

const DecimalForm = ({
  inputType,
  tableColumns,
  inputProps,
}: {
  inputType: DecimalInputType;
  tableColumns: TableColumn[];

  inputProps: DecimalInputProps;
}) => {
  switch (inputType) {
    case DecimalInputType.Table:
      return <DecimalTableInput {...inputProps} tableColumns={tableColumns} />;
    case DecimalInputType.SingleInput:
      return <DecimalInput {...inputProps} className='mt-3' />;
    default:
      return <div className='mt-3'>Not supported type</div>;
  }
};
