import {
  DashboardSurveyType,
  InsightDashboard,
  InsightDashboardFilters,
  InsightDashboardSettingKeys,
  InsightDashboardTitle,
  PrivacyFilter,
  SurveyFilter,
  TimeFrameType,
  UtrvFilter,
} from '@g17eco/types/insight-custom-dashboard';
import { SurveyType } from '@g17eco/types/survey';
import { getDateRange } from '@g17eco/molecules';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { TIME_RANGE_YEARLY } from '@utils/date';
import { SURVEY } from '@constants/terminology';

export const defaultTimeFrame = {
  type: TimeFrameType.TwelveMonths,
  ...getDateRange(TIME_RANGE_YEARLY),
};

export const DEFAULT_FILTERS: Required<InsightDashboard['filters']> = {
  utrv: UtrvFilter.Verified,
  survey: SurveyFilter.All,
  privacy: PrivacyFilter.All,
  timeFrame: defaultTimeFrame,
  surveyType: SurveyType.Default,
  period: DataPeriods.Monthly,
  initiativeInfo: {
    enabled: false,
  },
  sdgContribution: {
    enabled: false,
  },
  baselinesTargets: {
    enabled: true,
  },
  shareWithSubsidiaries: {
    enabled: true,
  },
};

export const getTimeFrameType = (period: number | string) => {
  switch (period) {
    case 'custom':
      return TimeFrameType.Custom;
    case 12:
      return TimeFrameType.TwelveMonths;
    case 6:
      return TimeFrameType.SixMonths;
    case 3:
      return TimeFrameType.ThreeMonths;
    case 1:
      return TimeFrameType.OneMonth;
    default:
      return TimeFrameType.AllTime;
  }
};

export const getTimeFramePeriod = (type: TimeFrameType | undefined) => {
  switch (type) {
    case TimeFrameType.Custom:
      return 'custom';
    case TimeFrameType.TwelveMonths:
      return 12;
    case TimeFrameType.SixMonths:
      return 6;
    case TimeFrameType.ThreeMonths:
      return 3;
    case TimeFrameType.OneMonth:
      return 1;
    default:
      return TimeFrameType.AllTime;
  }
};

export const surveyTypeOptions: { value: DashboardSurveyType; label: string }[] = [
  { value: SurveyType.Default, label: SURVEY.CAPITALIZED_PLURAL },
  { value: SurveyType.Aggregation, label: `Combined ${SURVEY.PLURAL}` },
];

export const surveyStatusOptions = [
  { value: SurveyFilter.All, label: `All ${SURVEY.PLURAL}` },
  { value: SurveyFilter.Completed, label: `Completed ${SURVEY.PLURAL} only` },
];

export const metricStatusOptions = [
  { value: UtrvFilter.Verified, label: 'All verified data' },
  { value: UtrvFilter.Assured, label: 'Assured data only' },
];

export const metricPrivacyOptions = [
  { value: PrivacyFilter.All, label: 'Public and private' },
  { value: PrivacyFilter.Public, label: 'Public only' },
];

export const isDashboardSettingHidden = (hideOptions: InsightDashboardSettingKeys[]) => {
  return [
    InsightDashboardTitle,
    InsightDashboardFilters.ShareWithSubsidiaries,
    InsightDashboardFilters.BaselinesTargets,
    InsightDashboardFilters.InitiativeInfo,
    InsightDashboardFilters.SdgContribution,
  ].every((key) => hideOptions.includes(key as InsightDashboardSettingKeys));
};
