import { Button, Spinner } from 'reactstrap';
import {
  BackgroundJob,
  ImportDataTask,
  JobStatus,
  SupportedTask,
  Task,
  TaskStatus,
  TaskType,
  ValidationError,
} from '../../../types/background-jobs';
import { DATE, formatDate } from '../../../utils/date';
import SimpleTooltip from '../../simple-tooltip';
import { ProgressLoader } from '../../../atoms/loader/progress-loader';
import { SURVEY } from '@constants/terminology';

export const isProcessingImportData = (job: BackgroundJob, latestTask: Task) => {
  const { type, status } = latestTask;
  if (type !== TaskType.ImportData) {
    return false;
  }
  if (status === TaskStatus.Processing) {
    return true
  }
  // Job is about to start and import task in pending, treat as processing
return job.status === JobStatus.WaitForRun && status === TaskStatus.Pending
};

const uploadingHint = (
  <span className='text-center'>
    Your request is currently being processed. Please be patient as depending on the file size being imported, this
    process could take upwards of 10 minutes.
    <br />
    <br />
    We will send you a notification once your data is ready to be imported
  </span>
);

interface ImportActionsProps {
  job: BackgroundJob;
  latestTask: SupportedTask;
  isLoading: boolean;
  runBulkImportJob: (jobId: string) => void;
  deleteBulkImportJob: (jobId: string) => void;
  handleShowIssues: (errors: ValidationError[]) => void;
}

export const ImportActions = ({
  job,
  latestTask,
  isLoading,
  runBulkImportJob,
  deleteBulkImportJob,
  handleShowIssues,
}: ImportActionsProps) => {
  if (job.deletedDate) {
    return (
      <div className='text-ThemeIconSecondary'>
        <div>{job.status === JobStatus.Error ? 'Errored' : 'Deleted'} at: {formatDate(job.deletedDate, DATE.DEFAULT_SPACES_WITH_TIME)}</div>
      </div>
    );
  }

  if (job.completedDate) {
    return (
      <div className='text-ThemeIconSecondary'>
        Completed at: {formatDate(job.completedDate, DATE.DEFAULT_SPACES_WITH_TIME)}
      </div>
    );
  }

  const { type, status } = latestTask;

  switch (true) {
    case type === TaskType.UploadFile || type === TaskType.ProcessFile: {
      return (
        <div className='d-flex gap-3 text-ThemeIconSecondary'>
          <div className='text-md'>Uploading file</div>
          <ProgressLoader />
          <SimpleTooltip text={uploadingHint}>
            <i className='fa-solid fa-circle-info text-lg'></i>
          </SimpleTooltip>
        </div>
      );
    }
    case isProcessingImportData(job, latestTask): {
      return (
        <div className='loading-spinner'>
          <Spinner color='primary'>Loading...</Spinner>
        </div>
      );
    }
    case type === TaskType.ImportData && status === TaskStatus.Pending: {
      const { data } = latestTask;
      const hasErrors = data.errors && data.errors.length > 0;

      return (
        <div className='import-actions d-flex gap-3'>
          <SimpleTooltip text={`You file has been uploaded to the system. Click here to import ${SURVEY.SINGULAR} data.`}>
            <Button type='button' color='primary' disabled={isLoading} onClick={() => runBulkImportJob(job._id)}>
              Import data
            </Button>
          </SimpleTooltip>
          <SimpleTooltip
            text={
              hasErrors ? 'You may encounter the following issues when importing this file.' : 'No issues have found.'
            }
          >
            <Button type='button' color='secondary' disabled={!hasErrors} onClick={() => handleShowIssues(data.errors)}>
              Review issues
            </Button>
          </SimpleTooltip>
          <SimpleTooltip text='Cancel import and delete file.'>
            <Button type='button' color='danger' outline className='px-2' onClick={() => deleteBulkImportJob(job._id)}>
              <i className='fa-solid fa-trash'></i>
            </Button>
          </SimpleTooltip>
        </div>
      );
    }
    default:
      return null;
  }
};
