import React from 'react';
import RsCarousel, { RsCarouselProps, RsCarouselState } from './rs-carousel';
import { connect } from 'react-redux';
import { isUserManager } from '../../selectors/user';
import SimpleTooltip from '../simple-tooltip';
import { DownloadButton } from '../button/DownloadButton';
import { openUpgradeModal } from '../../actions/upgradeModal';
import G17Client from '../../services/G17Client';
import {
  loadSurveyList,
  selectList
} from '../../slice/initiativeSurveyListSlice';
import { getAnalytics } from '../../services/analytics/AnalyticsService';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { getDefaultDownloadScope, getMultiScopeReport, SupportedReportCodes } from '../report-output/outputs';
import { RootState } from '../../reducers';
import { RatingAgencyRating } from '../../types/initiative';
import { getDownloadMultiScope } from '../downloads';
import { VisibilityStatus } from '../../types/download';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';

interface StandardsCarouselState extends RsCarouselState {
  selectedItem?: {
    _id: string;
  };
}

interface StandardsCarouselProps extends RsCarouselProps {
  canAccessDownloads: boolean;
  openUpgradeModal: (tags: string[]) => void;
}

class StandardsCarousel extends RsCarousel<StandardsCarouselProps> {
  state: StandardsCarouselState = {
    defaultWrapperClass: 'standards-carousel',
    headingText: 'Standards & Frameworks',
    linkTooltip: 'Visit Standard',
    loaded: false,
    selectedItem: undefined,
  };

  handleDropdownSelect = async (item: { _id: string }) => {
    this.setState({ selectedItem: item, loaded: false });

    try {
      const items = await G17Client.getSurveyRatings({
        initiativeId: this.props.initiativeId,
        surveyId: item._id
      });

      if (this._isMounted) {
        this.setState({
          loaded: true,
          items: Array.isArray(items) ? items : []
        });
      }
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setLoaded();
    }
  }

  handleError = (e: any) => {
    if (this._isMounted) {
      this.setState({ errorMessage: e.message, hasError: true });
    }
    console.error(e);
  }

  setLoaded = () => {
    if (this._isMounted) {
      this.setState({ loaded: true });
    }
  }

  async componentDidMount() {
    if (!this.props.standards) {
      return;
    }
    this._isMounted = true;
    const { loaded, initiativeId, standards, data } = this.props.standards;
    if (loaded && this.props.initiativeId === initiativeId) {
      this.setState({
        loaded: true,
        selectedItem: data[0],
        items: Array.isArray(standards) ? standards : []
      });
    } else {
      this.props.loadSurveyList?.(this.props.initiativeId);
    }

    this.updateSelectedItem(data);
  }

  componentDidUpdate(prevProps: RsCarouselProps) {
    if (!this.props.standards) {
      return;
    }
    const { loaded, standards, data } = this.props.standards;
    if (!prevProps.standards?.loaded && loaded) {
      this.setState({
        loaded: true,
        selectedItem: data[0],
        items: Array.isArray(standards) ? standards : []
      });
    }
    this.updateSelectedItem(data);
  }

  updateSelectedItem(data: { _id: string }[]) {
    const { surveyId } = this.props;
    const { selectedItem } = this.state;
    if (selectedItem && surveyId && selectedItem._id !== surveyId) {
      const item = data.find((d) => d._id === surveyId);
      if (item) {
        this.handleDropdownSelect(item)
      }
    }
  }

  renderEditButton() {
    const surveyListItem = this.state.selectedItem;
    if (!this.props.isManager || !surveyListItem) {
      return <></>
    }

    const downloadReport = (code: SupportedReportCodes) => {
      if (!this.props.canAccessDownloads) {
        this.props.openUpgradeModal(['features', 'data_download']);
        return <></>;
      }
      const downloadScope = getDefaultDownloadScope([code], {
        visibility: VisibilityStatus.ExcludeValuesOnly,
      });
      getMultiScopeReport(surveyListItem._id, code, downloadScope).catch(this.handleError);
    }

    return (
      <>
        <UncontrolledDropdown style={{ display: 'inline-block' }}>
          <DropdownToggle color='transparent' outline className='px-2'><i className='fas fa-bars' /></DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.onClickDownload()}>
              <i className='fa fa-file-archive mr-2' />Download all data and evidence
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => downloadReport('tcfd')}>
              <i className='far fa-file-word mr-2' />TCFD report
            </DropdownItem>
            <DropdownItem onClick={() => downloadReport('ungc')}>
              <i className='far fa-file-word mr-2' />UNGC report
            </DropdownItem>
            <DropdownItem onClick={() => downloadReport('sdg')}>
              <i className='far fa-file-word mr-2' />SDG report
            </DropdownItem>
            <DropdownItem onClick={() => downloadReport('gri')}>
              <i className='far fa-file-word mr-2' />GRI report
            </DropdownItem>
            <DropdownItem onClick={() => downloadReport('vigeo_eiris')}>
              <i className='far fa-file-word mr-2' />Vigeo EIRIS report
            </DropdownItem>
            <DropdownItem onClick={() => downloadReport('sam_csa')}>
              <i className='far fa-file-word mr-2' />DJSI report
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    )
  }

  onClickDownload = (item?: RatingAgencyRating) => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return;
    }

    const canDownload = !!this.props.canAccessDownloads;
    if (!canDownload) {
      this.props.openUpgradeModal(['features', 'data_download']);
      return;
    }

    const initiativeId = this.props.initiativeId;

    return G17Client.downloadSurveySimple({
      surveyId: selectedItem._id,
      type: 'csv',
      downloadScope: {
        statuses: undefined,
        scope: getDownloadMultiScope(item?.code),
      }
    })
      .then(() => {
        const analytics = getAnalytics();
        return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
          initiativeId,
          surveyId: selectedItem._id,
          scopeValue: item ? item.code : undefined,
          source: 'standards_carousel'
        });
      })
      .catch(console.error)
  };

  renderFirstButton(item: RatingAgencyRating) {
    return <SimpleTooltip text='Download Data'>
      <DownloadButton
        className={'btn-icon'} outline={true} color={'secondary'}
        onClick={() => this.onClickDownload(item)}>
        <i className='fa fas fa-file-download' />
      </DownloadButton>
    </SimpleTooltip>
  }
}

const mapStateToProps = (state: RootState) => ({
  standards: selectList(state),
  isManager: isUserManager(state),
  canAccessDownloads: FeaturePermissions.canAccessDownloads(state)
});

const mapDispatchToProps = ({
  openUpgradeModal,
  loadSurveyList,
});

// @TODO this weird merge of two different components cause types to missmatch
// @ts-expect-error Should split into separate components and avoid inheritance
export default connect(mapStateToProps, mapDispatchToProps)(StandardsCarousel);
