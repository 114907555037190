import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { ButtonGradient } from '../../apps/common/components/ButtonGradient/ButtonGradient';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { useToggle } from '@hooks/useToggle';
import { AssistantOffcanvas } from './AssistantOffcanvas';
import { FeatureStability } from '@g17eco/molecules';
import { useConnectionContext } from './connections/ConnectionContext';
import { useEffect, useState } from 'react';

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'valueValidation'>;
  utrv: SurveyModelMinimalUtrv;
}
export const AssistantBtn = ({ utr, utrv }: Props) => {
  const [open, toggle] = useToggle(false);

  const { removeConnection } = useConnectionContext();
  const handleToggle = () => {
    removeConnection();
    toggle();
  };

  return (
    <>
      <ButtonGradient outline onClick={handleToggle} key={`assistant-btn-${open ? 'open' : 'close'}`}>
        <i className='fal fa-sparkles mr-2' />
        Assistant
        <FeatureStability stability={'beta'} />
      </ButtonGradient>
      <AssistantOffcanvas
        utrv={utrv}
        utr={utr}
        open={open}
        toggle={handleToggle}
      />
    </>
  );
};
