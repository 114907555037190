import IconButton from '@components/button/IconButton';
import { PACK, QUESTION } from '@constants/terminology';
import { InputRow, TableDraggableColumn, TableDraggableRows } from '@g17eco/molecules';

const HEADINGS: Record<string, { name: string; definition: string }> = {
  module: {
    name: 'Module',
    definition: `Standard or custom ${PACK.SINGULAR} where the ${QUESTION.SINGULAR} was answered`,
  },
  code: {
    name: 'Code',
    definition: `Unique ${QUESTION.SINGULAR} code`,
  },
  mapping: {
    name: 'Mapping',
    definition: `Alternative ${PACK.SINGULAR} mapping for ${QUESTION.SINGULAR}`,
  },
  type: {
    name: 'Type',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} type i.e. percentage, number, date, value list...`,
  },
  header: {
    name: 'Header',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} header, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  instructions: {
    name: 'Instructions',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} instructions, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  inputHeader: {
    name: 'Input header',
    definition: `Input header, as seen on ${QUESTION.SINGULAR} answer page (if applicable)`,
  },
  unit: {
    name: 'Unit',
    definition: 'Unit of value (may include conversions based on override preferences)',
  },
  numberScale: {
    name: 'Number scale',
    definition: 'Number scale of value (may include conversions based on overrides)',
  },
  status: {
    name: 'Status',
    definition: `Current status of the ${QUESTION.SINGULAR} and/or input`,
  },
  value: {
    name: 'Value',
    definition: `Value provided for the ${QUESTION.SINGULAR} and/or input`,
  },
  furtherExplanation: {
    name: 'Further explanation / notes',
    definition: `Latest notes provided with the ${QUESTION.SINGULAR} submission`,
  },
  tags: {
    name: 'Tags',
    definition: `Tags assigned to the ${QUESTION.SINGULAR}`,
  },
  provenanceEvidence: {
    name: 'Provenance and evidence',
    definition: 'Link to access provenance and evidence (Assessable to users only)',
  },
};

export const Headings = () => {
  const columns: TableDraggableColumn[] = [
    {
      header: 'Heading',
      className: '',
    },
    {
      header: 'Definition',
      className: '',
    },
    {
      header: '',
      className: '',
    },
  ];

  const rows: InputRow[] = Object.keys(HEADINGS).map((key) => {
    const { name, definition } = HEADINGS[key];
    return {
      id: key,
      cols: [
        <div key={'name'}>{name}</div>,
        <div key={'definition'}>{definition}</div>,
        <IconButton
          key={'delete-btn'}
          icon='fal fa-trash text-danger'
          color='link'
          size='sm'
          disabled
          onClick={() => {}}
        />,
      ],
    };
  });
  return <TableDraggableRows columns={columns} data={rows} handleUpdate={() => ({})} disabled />;
};
