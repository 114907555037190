import { createFormData } from '@utils/files';
import { InitiativeMin } from '../types/initiative';
import { InsightDashboard } from '../types/insight-custom-dashboard';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { ByIdParam, CreateParam, UpdateParam, UploadFileParam, UploadedDashboardFile } from './insight-dashboards';
import { DashboardItemFilters, HistoricalUtrs, HistoricalUtrsQueryByCodeParams } from './insights';
import { formDataConfig } from '@services/G17Client';

type ByIdParamWithQuery = ByIdParam & { queryParams?: DashboardItemFilters };

const getUrl = ({ initiativeId, dashboardId = '' }: { initiativeId: string; dashboardId?: string }) =>
  `/portfolios/${initiativeId}/insight-dashboards/${dashboardId}`;

const TAGS = ['portfolio-insight-dashboards'];

export const portfolioInsightDashboardsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [...TAGS, 'utrs-history'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInsightDashboardsByPortfolio: builder.query<InsightDashboard[], InitiativeMin['_id']>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl({ initiativeId }),
          method: 'get',
        }),
        providesTags: TAGS,
      }),
      getInsightDashboardByPortfolioId: builder.query<InsightDashboard, ByIdParamWithQuery>({
        transformResponse,
        query: ({ initiativeId, dashboardId, queryParams }) => ({
          url: getUrl({ initiativeId, dashboardId }),
          method: 'get',
          params: queryParams,
        }),
        providesTags: TAGS,
      }),
      createPortfolioInsightDashboard: builder.mutation<InsightDashboard, CreateParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: getUrl({ initiativeId }),
          method: 'post',
          data,
        }),
      }),
      updatePortfolioInsightDashboard: builder.mutation<InsightDashboard, UpdateParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ _id: dashboardId, initiativeId, ...rest }) => ({
          url: getUrl({ initiativeId, dashboardId }),
          method: 'put',
          data: rest,
        }),
      }),
      deletePortfolioInsightDashboard: builder.mutation<InsightDashboard, ByIdParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId, dashboardId }) => {
          return {
            url: getUrl({ initiativeId, dashboardId }),
            method: 'delete',
          };
        },
      }),
      duplicatePortfolioInsightDashboard: builder.mutation<InsightDashboard, ByIdParam>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ dashboardId, initiativeId }) => ({
          url: `${getUrl({ initiativeId, dashboardId })}/duplicate`,
          method: 'post',
        }),
      }),
      getPortfolioHistoricalUtrsByCodes: builder.query<HistoricalUtrs[], HistoricalUtrsQueryByCodeParams>({
        transformResponse,
        query: ({ initiativeId, utrCodes, queryParams }) => {
          return {
            url: `${getUrl({ initiativeId })}/utrs-history`,
            method: 'post',
            data: { utrCodes, filters: queryParams },
          };
        },
        providesTags: ['utrs-history'],
      }),
      uploadPortfolioInsightDashboardMediaFiles: builder.mutation<UploadedDashboardFile[], UploadFileParam>({
        transformResponse,
        query: ({ files, initiativeId, dashboardId }) => ({
          url: `${getUrl({ initiativeId, dashboardId })}/upload`,
          method: 'post',
          data: createFormData(files),
          ...formDataConfig,
        }),
      }),
    }),
  });

export const {
  useCreatePortfolioInsightDashboardMutation,
  useGetInsightDashboardByPortfolioIdQuery,
  useUpdatePortfolioInsightDashboardMutation,
  useDeletePortfolioInsightDashboardMutation,
  useGetInsightDashboardsByPortfolioQuery,
  useDuplicatePortfolioInsightDashboardMutation,
  useLazyGetPortfolioHistoricalUtrsByCodesQuery,
  useUploadPortfolioInsightDashboardMediaFilesMutation,
} = portfolioInsightDashboardsApi;
