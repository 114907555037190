import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {
  GridDashboardItemBase,
  GridDashboardTempItem,
  InsightDashboardItemType,
  InsightDashboardTempItemType,
} from '../../../types/insight-custom-dashboard';
import { AddWidgetBtn } from './AddWidgetBtn';
import { FloatingToolbar } from '../../floating-toolbar';
import { useToggle } from '../../../hooks/useToggle';
import { DeleteConfirmation } from '../../confirm-modal/DeleteConfirmation';
import { useContext, useState } from 'react';
import { CustomDashboardContext } from '@routes/custom-dashboard/context/CustomDashboardWrapper';
import { generateErrorToast, generateToast } from '@components/toasts';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';

interface Props {
  openAddingModal: (item: Pick<GridDashboardItemBase | GridDashboardTempItem, 'type'>) => void;
  saveDashboardChanges: () => void;
  handleDelete: () => void;
  handleCancel: () => void;
  handleExport: () => void;
  toggleShowHideBreakpoints: () => void;
}

export const CustomDashboardToolbar = (props: Props) => {
  const { saveDashboardChanges, openAddingModal, handleDelete, handleCancel, handleExport, toggleShowHideBreakpoints } =
    props;
  const { duplicateDashboard } = useContext(CustomDashboardContext);
  const [isDuplicating, setDuplicating] = useState(false);
  const isStaffUser = useAppSelector(isStaff);

  const [isConfirmingDelete, toggleDelete] = useToggle(false);

  const addItemButtons = (
    [
      InsightDashboardTempItemType.TempChart,
      InsightDashboardItemType.Table,
      InsightDashboardItemType.Media,
      InsightDashboardItemType.Headline,
    ] as const
  ).map((type) => <AddWidgetBtn key={type} type={type} handleClick={() => openAddingModal({ type })} />);

  const handleDuplicate = () => {
    setDuplicating(true);
    duplicateDashboard()
      .then(() => {
        generateToast({
          color: 'success',
          message: 'Dashboard has been successfully duplicated',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      })
      .finally(() => {
        setDuplicating(false);
      });
  };

  const dashboardMenuDropdown = (
    <UncontrolledDropdown direction='up'>
      <DropdownToggle color='secondary'>
        <i className={'fal fa-bars mr-2'}></i>
        <span>Dashboard menu</span>
      </DropdownToggle>
      <DropdownMenu color='secondary'>
        <DropdownItem onClick={handleDuplicate} disabled={isDuplicating}>
          Duplicate dashboard
        </DropdownItem>
        <DropdownItem toggle={false} onClick={toggleDelete}>
          Delete dashboard
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const [isOpenExportMenu, toggleExportMenu] = useToggle(false);
  const exportMenu = isStaffUser ? (
    <Dropdown isOpen={isOpenExportMenu} toggle={toggleExportMenu} direction='up'>
      <DropdownToggle color='secondary'>
        <i className={'fal fa-bars mr-2'}></i>
        <span>Export dashboard</span>
      </DropdownToggle>
      <DropdownMenu color='secondary'>
        <DropdownItem onClick={handleExport}>
          Export to PDF
        </DropdownItem>
        <DropdownItem onClick={toggleShowHideBreakpoints}>
          Show breakpoints / hide breakpoints
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  ) : null;

  const saveButton = (
    <Button color='primary' onClick={() => saveDashboardChanges()}>
      Save
    </Button>
  );

  return (
    <>
      <FloatingToolbar
        handleClose={handleCancel}
        isOpen
        items={[...addItemButtons, exportMenu, dashboardMenuDropdown, saveButton]}
      />
      {isConfirmingDelete ? (
        <DeleteConfirmation title='Delete dashboard' toggle={toggleDelete} handleConfirm={handleDelete} />
      ) : null}
    </>
  );
};
