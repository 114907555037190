import GeneratingReportImage from '@g17eco/images/ai-reports/workingonit-AI.gif';

export const GeneratingReportPlaceholder = () => {
  return (
    <div className='d-flex flex-column align-items-center w-100 mt-3'>
      <div className='text-center text-ThemeTextMedium'>
        Your report is being generated and will appear in the ‘Latest reports’ section below in a couple of minutes.
        <br /> You can leave this page and return later. <br />
        We we send you a notification and an email when the report is ready.
      </div>
      <img src={GeneratingReportImage} className='my-2 rounded-circle' />
    </div>
  );
};
