import { UtrValueType } from '@g17eco/types/universalTracker';
import { QUESTION } from '@constants/terminology';
import { SingleInputAnswer } from './partials/SingleInputAnswer';
import { MultiInputAnswer } from './partials/MultiInputAnswer';
import { useGetAIUtrvAssistantQuery } from '@api/ai';
import { QueryError } from '@components/query/QueryError';
import { DashboardDivider, SectionLoader } from '@g17eco/atoms';
import { AnswerProps, PreviousUtrv } from './types';
import { isSupportedPrediction } from './utils';

export interface AnswerDataProps extends AnswerProps {
  predictedData?: string | number | { [key: string]: string | number | string[] };
  targetValue?: number;
  previousUtrvs: PreviousUtrv[];
}

const renderAnswerData = (props: AnswerDataProps) => {
  const { utr, predictedData } = props;

  switch (utr.valueType) {
    case UtrValueType.Text:
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      const predictedAnswer = predictedData && typeof predictedData !== 'object' ? predictedData : '';
      return <SingleInputAnswer predictedAnswer={predictedAnswer} {...props} />;
    }
    case UtrValueType.TextValueList:
    case UtrValueType.NumericValueList:
    case UtrValueType.Table: {
      const predictedAnswers = predictedData && typeof predictedData === 'object' ? predictedData : {};
      const converted = Object.entries(predictedAnswers).reduce<{ [key: string]: number | string }>(
        (acc, [key, value]) => {
          if (Array.isArray(value)) {
            acc[key] = value.join(', ');
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {}
      );

      return <MultiInputAnswer predictedAnswers={converted} {...props} />;
    }
    default: {
      return null;
    }
  }
};

export const Answer = (props: Pick<AnswerProps, 'utr' | 'utrv'>) => {
  const { utr, utrv } = props;
  const isSupported = isSupportedPrediction(utr);
  const { data, isFetching, isError, error } = useGetAIUtrvAssistantQuery(
    {
      initiativeId: utrv.initiativeId,
      utrvId: utrv._id,
    },
    { skip: !isSupported }
  );

  const { questionExplanation = '', previousUtrvs = [], targetValue, predictedAnswer } = data || {};
  const hasPreviousUtrvs = Boolean(previousUtrvs.length);

  return (
    <>
      {isError ? <QueryError error={error} type={'danger'} /> : null}
      <h6 className='my-0 text-ThemeHeadingDark'>{QUESTION.CAPITALIZED_SINGULAR} Explanation:</h6>
      {isFetching ? <SectionLoader /> : <p className='text-ThemeTextMedium'>{questionExplanation}</p>}
      <DashboardDivider className='' />
      {isSupported ? (
        renderAnswerData({
          utr,
          utrv,
          predictedData: predictedAnswer,
          hasPreviousUtrvs,
          isFetching,
          targetValue,
          previousUtrvs,
        })
      ) : (
        <p className='text-ThemeTextDark'>This {QUESTION.SINGULAR} is not yet supported</p>
      )}
    </>
  );
};
