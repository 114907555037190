import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadInitiativeQuestions } from '../../../actions/blueprints';
import UniversalTracker from '../../../model/UniversalTracker';
import { getUsedStandardAndFrameworkOptions } from '../../../constants/standards-frameworks';
import { filterMetricGroups, filterStandardAndFramework } from '../../../components/survey/utils/filters';
import {
  QuestionData,
  QuestionFilters,
  createQuestionOptions,
  getResetChildren,
} from '../../../routes/custom-dashboard/utils';
import { getGroup, standards } from '@g17eco/core';
import { UniversalTrackerBlueprintMin, UniversalTrackerPlain, UtrValueType } from '../../../types/universalTracker';
import { ActionMeta, createFilter } from 'react-select';
import { MetricGroup } from '../../../types/metricGroup';
import { DEFAULT_GROUP_COLOR } from '@components/form/ColourPicker';
import { naturalSort } from '@utils/index';
import { UsedScopes } from '@api/organization';
import {
  MetaAction,
  getQuestionOptionLabel,
  SIMPLE_TYPES,
} from '@features/custom-dashboard/question-selecting-filter/utils';
import { useGetValueListByIdQuery } from '@api/value-list';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ValueListPlain } from '@g17eco/types/valueList';
import { canAddTarget } from '@utils/universalTracker';
import { Option } from '@g17eco/molecules';

interface UseQuestionFiltersProps {
  initiativeId: string;
  questionData?: QuestionData;
  blueprintQuestions: UniversalTrackerBlueprintMin[];
  usedPacks: UsedScopes;
  metricGroups: MetricGroup[];
  validatingFunc?: (question: UniversalTrackerBlueprintMin) => boolean;
}

const getValueListOptions = (valueLists: ValueListPlain) => {
  const options = valueLists.options.map((v) => ({
    value: v.code,
    label: v.name,
    searchString: `${v.name} ${v.code}`,
    isDisabled: false,
  }));
  return [{ value: 'total', label: 'Total', searchString: 'Total', isDisabled: false }, ...options];
};

const getTableColumnOptions = (utrPlain: UniversalTrackerBlueprintMin) => {
  return (
    utrPlain.valueValidation?.table?.columns.map((c) => {
      const isAllowed = SIMPLE_TYPES.includes(c.type);
      return {
        value: c.code,
        label: getQuestionOptionLabel({ label: c.name, isAllowed }),
        searchString: `${c.name} ${c.code}`,
        isDisabled: !isAllowed,
      };
    }) ?? []
  );
};

export const useQuestionFilters = ({
  initiativeId,
  questionData,
  blueprintQuestions,
  metricGroups,
  usedPacks,
  validatingFunc = canAddTarget,
}: UseQuestionFiltersProps) => {
  const [filters, setFilters] = useState<QuestionFilters>(() => {
    if (!questionData) {
      return {};
    }
    const { valueListCode, groupCode, subGroupCode, code } = questionData;
    return {
      pack: groupCode,
      subPack: subGroupCode,
      question: code,
      input: valueListCode,
    };
  });

  const [selectedQuestionCode, setSelectedQuestionCode] = useState<string>(questionData?.code || '');
  const selectedQuestion = blueprintQuestions.find((q) => q.code === selectedQuestionCode);
  const isSupportedType = selectedQuestion && validatingFunc(selectedQuestion);
  const selectedValueListId = isSupportedType ? selectedQuestion.valueValidation?.valueList?.listId : undefined;

  const { data: valueLists } = useGetValueListByIdQuery(selectedValueListId ?? skipToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    dispatch(loadInitiativeQuestions(initiativeId));
  }, [dispatch, initiativeId]);

  const packOptions: Option[] = useMemo(() => {
    const customGroupOptions = metricGroups
      .map((group) => {
        return {
          label: (
            <div className='d-flex align-items-center'>
              {group.groupData?.icon ? (
                <img src={group.groupData.icon} alt={group.groupName} width={26} className='mr-2' />
              ) : (
                <i
                  className='fa fa-circle mr-2'
                  style={{ color: group.groupData?.colour ?? DEFAULT_GROUP_COLOR, fontSize: '26px' }}
                ></i>
              )}
              <span>CM: {group.groupName}</span>
            </div>
          ),
          searchString: group.groupName,
          value: group._id,
        };
      })
      .sort((a, b) => naturalSort(a.searchString, b.searchString));
    return [...customGroupOptions, ...getUsedStandardAndFrameworkOptions(usedPacks)];
  }, [metricGroups, usedPacks]);

  const subPacks: Option[] = useMemo(() => {
    const selectedPack = filters.pack;
    if (!selectedPack) {
      return [];
    }
    const subGroups = getGroup('standards-and-frameworks', selectedPack)?.subgroups ?? [];
    const usedPackType = standards[selectedPack] ? 'standards' : 'frameworks';
    const usedStandardSubGroups = subGroups.filter((group) =>
      usedPacks[usedPackType][selectedPack]?.includes(group.code)
    );
    return usedStandardSubGroups.map((group) => ({
      label: group.name,
      searchString: group.name,
      value: group.code,
    }));
  }, [filters.pack, usedPacks]);

  const filteredQuestions: Option[] = useMemo(() => {
    if (!blueprintQuestions || !filters.pack) {
      return [];
    }
    const scope = filters.subPack || filters.pack;
    if (!getGroup('standards-and-frameworks', filters.pack)) {
      return createQuestionOptions(
        blueprintQuestions.filter((question) =>
          filterMetricGroups(
            { universalTracker: new UniversalTracker(question as UniversalTrackerPlain) },
            scope,
            metricGroups
          )
        ),
        validatingFunc
      );
    }
    return createQuestionOptions(
      blueprintQuestions.filter((question) =>
        filterStandardAndFramework({ universalTracker: new UniversalTracker(question as UniversalTrackerPlain) }, scope)
      ),
      validatingFunc
    );
  }, [blueprintQuestions, filters.pack, filters.subPack, metricGroups, validatingFunc]);

  const questionInputs: Option[] = useMemo(() => {
    const currentQuestion = filteredQuestions.find((question) => question.value === selectedQuestionCode);
    const utrPlain = blueprintQuestions?.find((question) => question.code === currentQuestion?.value);

    if (!utrPlain) {
      return [];
    }

    if (utrPlain.valueType === UtrValueType.NumericValueList && valueLists) {
      return getValueListOptions(valueLists);
    }

    return getTableColumnOptions(utrPlain);
  }, [blueprintQuestions, filteredQuestions, selectedQuestionCode, valueLists]);

  const filterOption = createFilter<Option | null>({
    stringify: ({ data }) => data ? `${data.searchString ?? data.label}` : '',
  });

  const handleChangeFilters = (key: keyof QuestionFilters, actionMeta: ActionMeta<Option | null>, option?: string) => {
    const reset = getResetChildren(key);
    switch (actionMeta.action) {
      case MetaAction.Select:
        setFilters({
          ...filters,
          ...reset,
          [key]: option,
        });
        break;
      case MetaAction.Clear:
        setFilters({
          ...filters,
          ...reset,
          [key]: '',
        });
        break;
      default:
        break;
    }
  };

  return {
    packOptions,
    subPacks,
    filteredQuestions,
    questionInputs,
    filters,
    selectedQuestionCode,
    filterOption,
    handleChangeFilters,
    setSelectedQuestionCode,
  };
};
