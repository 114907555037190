import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { StatsRow } from '../utils/types';
import SimpleTooltip from '../../simple-tooltip';
import G17EcoLogo from '../../../images/g17eco-icon.svg';
import { DATE, formatDate } from '../../../utils/date';
import { getProductType, permissionGroupOptions } from '../../../services/SubscriptionService';
import { capitalise } from '../../../utils';

const getPermissionGroup = (permissionGroup: string) => {
  const option = permissionGroupOptions.find(o => o.code === permissionGroup);
  return option?.label;
}

interface CompanyDetailsModalProps {
  company: StatsRow | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

export const CompanyDetailsModal = (props: CompanyDetailsModalProps) => {
  const { company, isOpen, handleClose } = props;

  if (!company) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose} backdrop='static' className='manual-banks'>
      <ModalHeader toggle={handleClose}>{company?.name}</ModalHeader>
      <ModalBody>
        <div className='d-flex flex-wrap text-sm'>
          {company.owners.map((owner) => {
            const ownerName = `${owner.firstName?.charAt(0) ?? ''} ${owner.surname ?? ''}`;
            return (
              <div className='w-100 mb-3' key={owner._id}>
                <Row>
                  <Col className='col-4 text-ThemeTextMedium text-uppercase'>Owner name:</Col>
                  <Col className='col-8'>
                    {capitalise(ownerName)}{' '}
                    {owner.isStaff ? (
                      <SimpleTooltip text={'Support user'}>
                        <img src={G17EcoLogo} alt='staff-icon' height={12} className='ml-2' />
                      </SimpleTooltip>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='col-4 text-ThemeTextMedium text-uppercase'>Owner email:</Col>
                  <Col className='col-8 dont_translate'>{owner.email}</Col>
                </Row>
              </div>
            );
          })}
          {company.owners.length > 0 ? <hr className='divider m-0 mb-3' /> : null}
          <div className='w-100'>
            <Row>
              <Col className='col-4 text-ThemeTextMedium text-uppercase'>Product:</Col>
              <Col className='col-8'>{getProductType(company.productCode)}</Col>
            </Row>

            <Row className='mt-3'>
              <Col className='col-4 text-ThemeTextMedium text-uppercase'>Tier</Col>
              <Col className='col-8'>{getPermissionGroup(company.permissionGroup)}</Col>
            </Row>
            <Row className='mt-3'>
              <Col className='col-4 text-ThemeTextMedium text-uppercase'>Created</Col>
              <Col className='col-8'>{formatDate(company.created, DATE.DEFAULT_SPACES)}</Col>
            </Row>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
