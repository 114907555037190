import { SerializedError } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { SelectedTagIdsType } from '../../api/metric-groups';
import { Action } from '../../constants/action';
import { Tag } from '../../types/metricGroup';
import { BasicAlert } from '../alert/BasicAlert';
import { CheckboxState } from '../common/Checkbox';
import Loader from '../loader';
import { TagList } from './TagList';
import { getTagStatus } from './utils';

interface AddTagListProps {
  tags: Tag[];
  isLoading: boolean;
  tagListError?: { message: string; name: string } | SerializedError;
  selectedUtrIds: string[];
  openManageTags: () => void;
  handleAddUtrsToTags: (ids: SelectedTagIdsType) => void;
}

const defaultSelectedTagIds = { add: [], remove: [] };

export const AddTagList = (props: AddTagListProps) => {
  const { tags, isLoading, tagListError, selectedUtrIds, openManageTags, handleAddUtrsToTags } = props;

  const [selectedTagIds, setSelectedTagIds] = useState<SelectedTagIdsType>(defaultSelectedTagIds);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!tags.length || !selectedUtrIds.length) {
      return;
    }
    const initialSelectedTagIds = tags
      .filter((tag) => getTagStatus(tag, selectedUtrIds) === CheckboxState.Checked)
      .map((tag) => tag._id);

    setSelectedTagIds((state) => ({ ...state, add: initialSelectedTagIds }));
  }, [tags, selectedUtrIds]);

  const filteredCustomTags = useMemo(() => {
    if (!search) {
      return tags;
    }
    return tags.filter((tag) => tag.groupName.toLowerCase().includes(search.toLowerCase()));
  }, [search, tags]);

  const onSearch = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  const handleSelect = (selectedTag: Tag) => {
    const selectedTagId = selectedTag._id;
    const action = !selectedTagIds.add.includes(selectedTagId) ? Action.Add : Action.Remove;
    setSelectedTagIds(getSelectedTagIds(action, selectedTagId));
  };

  const getSelectedTagIds = (action: Action, selectedTagId: string) => {
    switch (action) {
      case Action.Remove:
        return {
          add: selectedTagIds.add.filter((tagId) => tagId !== selectedTagId),
          remove: [...selectedTagIds.remove, selectedTagId],
        };
      case Action.Add:
      default:
        return {
          add: [...selectedTagIds.add, selectedTagId],
          remove: selectedTagIds.remove.filter((tagId) => tagId !== selectedTagId),
        };
    }
  };

  const isDisabled = !(selectedTagIds.add.length || selectedTagIds.remove.length);

  return (
    <>
      <ModalBody>
        <InputGroup>
          <InputGroupText className='bg-white border-ThemeBorderDefault'>
            <i className='fa fa-light fa-magnifying-glass text-ThemeIconSecondary'></i>
          </InputGroupText>
          <Input
            className='border-ThemeBorderDefault border-start-0 pl-1'
            placeholder={'Search'}
            onChange={onSearch}
            defaultValue={search}
          />
        </InputGroup>
        {isLoading ? <Loader /> : null}
        {tagListError ? (
          <BasicAlert type={'danger'} className='mt-2'>
            {tagListError.message}
          </BasicAlert>
        ) : (
          <TagList
            tags={filteredCustomTags}
            selectedUtrIds={selectedUtrIds}
            search={search}
            handleSelect={handleSelect}
          />
        )}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color='transparent' onClick={openManageTags} data-testid='manage-tag-btn'>
          Manage tags
        </Button>
        <Button color='primary' disabled={isDisabled || isLoading} onClick={() => handleAddUtrsToTags(selectedTagIds)} data-testid='add-tag-btn'>
          Add tag
        </Button>
      </ModalFooter>
    </>
  );
};
