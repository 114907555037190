import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from '@components/loader';
import { BasicAlert } from '@components/alert/BasicAlert';
import { GridItemProps } from '@routes/custom-dashboard/GridItem';
import { HistoricalUtrsState, PreviewChartData } from '../types';
import { MultiUtrsChartWidget } from '@routes/custom-dashboard/items/charts/multi-utrs-chart/MultiUtrsChartWidget';
import {
  GridDashboardChartItem,
  GridDashboardSDGChartItem,
  InsightDashboardItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { ChartWidget } from '@routes/custom-dashboard/items/charts/ChartWidget';
import { transformVariables } from '@routes/custom-dashboard/utils';
import { isMultipleMetricsChart, getChartCalculation } from '../utils/dashboard-utils';
import { NoData } from '@routes/custom-dashboard/items/charts/common/NoData';
import { generateId } from '@utils/index';

interface PreviewModalProps extends Pick<GridItemProps, 'survey'> {
  initiativeId: string;
  isOpen: boolean;
  toggleOpen: () => void;
  chartData: PreviewChartData;
  historicalUtrsState: HistoricalUtrsState;
}

export const PreviewModal = ({
  initiativeId,
  isOpen,
  toggleOpen,
  chartData,
  survey,
  historicalUtrsState,
}: PreviewModalProps) => {
  const { historicalUtrs, isLoading, errorMessage } = historicalUtrsState;

  const isSDGTracker = chartData.type === InsightDashboardItemType.SDGTracker;
  const altUnitText = isSDGTracker ? 'contribution' : '';
  const utrs = historicalUtrs.map((utr) => utr.utr);
  const noData = historicalUtrs.every(({ utrvs }) => !utrvs.length);

  const tempVariables = transformVariables(chartData.metrics);
  const tempItem: GridDashboardChartItem | GridDashboardSDGChartItem = {
    ...chartData,
    variables: tempVariables,
    calculation: getChartCalculation({
      metrics: chartData.metrics,
      variables: tempVariables,
      subType: chartData.subType,
      chartType: chartData.type,
      questionsMap: new Map(utrs.map((utr) => [utr.code, utr])),
    }),
    // This is added for displaying the chart placeholder
    _id: generateId(),
    gridSize: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!chartData.metrics.length) {
    return null;
  }

  const previewChart = () => {
    if (noData) {
      return <NoData item={tempItem} />;
    }

    if (isMultipleMetricsChart(chartData)) {
      return (
        <MultiUtrsChartWidget
          altUnitText={altUnitText}
          initiativeId={initiativeId}
          item={tempItem}
          readOnly
          utrsData={historicalUtrs}
        />
      );
    }

    return (
      <ChartWidget
        utrData={historicalUtrs?.[0]}
        selectedColumnCode={chartData.metrics[0].valueListCode}
        initiativeId={initiativeId}
        type={chartData.type}
        subType={chartData.subType}
        readOnly
        survey={survey}
      />
    );
  };

  return (
    <Modal fade={false} isOpen={isOpen} toggle={toggleOpen} backdrop='static' keyboard>
      <ModalHeader toggle={toggleOpen}>Preview chart</ModalHeader>
      <ModalBody style={{ height: '260px' }}>
        <BasicAlert type={'danger'} className='mt-2'>
          {errorMessage}
        </BasicAlert>
        {previewChart()}
      </ModalBody>
    </Modal>
  );
};
