import { HistoricalUtrs } from '@api/insights';
import { UtrvType } from '@constants/status';
import { UniversalTrackerModalServiceUtrv } from '../../../../../reducers/universal-tracker-modal';
import { Calculation, CalculationType, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { getNumericValue, getValueWithFallback, sortUtrvByDate } from '@utils/charts';
import { DATE, formatDateUTC } from '@utils/date';
import { FormulaVariables, tryCalculation } from '@utils/formula';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { isNaOrNr } from '@utils/universalTrackerValue';
import { isNumeric } from '@utils/number';
import { HistoricalGenerated } from '@g17eco/types/integration';

const getActualUtrvs = (utrsData: HistoricalUtrs[]) => {
  return utrsData.reduce((acc, cur) => {
    return [...acc, ...cur.utrvs.filter((utrv) => utrv.type === UtrvType.Actual)];
  }, [] as UniversalTrackerModalServiceUtrv[]);
};

export const getActualUtrvsGroupByDate = (utrsData: HistoricalUtrs[]) => {
  const utrvs = getActualUtrvs(utrsData).sort(sortUtrvByDate);

  return utrvs.reduce((utrvsGroupByDate, utrv) => {
    const date = formatDateUTC(utrv.effectiveDate, DATE.MONTH_YEAR_SHORT);
    utrvsGroupByDate[date] = [...(utrvsGroupByDate[date] || []), utrv];
    return utrvsGroupByDate;
  }, {} as { [key: string]: UniversalTrackerModalServiceUtrv[] });
};

export const getLatestActualUtrvs = (utrsData?: HistoricalUtrs[]) => {
  if (!utrsData?.length) {
    return {};
  }

  const utrvs = getActualUtrvs(utrsData);

  const sortedUtrvs = utrvs.slice().sort(sortUtrvByDate).reverse();
  const latestActual = sortedUtrvs[0];

  if (!latestActual) {
    return {};
  }

  const { effectiveDate, period } = latestActual;

  const latestActualUtrvs = sortedUtrvs.filter(
    (utrv) => utrv.effectiveDate === effectiveDate && utrv.period === period
  );

  return {
    latestActualUtrvs,
    effectiveDate,
    period,
  };
};

export const getVariablesWithValues = ({
  utrsData,
  variables,
  utrvs,
  fallback = 0,
}: Required<Pick<GridDashboardChartItem, 'variables'>> & {
  utrsData: HistoricalUtrs[];
  utrvs: UniversalTrackerModalServiceUtrv[];
  fallback?: number | string | undefined;
}) => {
  return Object.keys(variables).reduce((variablesWithValues, variable) => {
    variablesWithValues[variable] = fallback;

    const utrData = utrsData.find((utrData) => utrData.utr.code === variables[variable].code);
    if (!utrData) {
      return variablesWithValues;
    }

    const utrv = utrvs.find((utrv) => utrv.universalTrackerId === utrData.utr._id);
    if (!utrv || isNaOrNr(utrv)) {
      return variablesWithValues;
    }

    if (isNumeric(fallback)) {
      // fallback is a number, therefore the value must be numeric
      variablesWithValues[variable] = getNumericValue(utrv, variables[variable].valueListCode, { isMultiRowsTable: true });
      return variablesWithValues;
    }

    variablesWithValues[variable] = getValueWithFallback(utrv, variables[variable].valueListCode, { isMultiRowsTable: true, fallback });
    return variablesWithValues;
  }, {} as FormulaVariables);
};

export const getMetricUnit = (utrsData: Pick<HistoricalGenerated, 'utr'>[]) => {
  return utrsData?.[0]?.utr ? getMetricUnitDesc(utrsData[0].utr, undefined, '') : '';
};

export const getSumOfVariables = (variablesWithValues: FormulaVariables) =>
  Object.values(variablesWithValues).reduce((acc, cur) => Number(acc) + Number(cur), 0) as number;

export const getLatestActualAndTargetSingleUtr = ({
  utrsData,
  calculation,
  variables,
}: { utrsData: HistoricalUtrs[] } & Required<Pick<GridDashboardChartItem, 'calculation' | 'variables'>>) => {
  const [variable] = Object.values(variables);
  const utrData = utrsData.find((data) => data.utr.code === (variable ? variable.code : ''));

  if (!utrData) {
    return {
      variablesWithValues: {},
    };
  }

  const sortedUtrvs = utrData.utrvs.slice().sort(sortUtrvByDate).reverse();
  const latestActualUtrv = sortedUtrvs.find((utrv) => utrv.type === UtrvType.Actual);
  const latestTargetUtrv = sortedUtrvs.find((utrv) => utrv.type === UtrvType.Target);

  const variablesWithActualValue = getVariablesWithValues({
    utrsData,
    variables,
    utrvs: latestActualUtrv ? [latestActualUtrv] : [],
    fallback: '',
  });

  const actualValue = tryCalculation({
    formula: calculation.values[0].formula || '',
    variables: variablesWithActualValue,
    fallback: '',
    infinityFallback: '',
  });

  const variablesWithTargetValue = getVariablesWithValues({
    utrsData,
    variables,
    utrvs: latestTargetUtrv ? [latestTargetUtrv] : [],
    fallback: '',
  });

  const targetValue = tryCalculation({
    formula: calculation.values[0].formula || '',
    variables: variablesWithTargetValue,
    fallback: '',
    infinityFallback: '',
  });
  const effectiveDate = latestActualUtrv?.effectiveDate || latestTargetUtrv?.effectiveDate || '';
  const period = latestActualUtrv?.period || latestTargetUtrv?.period || '';

  return { actualValue, targetValue, effectiveDate, period, variablesWithValues: variablesWithActualValue };
};

export const getTextColor = ({
  actualValue,
  targetValue,
}: {
  actualValue: number | string | undefined;
  targetValue: number | string | undefined;
}) => {
  if (!actualValue || !targetValue) {
    return 'text-ThemeTextMedium';
  }
  if (Number(actualValue) > Number(targetValue)) {
    return 'text-ThemeSuccessMedium';
  }
  return 'text-ThemeWarningMedium';
};

interface VAxisTitle {
  unitText: string | undefined;
  calculation: Calculation;
  utrsData: HistoricalUtrs[];
}

export const getVAxisTitle = ({ unitText, calculation, utrsData }: VAxisTitle) => {
  if (unitText) {
    return unitText;
  }

  return calculation.type === CalculationType.Percentage ? '%' : getMetricUnit(utrsData);
};
