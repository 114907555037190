import { useGetSecondaryConnectionsQuery } from '@api/utrv';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { QUESTION } from '@constants/terminology';
import { DashboardDivider } from '@g17eco/atoms';
import { getGroup } from '@g17eco/core';
import { UniversalTrackerPlain, UtrValueType } from '@g17eco/types/universalTracker';
import {
  Calculation,
  CalculationType,
  UniversalTrackerConnection,
  CalculationUtr,
  VariableInfo,
  CalculationIntegrationUtr,
} from '@g17eco/types/utrv-connections';
import { useToggle } from '@hooks/useToggle';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import classNames from 'classnames';
import React from 'react';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CalculationWithUtrInfo } from './types';
import { SelectedConnection, useConnectionContext } from './ConnectionContext';
import SimpleTooltip from '@components/simple-tooltip';

interface VariableProps {
  variable: VariableInfo;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
}

const getPackInfo = (utr: CalculationUtr | CalculationIntegrationUtr) => {
  if ('type' in utr) {
    const standard = utr.type ? getGroup('standards', utr.type) : undefined;
    return standard ? { logo: standard.src, shortName: standard.shortName } : undefined;
  }

  return utr.provider ? { logo: utr.provider.logo, shortName: utr.provider.shortName } : undefined;
};
const Variable = ({ variable, utrs, integrationUtrs }: VariableProps) => {
  const utr = (variable.integrationCode ? integrationUtrs : utrs).find((utr) => utr.code === variable.utrCode);
  if (!utr) {
    return null;
  }

  const inputTitle = utr.valueValidation?.table?.columns.find((column) => column.code === variable.valueListCode)?.name;
  const packInfo = getPackInfo(utr);
  return (
    <div className='mt-1'>
      <div className='d-flex align-items-center'>
        {packInfo ? (
          <>
            <img src={packInfo.logo} alt={packInfo.shortName} height='16px' className='mr-1' />
            <div className='me-auto text-nowrap text-ThemeTextDark'>{packInfo.shortName}</div>
          </>
        ) : null}

        <p className='flex-grow-1 text-right my-0 ms-3 text-truncate text-ThemeTextDark text-sm'>
          <SimpleTooltip text={utr.valueLabel}>{utr.valueLabel}</SimpleTooltip>
        </p>
      </div>
      {inputTitle ? (
        <>
          <p className='mb-0 mt-1 text-ThemeTextMedium text-sm text-right'>{inputTitle}</p>
        </>
      ) : null}
    </div>
  );
};

interface VariableCollapseProps {
  name: string;
  variable: VariableInfo;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
}
const VariableCollapse = ({ name, variable, utrs, integrationUtrs }: VariableCollapseProps) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <div className='mt-2 d-flex justify-content-end align-items-center cursor-pointer' onClick={toggle}>
        <p className='mb-0 mr-1 text-ThemeTextMedium text-sm'>
          {QUESTION.CAPITALIZED_SINGULAR} {name.toUpperCase()}
        </p>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        <Variable variable={variable} utrs={utrs} integrationUtrs={integrationUtrs} />
      </Collapse>
    </>
  );
};

// {a} + {b} -> Metric A + Metric B
const transformFormula = (formula: string) => {
  return formula
    .toUpperCase()
    .replace(/{/g, ` ${QUESTION.CAPITALIZED_SINGULAR} `)
    .replace(/}/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
};

interface VariablesProps {
  calculation: Calculation;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
}
const Variables = ({ calculation, utrs, integrationUtrs }: VariablesProps) => {
  switch (calculation.type) {
    case CalculationType.Direct: {
      return (
        <Variable variable={calculation.variables[calculation.direct]} utrs={utrs} integrationUtrs={integrationUtrs} />
      );
    }
    case CalculationType.Formula: {
      const variables = calculation.variables;

      return (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='text-sm my-0 mt-2 text-ThemeTextMedium'>Calculation:</label>
            <p className='mb-0 text-ThemeTextDark text-sm'>{transformFormula(calculation.formula)}</p>
          </div>
          {Object.keys(variables).map((key) => (
            <VariableCollapse
              key={key}
              name={key}
              variable={variables[key]}
              utrs={utrs}
              integrationUtrs={integrationUtrs}
            />
          ))}
        </>
      );
    }
    case CalculationType.Stages:
    default: {
      return <div>Not support yet</div>;
    }
  }
};

const getInputValue = ({
  utrv,
  utr,
  valueListCode,
}: Pick<Props, 'utr' | 'utrv'> & {
  valueListCode?: string;
}) => {
  switch (utr.valueType) {
    case UtrValueType.Table: {
      const tableData = utrv.valueData?.table;
      if (!valueListCode || !tableData?.length || utr.valueValidation?.table?.validation?.maxRows !== 1) {
        return undefined;
      }

      const value = tableData[0]?.find((col) => col.code === valueListCode)?.value;
      return value === undefined ? undefined : Number(value);
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return utrv.value;
    }
    default: {
      return undefined;
    }
  }
};

interface ConnectionProps {
  calculation: CalculationWithUtrInfo;
  name: string | undefined;
  unitDesc: string;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
  selectedConnection?: SelectedConnection;
  onClickConnection: () => void;
  onPopulateInput: () => void;
}

const Connection = ({
  calculation,
  name,
  unitDesc,
  utrs,
  integrationUtrs,
  selectedConnection,
  onClickConnection,
  onPopulateInput,
}: ConnectionProps) => {
  return (
    <div
      className={classNames('p-1', {
        'cursor-pointer': calculation.valueListCode,
        'background-ThemeAccentExtralight': selectedConnection?._id === calculation._id,
      })}
      onClick={onClickConnection}
    >
      {name ? <label className='text-ThemeTextMedium mb-2'>CONNECTION {name}</label> : null}
      <div key={calculation._id}>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='text-sm me-auto my-0 text-ThemeTextMedium'>Answer:</label>
          <p className='mb-0 text-ThemeTextDark fw-bold'>
            {calculation.data} {unitDesc}
          </p>
          <i
            className='fa-light fa-arrow-right-to-arc ms-2 text-ThemeAccentMedium cursor-pointer'
            onClick={onPopulateInput}
          />
        </div>
        <Variables calculation={calculation} utrs={utrs} integrationUtrs={integrationUtrs} />
      </div>
    </div>
  );
};

const WarningModal = ({
  open,
  toggle,
  handleConfirm,
}: {
  open: boolean;
  toggle: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeWarningExtraDark'>Replace answer?</span>
      </ModalHeader>
      <ModalBody>
        <p className='mb-0'>
          This field already has a value/content in it. If you proceed this value will be replaced with the answer you
          have selected in the sidebar.
        </p>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={toggle}>
          Cancel
        </Button>
        <Button color='warning' onClick={handleConfirm}>
          Replace answer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation'>;
  utrv: SurveyModelMinimalUtrv;
}

// For now, we treat all calculations as connections to be simply displayed. Might extend this in the future.
const getCalculations = (connections: UniversalTrackerConnection[]) => {
  const calculations: CalculationWithUtrInfo[] = [];
  const dataMap = new Map<string, number[]>();

  connections.forEach((connection) => {
    const { utrCode, valueListCode, calculationGroups } = connection;
    const key = `${utrCode}-${valueListCode}`;

    calculationGroups.forEach((calculationGroup) => {
      calculationGroup.calculations.forEach((calculation) => {
        // Show calculations that have data and filter out one that has duplicate data.
        const dataList = dataMap.get(key) ?? [];
        if (calculation.data && !dataList.includes(calculation.data)) {
          dataMap.set(key, [...dataList, calculation.data]);
          calculations.push({ ...calculation, utrCode, valueListCode });
        }
      });
    });
  });

  return calculations;
};

export const Connections = ({ utr, utrv }: Props) => {
  const getSecondaryConnectionsQuery = useGetSecondaryConnectionsQuery({ utrvId: utrv._id });

  const { connection, setConnection, setInputData } = useConnectionContext();
  const [showWarning, toggleShowWarning] = useToggle(false);

  const handlePopulateInput = (calculation: CalculationWithUtrInfo) => {
    setConnection(calculation);
    if (!calculation) {
      return;
    }

    const data = calculation.data;
    if (data === undefined) {
      return;
    }

    const currentValue = getInputValue({ utr, utrv, valueListCode: calculation.valueListCode });

    if (currentValue === data) {
      return;
    }

    if (currentValue === undefined) {
      setInputData(data);
      return;
    }

    toggleShowWarning();
  };

  const handleConfirm = () => {
    setInputData(connection?.data);
    toggleShowWarning();
  };

  const onSuccessRender = ({
    connections,
    utrs,
    integrationUtrs = [],
  }: {
    connections: UniversalTrackerConnection[];
    utrs: CalculationUtr[];
    integrationUtrs?: CalculationIntegrationUtr[];
  }) => {
    const calculations = getCalculations(connections);

    if (!calculations.length) {
      return <p>This {QUESTION.SINGULAR} has no connections</p>;
    }

    return (
      <>
        <p className='text-sm text-ThemeTextLight'>
          Click <i className='fa-light fa-arrow-right-to-arc' /> to populate input field with selected answer
        </p>
        {calculations.map((calculation, index, calculations) => (
          <React.Fragment key={calculation._id}>
            {index ? <DashboardDivider className='my-3' /> : null}
            <Connection
              calculation={calculation}
              name={calculations.length === 1 ? undefined : String(index + 1)}
              unitDesc={getMetricUnitDesc(utr, calculation.valueListCode, '')}
              utrs={utrs}
              integrationUtrs={integrationUtrs}
              selectedConnection={connection}
              onClickConnection={() => setConnection(calculation)}
              onPopulateInput={() => handlePopulateInput(calculation)}
            />
          </React.Fragment>
        ))}
        <WarningModal open={showWarning} toggle={toggleShowWarning} handleConfirm={handleConfirm} />
      </>
    );
  };

  return (
    <div>
      <QueryWrapper query={getSecondaryConnectionsQuery} onSuccess={onSuccessRender} />
    </div>
  );
};
