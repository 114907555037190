import { CustomMetric } from '@api/custom-metrics';
import { QUESTION, SURVEY } from '@constants/terminology';
import Table, { TableColumn } from '../table/Table';
import { CTAButtons } from './partials/CTAButtons';
import { NameCell } from './partials/NameCell';

interface CustomMetricTableProps {
  questions: CustomMetric[];
  isUsed: boolean;
  handleOpenEditUtr: (utrId: string) => void;
  handleReadOnlyUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utr: CustomMetric) => void;
}

export const CustomMetricTable = ({
  questions,
  isUsed,
  handleOpenEditUtr,
  handleReadOnlyUtr,
  handleOpenDeleteUtr,
}: CustomMetricTableProps) => {
  const columns: TableColumn<CustomMetric>[] = [
    {
      accessor: 'name',
      Header: () => `${QUESTION.CAPITALIZED_SINGULAR} name`,
      minWidth: 500,
      cellProps: {
        className: 'nameCol flex-grow-1 order-1',
      },
      Cell: ({ row }) => <NameCell isInGroup={true} row={row} />,
    },
    {
      id: 'in-survey',
      disableSortBy: true,
      Header: () => (isUsed ? `In a ${SURVEY.SINGULAR}` : `Added to ${SURVEY.SINGULAR}`),
      cellProps: {
        className: 'typeCol text-center order-2',
      },
      Cell: ({ row }) => (
        <h4 className='m-0'>
          {row.original.universalTrackerValues.length ? (
            <i className='fal fa-check text-ThemeSuccessMedium' />
          ) : (
            <i className='fal fa-dash text-ThemeNeutralsExtradark' />
          )}
        </h4>
      ),
    },
    {
      id: 'answered',
      disableSortBy: true,
      Header: () => 'Answered',
      cellProps: {
        className: 'typeCol text-center order-2',
      },
      Cell: () => (
        <h4 className='m-0'>
          {isUsed ? (
            <i className='fal fa-check text-ThemeSuccessMedium' />
          ) : (
            <i className='fal fa-dash text-ThemeNeutralsExtradark' />
          )}
        </h4>
      ),
    },
    {
      id: 'buttons',
      Header: () => '',
      disableSortBy: true,
      cellProps: {
        className: 'buttonsCol flex-shrink-1 order-3',
      },
      Cell: ({ row }) => {
        return (
          <CTAButtons
            handleOpenEditUtr={handleOpenEditUtr}
            handleReadOnlyUtr={handleReadOnlyUtr}
            universalTracker={row.original}
            handleOpenDeleteUtr={handleOpenDeleteUtr}
            isDisabled={isUsed}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='metric-table-container' data-testid='table-custom-metrics'>
        <Table responsive={true} columns={columns} data={questions} pageSize={10} unit='questions' />
      </div>
      {!questions.length ? (
        <div className='metric-group-form--text-helper'>No custom {QUESTION.PLURAL} in this company</div>
      ) : null}
    </>
  );
};
