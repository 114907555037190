import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import { useGetTemplateByIdQuery } from '../../api/survey-templates';
import { useGetHistoryQuery } from '../../api/survey-templates-history';
import { useAppSelector } from '../../reducers';
import NotAuthorised from '../../routes/not-authorised';
import { NotFound } from '../../routes/not-found/NotFound';
import { getCurrentUser } from '../../selectors/user';
import { SurveyPermissions } from '../../services/permissions/SurveyPermissions';
import { getCheckOptions } from '../../utils/survey';
import Dashboard, { DashboardSection } from '../dashboard';
import Loader from '../loader';
import LoadingPlaceholder from '../LoaderContainer/LoadingPlaceholder';
import { SurveyQuestionForm } from '../survey-configuration/partials/SurveyQuestionForm';
import { SurveyReportingSettings } from '../survey-configuration/partials/SurveyReportingSettings';
import SurveyUnitsAndCurrency from '../survey-configuration/partials/SurveyUnitsAndCurrency';
import { TemplateDashboardHeader } from '../survey-templates/partials/TemplateDashboardHeader';
import { TemplateHistoryHeader } from './TemplateHistoryHeader';
import { useGetSelectedHistory } from './hooks/useGetSelectedHistory';
import { TemplateHistoryNav } from './TemplateHistoryNav';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';
import { NoteInstructionContainer } from '../../features/note-instructions/NoteInstructionContainer';
import { SURVEY } from '@constants/terminology';

export const TemplateHistoryConfiguration = () => {
  const { templateId = '' } = useParams<{ templateId: string }>();
  const historyQuery = useGetHistoryQuery(templateId, { skip: !templateId });
  const templateQuery = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const currentUser = useAppSelector(getCurrentUser);
  const canAccessVerification = useAppSelector(FeaturePermissions.canAccessVerification);
  const canSetNoteInstructions = useAppSelector(FeaturePermissions.canSetNoteInstructions);
  const checkOptions = getCheckOptions(canAccessVerification);
  const selectedHistory = useGetSelectedHistory(historyQuery.data);

  const canManage = useMemo(() => {
    if (!currentUser || !selectedHistory) {
      return false;
    }
    return SurveyPermissions.canManage({ initiativeId: selectedHistory.initiativeId }, currentUser);
  }, [currentUser, selectedHistory]);

  if (historyQuery.isFetching || templateQuery.isFetching) {
    return <Loader />;
  }

  if (!selectedHistory || !templateQuery.data) {
    return <NotFound />;
  }

  if (!canManage) {
    return <NotAuthorised />;
  }

  return (
    <Dashboard>
      <TemplateHistoryHeader />
      <DashboardSection
        icon='fal fa-cog'
        header={<TemplateDashboardHeader title={`${SURVEY.CAPITALIZED_SINGULAR} templates: ${templateQuery.data.name}`} />}
      >
        <TemplateHistoryNav />
        <LoadingPlaceholder className='mt-4' count={1} height={800} isLoading={false}>
          <Form className='survey-config-form'>
            <div className='mt-4'>
              <h5>{SURVEY.CAPITALIZED_SINGULAR} name</h5>
              <Input disabled placeholder={selectedHistory.snapshot.name} />
            </div>
            <SurveyReportingSettings isDisabled form={selectedHistory.snapshot} />
            <SurveyQuestionForm
              disabled
              isCheckboxDisabled
              checkOptions={checkOptions}
              form={selectedHistory.snapshot}
              updateForm={() => {}}
            />
            <FormGroup className='mt-4' disabled>
              <SurveyUnitsAndCurrency isDisabled unitConfig={selectedHistory.snapshot.unitConfig} />
            </FormGroup>

            <NoteInstructionContainer
              editorId={templateId}
              updateForm={() => {}}
              disabled={true}
              canSetNoteInstructions={canSetNoteInstructions}
              noteInstructionsEditorState={selectedHistory.snapshot.noteInstructionsEditorState}
              noteInstructions={selectedHistory.snapshot.noteInstructions}
            />
          </Form>
        </LoadingPlaceholder>
      </DashboardSection>
    </Dashboard>
  );
};
