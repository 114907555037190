import { useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import {
  InsightDashboard,
  SurveyFilter,
  UtrvFilter,
  ToggleFilter,
  PrivacyFilter,
  InsightDashboardFilters,
  TimeFrameFilter,
  DashboardSurveyType,
  InsightDashboardItemType,
  InsightDashboardSettingKeys,
  InsightDashboardTitle,
} from '@g17eco/types/insight-custom-dashboard';
import { SelectFactory, SelectTypes, Sidebar, TimeRangeSelector } from '@g17eco/molecules';
import { EditorTemplate } from '@features/custom-dashboard/item-editor/EditorTemplate';
import { CollapseButton, CollapseContent, CollapsePanel } from '@components/collapse-panel';
import { DashboardDivider } from '@g17eco/atoms';
import { TIME_RANGE_OPTIONS } from '@utils/date';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { DateRangeType } from '@g17eco/types/common';
import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import {
  DEFAULT_FILTERS,
  getTimeFramePeriod,
  getTimeFrameType,
  isDashboardSettingHidden,
  metricPrivacyOptions,
  metricStatusOptions,
  surveyStatusOptions,
  surveyTypeOptions,
} from './utils';
import { isESGDashboard } from '@routes/custom-dashboard/shared-dashboard/utils';
import { generateGridDashboardItem } from '@routes/custom-dashboard/utils';
import { QUESTION, SURVEY } from '@constants/terminology';

interface SwitchGroupProps {
  filterKey: keyof InsightDashboard['filters'];
  label: string;
  checked: boolean | undefined;
  handleToggle: (key: keyof InsightDashboard['filters']) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const SwitchGroup = ({ filterKey, label, checked, handleToggle }: SwitchGroupProps) => {
  return (
    <FormGroup switch className='d-flex align-items-center gap-2'>
      <Input type='switch' role='switch' checked={checked} onChange={handleToggle(filterKey)} />
      <Label className='text-md' check>
        {label}
      </Label>
    </FormGroup>
  );
};

interface DropdownGroupProps {
  label: string;
  children: React.ReactNode;
}
const DropdownGroup = ({ label, children }: DropdownGroupProps) => {
  return (
    <FormGroup switch className='d-flex justify-content-between align-items-center gap-2'>
      <Label className='text-md' check>
        {label}
      </Label>
      {children}
    </FormGroup>
  );
};

interface Props {
  isOpenSidebar: boolean;
  toggleSidebar: () => void;
  dashboard: Pick<InsightDashboard, 'title' | 'filters' | 'type' | 'items'>;
  handleSave: (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => void;
  hideOptions?: InsightDashboardSettingKeys[];
  availablePeriods: DataPeriods[];
}

export const DashboardSettingsSidebar = ({
  isOpenSidebar,
  toggleSidebar,
  dashboard,
  hideOptions = [],
  availablePeriods,
  handleSave,
}: Props) => {
  const { title, filters } = dashboard;
  const [titleInput, setTitle] = useState(title);
  const [filtersInput, setFiltersInput] = useState<InsightDashboard['filters']>({ ...DEFAULT_FILTERS, ...filters });

  const handleChange =
    (key: keyof InsightDashboard['filters']) =>
    (
      value:
        | TimeFrameFilter
        | DashboardSurveyType
        | DataPeriods
        | SurveyFilter
        | UtrvFilter
        | PrivacyFilter
        | ToggleFilter
        | undefined
    ) => {
      if (!value) {
        return;
      }
      setFiltersInput((filters) => ({ ...filters, [key]: value }));
    };

  const handleToggle = (key: keyof InsightDashboard['filters']) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(key)({ enabled: e.currentTarget.checked });
  };

  const handleChangeTimeFrame = (dateRange: DateRangeType, period: string | number) => {
    handleChange(InsightDashboardFilters.TimeFrame)({ type: getTimeFrameType(period), ...dateRange });
  };

  const getUpdatedDashboardItems = () => {
    if (isESGDashboard(dashboard.type)) {
      return dashboard.items;
    }
    // switch sdgContributionChart on, add sdgContribution chart item to dashboard
    if (!dashboard.filters.sdgContribution?.enabled && filtersInput.sdgContribution?.enabled) {
      const item = generateGridDashboardItem({ type: InsightDashboardItemType.SDGContributionChart }, dashboard.items);
      return [...dashboard.items, item];
    }
  };

  const handleClickSave = () => {
    toggleSidebar();
    const updatedItems = getUpdatedDashboardItems();
    handleSave({ title: titleInput, filters: filtersInput, items: updatedItems }, true);
  };

  return (
    <Sidebar header='Dashboard settings' isOpen={isOpenSidebar} toggle={toggleSidebar} direction='end'>
      <EditorTemplate isDisabled={false} handleCancel={toggleSidebar} handleSubmit={handleClickSave}>
        {isDashboardSettingHidden(hideOptions) ? null : (
          <>
            <CollapsePanel className='py-3' collapsed={false}>
              <CollapseButton>
                <span className='ml-2'>Dashboard settings</span>
              </CollapseButton>

              <CollapseContent>
                {hideOptions.includes(InsightDashboardTitle) ? null : (
                  <div className='d-flex align-items-center gap-3 mt-3'>
                    <Label className='flex-grow-0 text-md'>Title</Label>
                    <Input
                      type='text'
                      className='flex-grow-1 text-md'
                      value={titleInput ?? ''}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder='Enter a dashboard title'
                    />
                  </div>
                )}
                <div className='d-flex flex-column gap-2 mt-3'>
                  {hideOptions.includes(InsightDashboardFilters.ShareWithSubsidiaries) ? null : (
                    <SwitchGroup
                      filterKey={InsightDashboardFilters.ShareWithSubsidiaries}
                      checked={filtersInput.shareWithSubsidiaries?.enabled}
                      label='Share dashboard with children'
                      handleToggle={handleToggle}
                    />
                  )}
                  {hideOptions.includes(InsightDashboardFilters.BaselinesTargets) ? null : (
                    <SwitchGroup
                      filterKey={InsightDashboardFilters.BaselinesTargets}
                      checked={filtersInput.baselinesTargets?.enabled}
                      label='Display targets and baselines'
                      handleToggle={handleToggle}
                    />
                  )}
                  <SwitchGroup
                    filterKey={InsightDashboardFilters.InitiativeInfo}
                    checked={filtersInput.initiativeInfo?.enabled}
                    label='Display company details heading'
                    handleToggle={handleToggle}
                  />
                  <SwitchGroup
                    filterKey={InsightDashboardFilters.SdgContribution}
                    checked={filtersInput.sdgContribution?.enabled}
                    label='Display SDG chart'
                    handleToggle={handleToggle}
                  />
                </div>
              </CollapseContent>
            </CollapsePanel>

            <DashboardDivider className='mt-2 mb-1' />
          </>
        )}

        <CollapsePanel className='py-3' collapsed={false}>
          <CollapseButton>
            <span className='ml-2'>{SURVEY.CAPITALIZED_SINGULAR} settings</span>
          </CollapseButton>

          <CollapseContent>
            <div className='d-flex flex-column gap-2 mt-3'>
              {hideOptions.includes(InsightDashboardFilters.TimeFrame) ? null : (
                <DropdownGroup label='Timeframe'>
                  <TimeRangeSelector
                    timeRange={getTimeFramePeriod(filtersInput.timeFrame?.type ?? DEFAULT_FILTERS.timeFrame.type)}
                    dateRange={{
                      startDate: filtersInput.timeFrame?.startDate,
                      endDate: filtersInput.timeFrame?.endDate,
                    }}
                    timeRangeOptions={TIME_RANGE_OPTIONS}
                    styleProps={{}}
                    onChangeDateRange={handleChangeTimeFrame}
                  />
                </DropdownGroup>
              )}

              <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} type`}>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  options={surveyTypeOptions}
                  value={surveyTypeOptions.find(
                    (op) => op.value === (filtersInput.surveyType ?? DEFAULT_FILTERS.surveyType)
                  )}
                  onChange={(op) => handleChange(InsightDashboardFilters.SurveyType)(op?.value)}
                />
              </DropdownGroup>

              {hideOptions.includes(InsightDashboardFilters.Period) ? null : (
                <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} period`}>
                  <SurveyPeriodDropdown
                    period={filtersInput.period}
                    availablePeriods={availablePeriods}
                    setPeriod={(value) => handleChange(InsightDashboardFilters.Period)(value)}
                  />
                </DropdownGroup>
              )}

              {hideOptions.includes(InsightDashboardFilters.Survey) ? null : (
                <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} status`}>
                  <SelectFactory
                    selectType={SelectTypes.SingleSelect}
                    options={surveyStatusOptions}
                    value={surveyStatusOptions.find((op) => op.value === filtersInput.survey)}
                    onChange={(op) => handleChange(InsightDashboardFilters.Survey)(op?.value)}
                  />
                </DropdownGroup>
              )}
            </div>
          </CollapseContent>
        </CollapsePanel>

        <DashboardDivider className='mt-2 mb-1' />

        <CollapsePanel className='py-3' collapsed={true}>
          <CollapseButton>
            <span className='ml-2'>{QUESTION.CAPITALIZED_SINGULAR} settings</span>
          </CollapseButton>

          <CollapseContent>
            <div className='d-flex flex-column gap-2 mt-3'>
              <DropdownGroup label={`${QUESTION.CAPITALIZED_SINGULAR} status`}>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  options={metricStatusOptions}
                  value={metricStatusOptions.find((op) => op.value === filtersInput.utrv)}
                  onChange={(op) => handleChange(InsightDashboardFilters.Utrv)(op?.value)}
                />
              </DropdownGroup>

              {hideOptions.includes(InsightDashboardFilters.Privacy) ? (
                <></>
              ) : (
                <DropdownGroup label={`${QUESTION.CAPITALIZED_SINGULAR} privacy`}>
                  <SelectFactory
                    selectType={SelectTypes.SingleSelect}
                    options={metricPrivacyOptions}
                    value={metricPrivacyOptions.find((op) => op.value === filtersInput.privacy)}
                    onChange={(op) => handleChange(InsightDashboardFilters.Privacy)(op?.value)}
                  />
                </DropdownGroup>
              )}
            </div>
          </CollapseContent>
        </CollapsePanel>
      </EditorTemplate>
    </Sidebar>
  );
};
