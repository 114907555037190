import React from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import SimpleTooltip from '@components/simple-tooltip';

export interface AdminSettingsCardProps {
  icon: string;
  text: string | React.ReactNode;
  onClickHandler?: React.MouseEventHandler<HTMLElement>;
  disabledText?: string;
}

export const AdminSettingsCard = ({
  icon = '',
  text = '',
  onClickHandler = () => {},
  disabledText = '',
}: AdminSettingsCardProps) => {
  return (
    <div className='settings-card-container'>
      <SimpleTooltip text={disabledText}>
        <Card
          className={`settings-card p-4 ${disabledText ? 'disabled' : 'active'}`}
          body
          onClick={disabledText ? undefined : onClickHandler}
        >
          <CardTitle className='settings-card-icon mb-3'>
            <i className={icon} />
          </CardTitle>
          <CardText tag={'div'} className='settings-card-text'>{text}</CardText>
        </Card>
      </SimpleTooltip>
    </div>
  );
};
