import { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import { RequestScope } from '@actions/api';
import { loadCustomMetricGroupsByInitiativeId } from '@actions/initiative';
import { SURVEY_PACKS } from '@constants/standards-frameworks';
import { useAppSelector } from '../../../../reducers';
import { ClassificationsLabels, SubmissionInsightsRowValues } from '@g17eco/types/portfolio';
import IconButton from '@components/button/IconButton';
import { Option, SelectFactory, SelectTypes, TimeRangeSelector } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import { UrlParams } from '../PackUsage';
import { ACTION, MetricView, SubmissionInsightsContext, SubmissionInsightsView } from './SubmissionInsightsContainer';
import { getClassificationsTextArray, getDisplayColumn, getDynamicRowValues, getViewOptions } from './utils';
import { exportToExcel } from '@components/downloads/util/exportToExcel';
import { PACK, SURVEY } from '@constants/terminology';

type PackOption = Pick<Option<string>, 'value' | 'label' | 'searchString'> & {
  scopeType?: string | 'standards' | 'frameworks' | 'sdg' | 'custom';
};

const viewOptions = getViewOptions();

export const SubmissionInsightsFilter = () => {
  const { filters, tableData, dispatch } = useContext(SubmissionInsightsContext);
  const reactDispatch = useDispatch();
  const { portfolioId } = useParams<UrlParams>();
  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  useEffect(() => {
    if (!portfolioId) {
      return
    }
    reactDispatch(loadCustomMetricGroupsByInitiativeId(portfolioId))
  }, [reactDispatch, portfolioId]);

  const packOptions = useMemo(() => {

    const customGroupOptions =  metricGroups.map(el => {
      return {
        label: <span>
          {el.groupData?.icon ? <img src={el.groupData.icon} alt={el.groupName} height='26px' className='mr-2'/> : null}
          <span>{el.groupName}</span>
        </span>,
        searchString: el.groupName,
        value: el._id,
        scopeType: 'custom',
      }
    });

    return [...SURVEY_PACKS, ...customGroupOptions]
  }, [metricGroups])

  const handleChangeFilters = (key: string, option: DateRangeType | RequestScope | string | boolean | undefined) => {
    if (option !== undefined) {
      dispatch({
        type: ACTION.SET_FILTERS,
        payload: {
          [key]: option,
        },
      });
    }
  };

  const handleChangeScope = (key: string, option: PackOption | null) => {
    if (option !== null) {
      const scopeGroup: RequestScope = {
        code: option.value,
        scopeType: option.scopeType || '',
      };
      handleChangeFilters(key, scopeGroup);
    }
  };

  const downloadReport = () => {
    const rowHeaders = [filters.view.toString()]
      .concat(Object.values(ClassificationsLabels))
      .concat(tableData.utrs.map((utr) => getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code })));
    const rowValues = tableData.utrValues.map((rowValue: SubmissionInsightsRowValues) => {
      return [
        rowValue.name,
        ...getClassificationsTextArray(rowValue),
        ...getDynamicRowValues(rowValue, tableData.utrs, filters.metricView),
      ];
    });

    exportToExcel({
      headers: rowHeaders,
      values: rowValues,
      fileName: 'benchmarking-submission-insights',
      sheetName: 'submission-insights',
    });
  };

  return (
    <div className='d-flex justify-content-between align-items-center gap-3 mt-5'>
      <SelectFactory<SubmissionInsightsView>
        selectType={SelectTypes.SingleSelect}
        options={viewOptions}
        onChange={(option) => handleChangeFilters('view', option?.value)}
        value={viewOptions.find((view) => view.value === filters.view)}
        defaultValue={viewOptions[0]}
        className='filter__select flex-grow-1'
        isSearchable={false}
      />
      <SelectFactory<string>
        selectType={SelectTypes.SingleSelect}
        placeholder={`${SURVEY.CAPITALIZED_ADJECTIVE} ${PACK.SINGULAR}`}
        options={packOptions}
        value={packOptions.find((v) => v.value === filters.scopeGroup?.code) ?? null}
        onChange={(option) => handleChangeScope('scopeGroup', option)}
        className='filter__select flex-grow-1'
      />
      <Label check>
        <Input
          type='radio'
          name='status'
          className='mr-2'
          onChange={(option) => handleChangeFilters('metricView', option.target.value)}
          checked={filters.metricView === MetricView.Average}
          value={MetricView.Average}
        />
        Average
      </Label>
      <Label check>
        <Input
          type='radio'
          name='status'
          className='mr-2'
          onChange={(option) => handleChangeFilters('metricView', option.target.value)}
          checked={filters.metricView === MetricView.Total}
          value={MetricView.Total}
        />
        Sum
      </Label>
      <TimeRangeSelector
        dateRange={filters.dateRange}
        onChangeDateRange={(range) => handleChangeFilters('dateRange', range)}
      />
      <IconButton
        onClick={downloadReport}
        outline={false}
        color='transparent'
        className='text-ThemeAccentDark'
        icon='fal fa-file-excel'
      />
    </div>
  );
};
