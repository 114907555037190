import { DecimalType, ErrorDecimalType } from '@components/utr-decimal/utils';
import { UniversalTrackerPlain, ValueValidation } from './universalTracker';

export interface InitiativeUniversalTracker extends Pick<UniversalTrackerPlain, 'unitInput' | 'numberScaleInput' | 'valueValidation'> {
  _id: string;
  initiativeId: string;
  universalTrackerId: string;
}

export interface DecimalInputProps {
  decimal: DecimalType;
  error: ErrorDecimalType;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum DecimalInputType {
  SingleInput = 'single-input',
  Table = 'table',
}
