import { SurveyUnitsAndCurrencyText } from '@components/survey-configuration/partials/SurveyUnitsAndCurrency';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { Option } from '@g17eco/molecules';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { UnitConfig } from '@models/surveyData';
import { getCurrencyList, getUnitsForType, UnitOption, UnitTypes } from '@utils/units';

export enum Tabs {
  MetricOverrides = 'Metric overrides',
  Tags = 'Tags',
}

export const getModalTitle = (selectedQuestions: Pick<BulkActionUtr, 'name'>[] | undefined) => {
  if (!selectedQuestions || !selectedQuestions.length) {
    return '';
  }
  if (selectedQuestions.length === 1) {
    return selectedQuestions[0].name ?? 'Configuring a metric';
  }
  return 'Configuring multiple metrics';
};

export const getDescription = (tab: Tabs | undefined) => {
  switch (tab) {
    case Tabs.MetricOverrides:
      return (
        'These metric override settings are global. ' +
        'Any reports created in the future and any metrics currently unanswered will follow the below rules. ' +
        'Already answered metrics will not be impacted by changes.'
      );
    case Tabs.Tags:
      return (
        'Tags are set globally. Any reports created in the future  will be tagged with the below. ' +
        'Metrics already added to scope will not update their tags'
      );
    default:
      return '';
  }
};

export const getUnitLabel = (unitType: string) => {
  if (unitType === UnitTypes.numberScale) {
    return 'Number scale';
  }
  if (unitType in SurveyUnitsAndCurrencyText) {
    return SurveyUnitsAndCurrencyText[unitType as keyof UnitConfig].title;
  }
  return unitType;
};

export type UnitTypeMapValue = { defaultValue: string; overriddenValue: string | undefined; utrIds: Set<string> };

export const getUnitTypeMap = (
  selectedQuestions: BulkActionUtr[],
  questionsWithValidation: InitiativeUniversalTracker[]
) => {
  const unitTypeMap = new Map<string, UnitTypeMapValue>();

  const addUnitType = (
    question: BulkActionUtr,
    unitType: string | undefined,
    unit: string | undefined,
    overrideUnit: string | undefined
  ) => {
    if (!unitType || unitType === 'currency' || !unit) {
      return;
    }
    const existing = unitTypeMap.get(unitType);
    const overriddenValue = existing?.overriddenValue ?? overrideUnit; // set the first overridden value found for each unitType, skip the rest
    const utrIds = existing ? existing.utrIds.add(question._id) : new Set([question._id]);
    unitTypeMap.set(unitType, { defaultValue: unit, overriddenValue, utrIds });
  };

  const addNumberScale = (
    question: BulkActionUtr,
    numberScale: string | undefined,
    overriddenNumberScale: string | undefined
  ) => {
    if (!numberScale) {
      return;
    }
    const existing = unitTypeMap.get(UnitTypes.numberScale);
    const overriddenValue = existing?.overriddenValue ?? overriddenNumberScale;
    const utrIds = existing ? existing.utrIds.add(question._id) : new Set([question._id]);

    unitTypeMap.set(UnitTypes.numberScale, { defaultValue: numberScale, overriddenValue, utrIds });
  };

  const getInitiativeUtr = (_id: string) => questionsWithValidation.find((item) => item.universalTrackerId === _id);

  const processNumericQuestions = (question: BulkActionUtr) => {
    const { unit, unitType, numberScale, _id } = question;
    const initiativeUtr = getInitiativeUtr(_id);
    const overriddenUnit = initiativeUtr?.unitInput;
    const overriddenNumberScale = initiativeUtr?.numberScaleInput;

    addNumberScale(question, numberScale, overriddenNumberScale);
    addUnitType(question, unitType, unit, overriddenUnit);
  };

  const processTableQuestions = (question: BulkActionUtr) => {
    const overriddenTable = getInitiativeUtr(question._id)?.valueValidation?.table;
    question.valueValidation?.table?.columns.forEach((col) => {
      const overriddenColumn = overriddenTable?.columns.find((c) => c.code === col.code);
      const overriddenUnit = overriddenColumn?.unitInput;
      const overriddenNumberScale = overriddenColumn?.numberScaleInput;

      addNumberScale(question, col.numberScale, overriddenNumberScale);
      addUnitType(question, col.unitType, col.unit, overriddenUnit);
    });
  };

  selectedQuestions.forEach((question) => {
    switch (question.valueType) {
      case UtrValueType.NumericValueList:
      case UtrValueType.Number:
        processNumericQuestions(question);
        break;
      case UtrValueType.Table:
        processTableQuestions(question);
        break;
      default:
        break;
    }
  });

  return unitTypeMap;
};

export const getInitialUnitConfig = (unitTypeMap: Map<string, UnitTypeMapValue>) => {
  return Array.from(unitTypeMap).reduce((acc, [key, value]) => {
    acc[key] = value.overriddenValue ?? value.defaultValue;
    return acc;
  }, {} as Record<string, string>);
};

export const hasDataChanged = (defaultValue: Record<string, unknown>, value: Record<string, unknown> | undefined) => {
  if (!value) {
    return true;
  }
  return Object.keys(defaultValue).some((key) => defaultValue[key] !== value[key]);
};

const getOptions = (type: string): UnitOption[] => {
  if (type === UnitTypes.currency) {
    return getCurrencyList();
  }
  if (type === UnitTypes.numberScale) {
    return getUnitsForType(UnitTypes.currency);
  }
  return getUnitsForType(type);
};

export const getUnitByTypeOptions = (type: string): Option<string>[] => {
  const options = getOptions(type);
  return options.map(({ abbr, singular }) => ({ label: `${singular} (${abbr})`, value: abbr }));
};
