import { Layout } from 'react-grid-layout';
import { InsightPage, UtrVariables } from '../routes/summary/insights/utils/constants';
import { HistoricalUtrs } from '../api/insights';
import { ScorecardResponse } from './scorecard';
import { ValueStages } from '@services/aggregation/ValueStage';
import { SurveyType } from './survey';
import { MainDownloadCode } from '../config/app-config';
import { DataPeriods } from './universalTracker';
import { IntegrationData } from '@g17eco/types/integration';

export enum InsightDashboardItemType {
  Chart = 'chart',
  Headline = 'headline',
  Text = 'text',
  SDGTracker = 'sdg-tracker',
  Table = 'table',
  Media = 'media',
  SDGContributionChart = 'sdg-contribution-chart',
  Integration = 'integration',
}

export enum InsightDashboardTempItemType {
  TempChart = 'temp-chart',
}

export type InsightDashboardItemChartType =
  | InsightDashboardItemType.Chart
  | InsightDashboardItemType.SDGTracker
  | InsightDashboardItemType.Integration;

export type DashboardSourceOption = { value: InsightDashboardItemChartType; label: string };

export type InsightDashboardItemTextType = InsightDashboardItemType.Headline | InsightDashboardItemType.Text;

export enum CalculationType {
  Sum = 'sum',
  Percentage = 'percentage',
  Formula = 'formula',
  Stages = 'stages',
}

export enum ValueRole {
  Annotation = 'annotation',
  Style = 'style',
}

export enum TableWidgetColumnType {
  Name = 'name',
  Unit = 'unit',
  Sparkline = 'sparkline',
}

type Tooltip = { formula: string };
export interface CalculationValue {
  name: string;
  formula?: string;
  role?: ValueRole;
  stages?: ValueStages;
  decimalPlaces?: number;
  suffix?: string;
  options?: { style: string; tooltip: Tooltip };
}

export interface Calculation {
  type: CalculationType;
  values: CalculationValue[];
  headers?: ({ name: string } | { role: ValueRole })[];
}

export interface Note {
  prefix?: string;
  value?: string;
  valueUnit?: string;
  postfix?: string;
}

interface DashboardItemConfig {
  hiddenColumns?: string[];
}

export type GridDashboardItemBase =
  | GridDashboardTextItemBase
  | GridDashboardTableItemBase
  | GridDashboardChartItemBase
  | GridDashboardSDGChartItemBase
  | GridDashboardSDGContributionItemBase
  | GridDashboardIntegrationItemBase
  | GridDashboardMediaItemBase;

export type GridDashboardItem =
  | GridDashboardTextItem
  | GridDashboardTableItem
  | GridDashboardChartItem
  | GridDashboardSDGChartItem
  | GridDashboardIntegrationItem
  | GridDashboardMediaItem
  | GridDashboardSDGContributionChartItem;

export type GridDashboardItemCreate = GridDashboardItemBase | GridDashboardItem;

export interface GridDashboardTempItem {
  type: InsightDashboardTempItemType;
}

// Temp chart means it's not yet become SDG tracker or chart
export interface GridDashboardTempChartItem extends GridDashboardTempItem {
  type: InsightDashboardTempItemType.TempChart;
}

export interface ChartDataLoaded<T = unknown> {
  chartData: T[][];
  effectiveDate?: string;
  period?: string;
}

export type DashboardGridSize = Pick<Layout, 'x' | 'y' | 'w' | 'h' | 'minW' | 'minH' | 'maxW' | 'maxH'>;

type CreatingItem<T extends GridDashboardItem = GridDashboardItem> = Partial<Omit<T, '_id'>> &
  Required<Pick<T, 'type'>>;

export interface GridDashboardTextItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Text | InsightDashboardItemType.Headline;
  subType?: TextSubType;
  text?: string;
  variables?: UtrVariables;
}

export type GridDashboardTextItemBase = CreatingItem<GridDashboardTextItem>;

export interface GridDashboardTableItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Table;
  subType: TableSubType;
  variables: UtrVariables;
  calculation: Calculation;
  title?: string;
  config?: DashboardItemConfig;
}

export type GridDashboardTableItemBase = CreatingItem<GridDashboardTableItem>;

export interface GridDashboardChartItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Chart;
  subType: ChartSubType;
  variables: UtrVariables;
  calculation?: Calculation;
  icon?: string;
  title?: string;
  note?: Note;
  unitText?: string;
}

export type GridDashboardChartItemBase = CreatingItem<GridDashboardChartItem>;

export interface GridDashboardSDGChartItem extends Omit<GridDashboardChartItem, 'type'> {
  type: InsightDashboardItemType.SDGTracker;
}

export type GridDashboardSDGChartItemBase = CreatingItem<GridDashboardSDGChartItem>;

export interface GridDashboardIntegrationItem extends Omit<GridDashboardChartItem, 'type'> {
  type: InsightDashboardItemType.Integration;
}
export type GridDashboardIntegrationItemBase = CreatingItem<GridDashboardIntegrationItem>;

export interface UploadedMediaFile {
  type: string;
  url: string;
  name: string;
  documentId: string;
  ratio?: number;
}

export type ToUploadMediaFile = Omit<UploadedMediaFile, 'documentId'> & { originalFile: File };

export type MediaFile = UploadedMediaFile | ToUploadMediaFile;

export interface MediaData {
  title: string;
  files: MediaFile[];
}

export interface GridDashboardMediaItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Media;
  title?: string;
  files?: MediaFile[];
}

export type GridDashboardMediaItemBase = CreatingItem<GridDashboardMediaItem>;

export interface GridDashboardSDGContributionChartItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.SDGContributionChart;
}

export type GridDashboardSDGContributionItemBase = CreatingItem<GridDashboardSDGContributionChartItem>;

export type OverviewDashboardItem = GridDashboardItem & {
  isDefault?: boolean;
  note?: Note;
  subType?: SubType;
};

export enum UtrvFilter {
  Verified = 'verified',
  Assured = 'assured',
}

export enum SurveyFilter {
  All = 'all',
  Completed = 'completed',
}

export enum PrivacyFilter {
  All = 'all',
  Public = 'public',
}

export enum TimeFrameType {
  TwelveMonths = '12-months',
  SixMonths = '6-months',
  ThreeMonths = '3-months',
  OneMonth = '1-month',
  AllTime = 'all-time',
  Custom = 'custom',
}

export interface TimeFrameFilter {
  type: TimeFrameType;
  startDate?: Date;
  endDate?: Date;
}

type InsightDashboardFilterKeys = keyof InsightDashboard['filters'];
export type InsightDashboardSettingKeys = InsightDashboardFilterKeys | 'title';

export interface ToggleFilter {
  enabled: boolean;
}

export interface SharingItem {
  enabled: boolean;
  token: string;
}

export interface InsightDashboard<ITEM = GridDashboardItem> {
  _id: string;
  initiativeId: string;
  title: string;
  filters: {
    utrv: UtrvFilter;
    survey: SurveyFilter;
    privacy: PrivacyFilter;
    timeFrame?: TimeFrameFilter;
    surveyType?: DashboardSurveyType;
    period?: DataPeriods;
    initiativeInfo?: ToggleFilter;
    sdgContribution?: ToggleFilter;
    baselinesTargets?: ToggleFilter;
    shareWithSubsidiaries?: ToggleFilter;
  };
  items: ITEM[];
  utrsData: HistoricalUtrs[];
  scorecard?: ScorecardResponse;
  integrationsData?: IntegrationData;
  share?: SharingItem[];
  type: InsightDashboardType;
}

export type OverviewDashboard = InsightDashboard<OverviewDashboardItem>;

export enum ChartSubType {
  Line = 'line',
  SingleValue = 'singleValue',
  SparkLine = 'sparkLine',
  Pie = 'pie', // donut
  FullPie = 'fullPie',
  PieWithLegend = 'pieWithLegend',
  Column = 'column',
  Bar = 'bar',
  Table = 'table',
  Stacked = 'stacked',
}

export enum TableSubType {
  Table = 'table',
  DoubleTable = 'doubleTable',
}

export enum TextSubType {
  Free = 'free',
  Metric = 'metric',
}

// extendable for future types
export type SubType = ChartSubType | TableSubType | TextSubType;

export enum InsightDashboardType {
  Custom = 'custom',
  Overview = 'overview',
  Environmental = 'environmental',
  Social = 'social',
  Governance = 'governance',
  SgxEnvironmental = 'sgx_environmental',
  SgxSocial = 'sgx_social',
  SgxGovernance = 'sgx_governance',
}

export const SummaryPages = [
  InsightDashboardType.Overview,
  InsightDashboardType.Environmental,
  InsightDashboardType.Social,
  InsightDashboardType.Governance,
  InsightDashboardType.SgxEnvironmental,
  InsightDashboardType.SgxSocial,
  InsightDashboardType.SgxGovernance,
];

export const getDashboardType = (type: InsightPage, mainCode: string): InsightDashboardType => {
  if (mainCode === MainDownloadCode.SGX_Metrics) {
    switch (type) {
      case InsightPage.Social:
        return InsightDashboardType.SgxSocial;
      case InsightPage.Governance:
        return InsightDashboardType.SgxGovernance;
      case InsightPage.Environmental:
        return InsightDashboardType.SgxEnvironmental;
    }
  }

  switch (type) {
    case InsightPage.Social:
      return InsightDashboardType.Social;
    case InsightPage.Governance:
      return InsightDashboardType.Governance;
    case InsightPage.Environmental:
      return InsightDashboardType.Environmental;
    default:
      return InsightDashboardType.Overview;
  }
};

export const InsightDashboardTitle = 'title';

export enum InsightDashboardFilters {
  Utrv = 'utrv',
  Survey = 'survey',
  Privacy = 'privacy',
  TimeFrame = 'timeFrame',
  SurveyType = 'surveyType',
  Period = 'period',
  InitiativeInfo = 'initiativeInfo',
  SdgContribution = 'sdgContribution',
  BaselinesTargets = 'baselinesTargets',
  ShareWithSubsidiaries = 'shareWithSubsidiaries',
}

export type DashboardSurveyType = SurveyType.Default | SurveyType.Aggregation;
