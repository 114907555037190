import { ValueStages } from '@services/aggregation/ValueStage';
import { UniversalTrackerPlain } from './universalTracker';
import { GeneratedUtr, IntegrationProvider } from './integration';

export enum CalculationType {
  Direct = 'direct', // 'a'
  Formula = 'formula', // {a}
  Stages = 'stages', // JSONata
}
export interface VariableInfo {
  utrCode: string;
  valueListCode?: string;
  /**
   * Integration service provider code
   */
  integrationCode?: string;
}

interface CalculationBase {
  // Common properties for all types
  _id: string;
  name: string;
  description?: string;
  variables: Record<string, VariableInfo>;
  data?: number | undefined;

  type: CalculationType;
}

interface DirectCalculation extends CalculationBase {
  type: CalculationType.Direct;
  direct: string;
}

interface FormulaCalculation extends CalculationBase {
  type: CalculationType.Formula;
  formula: string;
}

interface StagesCalculation extends CalculationBase {
  type: CalculationType.Stages;
  stages: ValueStages;
}

export type Calculation = DirectCalculation | FormulaCalculation | StagesCalculation;

interface CalculationGroup {
  _id: string;
  /** internal name like "Number of female employees" */
  name: string;
  /** Explain where it can be used */
  description?: string;

  calculations: Calculation[];
}

export interface UniversalTrackerConnection {
  _id: string;
  utrCode: string;
  // If targeting column
  valueListCode?: string;

  calculationGroups: CalculationGroup[];
}

export type CalculationUtr = Pick<UniversalTrackerPlain, '_id' | 'code' | 'valueLabel' | 'valueValidation' | 'type'>;
export type CalculationIntegrationUtr = GeneratedUtr & {
  integrationCode: string;
  provider?: Pick<IntegrationProvider, 'shortName' | 'logo'>;
};
