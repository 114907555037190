import UniversalTracker, { UniversalTrackerBase } from '../../model/UniversalTracker';
import { DecimalInputType, InitiativeUniversalTracker } from '../../types/initiativeUniversalTracker';
import { UtrValueType, ValueValidation } from '../../types/universalTracker';
import { isDefined } from '../../utils';
import { hasNumericColumnType, isNumericTableColumnType, isNumericValueType } from '../../utils/universalTracker';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { TableColumn } from '../survey/form/input/table/InputInterface';

export const DECIMAL_MIN = 0;
export const DECIMAL_MAX = 5;

export const invalidInputMessage = `Enter a value between ${DECIMAL_MIN}-${DECIMAL_MAX}`;

export interface DecimalType {
  [key: string]: number | undefined;
}

export interface ErrorDecimalType {
  [key: string]: string | undefined;
}

export const isSingleTableQuestion = (questions: BulkActionUtr[]) => {
  return questions.length === 1 && questions[0].valueType === UtrValueType.Table;
};

export const getInputType = (numericQuestions: BulkActionUtr[]) => {
  if (!numericQuestions.length) {
    return '' as DecimalInputType;
  }
  return isSingleTableQuestion(numericQuestions) ? DecimalInputType.Table : DecimalInputType.SingleInput;
};

export const getInitialState = ({
  inputType,
  tableColumns,
  valueValidation,
}: {
  inputType: DecimalInputType;
  tableColumns: TableColumn[];
  valueValidation: ValueValidation | undefined;
}) => {
  switch (inputType) {
    case DecimalInputType.Table:
      return Object.fromEntries(
        tableColumns.map((k) => [
          [k.code],
          valueValidation?.table?.columns?.find((c) => c.code === k.code)?.validation?.decimal,
        ])
      );
    case DecimalInputType.SingleInput:
    default:
      return {
        value:
          valueValidation?.decimal ??
          valueValidation?.table?.columns?.find((c) => isNumericTableColumnType(c.type))?.validation?.decimal,
      };
  }
};

export const isValidUpdateDecimal = ({
  decimal,
  error,
  isEnforced,
}: {
  decimal: DecimalType;
  error: ErrorDecimalType;
  isEnforced: boolean;
}) => {
  const hasError = Object.values(error).every((err) => !err);
  const isValidDecimal = isEnforced ? Object.keys(decimal).some((k) => isDefined(decimal[k])) : true;

  return hasError && isValidDecimal;
};

export const isDecimalMultipleUpdate = ({
  questionsWithValidation,
  selectedQuestions,
}: {
  questionsWithValidation: InitiativeUniversalTracker[];
  selectedQuestions: BulkActionUtr[];
}) => {
  const hasMultipleUtrDecimal = questionsWithValidation.length > 1;
  const hasAtLeastOneUtrDecimal = selectedQuestions.length > 1 && questionsWithValidation.length === 1;

  return hasMultipleUtrDecimal || hasAtLeastOneUtrDecimal;
};

export const convertValueByDecimal = ({
  value,
  decimal,
}: {
  decimal: number | undefined;
  value: string | number | undefined;
}) => {
  if (!value || !decimal) {
    return value;
  }

  return Number(value).toFixed(decimal);
};

export const getUtrDecimal = (
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'> | UniversalTrackerBase | undefined,
  inputName?: string
) => {
  if (!utr) {
    return;
  }
  const valueType: UtrValueType = 'getValueType' in utr ? utr.getValueType() : (utr.valueType as UtrValueType);

  const valueValidation: ValueValidation | undefined =
    'getValueValidation' in utr ? utr.getValueValidation() : utr.valueValidation;

  if (!valueValidation) {
    return;
  }

  if (isNumericValueType(valueType)) {
    return valueValidation?.decimal;
  }

  if (hasNumericColumnType({ valueValidation })) {
    return getTableColumnDecimal(valueValidation, inputName);
  }

  return;
};

const getTableColumnDecimal = (valueValidation: ValueValidation, inputName?: string) => {
  const tableColumn = valueValidation.table?.columns.find((col: TableColumn) => col.code === inputName);

  if (!tableColumn || !isNumericTableColumnType(tableColumn.type) || tableColumn.calculation) {
    return;
  }

  return tableColumn?.validation?.decimal;
};
