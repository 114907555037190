import { useMemo } from 'react';
import { Input } from 'reactstrap';
import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { SelectFactory, SelectTypes, Toggle } from '@g17eco/molecules';
import { noTagOption } from '@components/survey/utils/filters';
import { getFrameworksOptions, getStandardsOptions } from '@constants/standards-frameworks';
import { useGetCustomTagsQuery } from '@api/metric-groups';
import Loader from '@components/loader';
import { BasicAlert } from '@components/alert/BasicAlert';
import { ViewType } from '@api/admin-dashboard';

export enum UnitOption {
  Default = 'default',
  Override = 'override',
  DefaultAndOverride = 'default-override',
}

export interface QuestionFilters {
  packs: string[];
  tags: string[];
  unit: UnitOption;
}

export interface QuestionFilterProps {
  viewType: ViewType;
  toggleViewType: (viewType: ViewType) => void;
  showFilters?: boolean;
  filters: QuestionFilters;
  handleQuestionFilter: (props: { name: string; value: string[] | UnitOption | boolean }) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rootInitiativeId: string;
  canAccessRootOrg: boolean;
}

const unitOptions = [
  {
    label: 'Display default and overrides',
    value: UnitOption.DefaultAndOverride,
  },
  {
    label: 'Display default units only',
    value: UnitOption.Default,
  },
  {
    label: 'Display unit overrides only',
    value: UnitOption.Override,
  },
];

export const QuestionFilter = ({
  showFilters = false,
  viewType,
  toggleViewType,
  filters,
  rootInitiativeId,
  handleQuestionFilter,
  onSearch,
  canAccessRootOrg,
}: QuestionFilterProps) => {
  const {
    data: tags = [],
    error,
    isFetching,
  } = useGetCustomTagsQuery(rootInitiativeId, { skip: !rootInitiativeId || !canAccessRootOrg });

  const showInScopeOnly = viewType === ViewType.InScope;

  const standardsOptions = getStandardsOptions();
  const frameworksOptions = getFrameworksOptions();
  const tagOptions = useMemo(() => {
    const options = [noTagOption];
    tags.forEach((tag) => {
      options.push({ value: tag._id, searchString: tag.groupName, label: tag.groupName });
    });
    return options;
  }, [tags]);

  return (
    <div className='d-flex align-items-center mb-3'>
      {isFetching ? <Loader /> : null}
      {error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null}
      {showFilters ? (
        <div className='w-100'>
          <div className='d-flex align-items-center justify-content-between'>
            <Input
              type='text'
              placeholder={`Search for ${QUESTION.SINGULAR}`}
              className='border-ThemeBorderDefault'
              onChange={onSearch}
            />
            <div style={{ minWidth: 'fit-content' }}>
              <Toggle
                className={{ form: 'd-flex align-items-center' }}
                checked={viewType === ViewType.Blueprint}
                onChange={() => toggleViewType(showInScopeOnly ? ViewType.Blueprint : ViewType.InScope)}
                label={`Show out of scope ${QUESTION.SINGULAR}`}
              />
            </div>
          </div>
          <div className='mt-3 d-flex align-items-center gap-3'>
            <div>Filters:</div>
            <SelectFactory
              selectType={SelectTypes.MultipleSelect}
              placeholder={`Select ${SURVEY.ADJECTIVE} ${PACK.SINGULAR}`}
              options={[...standardsOptions, ...frameworksOptions]}
              onChange={(options: string[]) => handleQuestionFilter({ name: 'packs', value: options })}
              values={filters.packs}
              className='w-100'
            />
            <SelectFactory
              selectType={SelectTypes.MultipleSelect}
              placeholder='Select tag'
              options={tagOptions}
              onChange={(options: string[]) => handleQuestionFilter({ name: 'tags', value: options })}
              values={filters.tags}
              className='w-100'
            />
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isClearable={false}
              options={unitOptions}
              onChange={(op) => (op ? handleQuestionFilter({ name: 'unit', value: op.value }) : undefined)}
              value={unitOptions.find((op) => filters.unit === op.value)}
              className='w-100'
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
