import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { DataPeriods } from '../../../types/universalTracker';
import { getPeriodOptions } from '@utils/date';
import { useIndustrySector } from '../../industry-sector-selector/useIndustrySector';
import { Option, SelectFactory, SelectTypes, TimeRangeSelector } from '@g17eco/molecules';
import { DateRangeType } from '@g17eco/types/common';
import SimpleTooltip from '../../simple-tooltip';
import IconButton from '../../button/IconButton';

export interface Filters {
  sector?: string;
  industry?: string | null;
  marketCap?: string;
  reportType?: DataPeriods;
}

interface Props {
  searchText: string;
  filters: Filters;
  dateRange: DateRangeType;
  changeSearchText: (searchText: string) => void;
  changeFilters: (filters: Filters) => void;
  changeDateRange: (dateRange: DateRangeType) => void;
  toggleCustomiseColumns: () => void;
  handleDownload: () => void;
}

export const ExchangeFilters = (props: Props) => {
  const {
    searchText,
    filters,
    dateRange,
    changeSearchText,
    changeFilters,
    changeDateRange,
    toggleCustomiseColumns,
    handleDownload,
  } = props;
  const [openFilters, setOpenFilters] = useState(false);
  const toggleFilters = () => setOpenFilters((prev) => !prev);
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);
  const reportTypeOptions = getPeriodOptions();

  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    changeSearchText(value);
  };

  const handleChangeFilters = (name: string, option: Option | null) => {
    const newFilters = { ...filters, [name]: option?.value };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: Option | null) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option?.value ? option?.label : null };

    changeFilters(newFilters);
  };

  return (
    <div className='exchange-filters-container'>
      <div className='d-flex justify-content-between align-items-center align-items-stretch' style={{ height: '36px' }}>
        <div>
          <InputGroup>
            <Input placeholder={'Search for a company name'} onChange={handleChangeSearchText} value={searchText} />
            <InputGroupText>
              <i className='fa fa-light fa-magnifying-glass'></i>
            </InputGroupText>
          </InputGroup>
        </div>
        <div className='d-flex align-items-center px-3 ml-2 toggle-filters' onClick={() => toggleFilters()}>
          <span>Filters</span>
          <i className={classNames('fa-light ml-2', { 'fa-angle-down': !openFilters, 'fa-angle-up': openFilters })} />
        </div>
        <div className='d-flex align-items-center ml-auto'>
          <TimeRangeSelector dateRange={dateRange} onChangeDateRange={(range) => changeDateRange(range)} />
          <Button
            type='button'
            outline
            className='px-2 btn-customise text-sm ml-2'
            onClick={() => toggleCustomiseColumns()}
          >
            Customise
          </Button>
          <IconButton
            onClick={handleDownload}
            outline={false}
            color='transparent'
            className='text-ThemeAccentDark'
            icon='fal fa-file-excel'
          />
        </div>
      </div>
      {openFilters ? (
        <div className='d-flex justify-content-between align-items-center gap-3 filters-wrapper'>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            placeholder='Sector'
            isClearable
            options={sectorOptions}
            value={sectorOptions.find((item) => item.label === filters.sector) ?? null}
            onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
          />
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            placeholder='Industry'
            isClearable
            options={industryOptions}
            value={industryOptions.find((item) => item.label === filters.industry) ?? null}
            onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
            isDisabled={!filters.sector}
          />
          <div className='flex-grow-1'>
            <SimpleTooltip text='Filter currently unavailable'>
              <SelectFactory
                selectType={SelectTypes.SingleSelect}
                placeholder='Market cap'
                options={[]}
                onChange={(option) => handleChangeFilters('marketCap', option)}
                isDisabled
              />
            </SimpleTooltip>
          </div>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            placeholder='Report type'
            isClearable
            options={reportTypeOptions}
            value={undefined}
            onChange={(option) => handleChangeFilters('reportType', option)}
          />
        </div>
      ) : null}
    </div>
  );
};
