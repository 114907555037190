import {
  useCreateInsightDashboardItemMutation,
  useGetInsightDashboardsByInitiativeQuery,
} from '@api/insight-dashboards';
import { DashboardRow } from '@components/dashboard';
import Loader from '@components/loader';
import {
  GridDashboardItemCreate,
  InsightDashboard,
  InsightDashboardItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { useSiteAlert } from '@hooks/useSiteAlert';
import UniversalTracker from '@models/UniversalTracker';
import { getGridSize } from '@routes/custom-dashboard/utils';
import { InsightDashboardOption } from '@routes/summary/insights/utils/sidebar';
import { Button } from 'reactstrap';
import { SiteAlertColors } from '../../../../slice/siteAlertsSlice';

interface AddToDashboardDropdownProps {
  initiativeId?: string;
  alternativeCode?: string;
  selectedColumnCode?: string;
  utr: UniversalTracker;
}

export const AddToDashboard = ({
  initiativeId = '',
  alternativeCode,
  selectedColumnCode,
  utr,
}: AddToDashboardDropdownProps) => {
  const { data: dashboards, isFetching } = useGetInsightDashboardsByInitiativeQuery(initiativeId, {
    skip: !initiativeId,
  });
  const [createInsightDashboardItem] = useCreateInsightDashboardItemMutation();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
  }));

  const onAddItem = async (dashboard: InsightDashboard, page: string) => {
    if (!initiativeId) {
      return;
    }
    const item: GridDashboardItemCreate = {
      type: InsightDashboardItemType.Chart,
      title: utr.getName(),
    };
    return createInsightDashboardItem({
      ...item,
      initiativeId,
      dashboardId: dashboard._id,
      utrVariables: [{ code: utr.getCode(), valueListCode: selectedColumnCode, groupCode: alternativeCode }],
      gridSize: getGridSize(item, dashboard.items),
    })
      .then(() => {
        addSiteAlert({
          content: `Chart added to ${page}`,
          timeout: 2000,
          color: SiteAlertColors.Success,
        });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const onAddChart = (option: InsightDashboardOption) => {
    const dashboard = (dashboards || []).find(({ _id }) => _id === option.value);

    if (!dashboard) {
      return;
    }

    onAddItem(dashboard, option.label);
  };

  return isFetching ? (
    <Loader />
  ) : (
    <>
      <DashboardRow mb={0}>
        <div className='text-strong ms-1'>Select dashboard</div>
      </DashboardRow>
      <DashboardRow>
        <div className='d-flex flex-column'>
          {dashboardOptions.map((option, i) => (
            <Button
              key={`op-${i}}`}
              className='py-1 text-left'
              color='link-secondary'
              onClick={() => onAddChart(option)}
            >
              <i className='fal fa-plus text-lg me-2'></i>
              {option.label}
            </Button>
          ))}
        </div>
      </DashboardRow>
    </>
  );
};
