import { TagContainer, TagContainerProps } from '@components/tags/TagContainer';
import { MetricOverrideContainer, MetricOverrideContainerProps } from '@features/question-configuration';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getDescription, getModalTitle, Tabs } from '../utils';
import SimpleTooltip from '@components/simple-tooltip';

type TabProps = {
  tabs: { code: Tabs; disabled?: boolean; tooltip?: string }[];
  activeTab: Tabs | undefined;
  setActiveTab: (tab: Tabs | undefined) => void;
};

export type QuestionConfigurationModalProps = TagContainerProps & MetricOverrideContainerProps;

type Props = QuestionConfigurationModalProps & TabProps;

export const QuestionConfigurationModal = (props: Props) => {
  const { tabs, activeTab, setActiveTab, handleReload: onReload, ...modalContentProps } = props;

  if (!activeTab) {
    return null;
  }

  const onToggle = () => {
    setActiveTab(undefined);
  };

  const handleReload = async (props?: { reloadSurvey?: boolean }) => {
    onToggle();
    onReload(props);
  };

  const CurrentTabContent = () => {
    if (!activeTab) {
      return null;
    }
    switch (activeTab) {
      case Tabs.MetricOverrides:
        return <MetricOverrideContainer {...modalContentProps} handleReload={handleReload} />;
      case Tabs.Tags:
        return <TagContainer {...modalContentProps} handleReload={handleReload} />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={!!activeTab} toggle={onToggle} backdrop='static'>
      <ModalHeader toggle={onToggle}>{getModalTitle(modalContentProps.selectedQuestions)}</ModalHeader>
      <ModalBody className='pb-0'>
        <div className='mb-3 d-flex justify-content-between align-items-center gap-2'>
          {tabs.map(({ code, tooltip, disabled }) => (
            <SimpleTooltip key={code} className='w-100' text={tooltip}>
              <Button
                className='w-100'
                color='primary'
                outline={activeTab !== code}
                onClick={() => setActiveTab(code)}
                active={activeTab === code}
                disabled={disabled}
              >
                {code}
              </Button>
            </SimpleTooltip>
          ))}
        </div>
        {getDescription(activeTab)}
      </ModalBody>
      <CurrentTabContent />
    </Modal>
  );
};
