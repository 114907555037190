import SimpleTooltip from '@components/simple-tooltip';
import { EditorTemplate } from '../EditorTemplate';
import { Input } from 'reactstrap';
import { useState } from 'react';
import { DashboardDivider } from '@g17eco/atoms';
import { FileDropZone } from '@components/files/FileDropZone';
import { MediaEditorProps, MediaItem } from '../../types';
import { InsightDashboardItemType, MediaData, MediaFile } from '@g17eco/types/insight-custom-dashboard';
import { Preview } from './Preview';
import { DropZonePlaceholder } from './DropZonePlaceholder';
import { MAX_SIZE_MB, cloneFile, getFileRatio } from '@features/custom-dashboard/utils/media-utils';
import { FileRejection } from 'react-dropzone';
import { BasicAlert } from '@components/alert/BasicAlert';

const MAX_LENGTH_CHART_TITLE = 50;
const TITLE_TOOLTIP = 'Title will be shown under the media';
const ACCEPT = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/gif': ['.gif'],
  'video/mp4': ['.mp4'],
};
const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024; // 50 MB.

const getMediaData = ({ title = '', files = [] }: MediaItem) => ({ title, files });

export const MediaEditor = ({ handleCancel, updateItem, editingItem }: MediaEditorProps) => {
  const [media, setMedia] = useState<MediaData>(getMediaData(editingItem));
  const [error, setError] = useState<string | undefined>();

  const handleChangeMedia = (newMediaData: Partial<MediaData>) => {
    setMedia((mediaData) => ({ ...mediaData, ...newMediaData }));
  };
  const isDisabled = !media.files.length;

  const handleSubmit = async () => {
    updateItem({ ...editingItem, ...media, type: InsightDashboardItemType.Media });
  };
  const handleFilesAdded = async (files: File[], fileRejections: FileRejection[]) => {
    const mediaFiles: MediaFile[] = await Promise.all(
      files.map(async (file) => {
        // We want to use local object here because user can cancel the editing.
        // We only upload the file later once user saves the dashboard.
        const url = URL.createObjectURL(file);
        const type = file.type;
        const normalizedName = file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return {
          name: normalizedName,
          url,
          type,
          ratio: await getFileRatio({ url, type }),
          originalFile: cloneFile(file, normalizedName),
        };
      })
    );
    handleChangeMedia({ files: mediaFiles });

    const errors = fileRejections.map(({ errors }) => errors.map(({ message }) => message)).flat();
    setError(errors.join(', '));
  };

  const handleClickDelete = (file: MediaFile) => {
    handleChangeMedia({ files: media.files.filter((f) => f !== file) });

    // Release memory. https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static#memory_management
    URL.revokeObjectURL(file.url);
  };

  return (
    <EditorTemplate isDisabled={isDisabled} handleCancel={handleCancel} handleSubmit={handleSubmit}>
      <p className='mt-3 text-medium'>
        Title (optional)
        <SimpleTooltip text={TITLE_TOOLTIP} className='text-ThemeIconSecondary'>
          <i className='fal fa-info-circle ml-2' />
        </SimpleTooltip>
      </p>
      <Input
        type='text'
        className='text-md'
        maxLength={MAX_LENGTH_CHART_TITLE}
        value={media.title}
        onChange={(e) => handleChangeMedia({ title: e.target.value })}
        placeholder='Enter title'
      />
      <DashboardDivider className='mt-4 mb-4' />
      <p className='mt-3 text-medium'>Upload media</p>
      {media.files.length ? (
        <Preview files={media.files} handleClickDelete={handleClickDelete} />
      ) : (
        <FileDropZone multiple={false} maxSize={MAX_SIZE} accept={ACCEPT} onDrop={handleFilesAdded}>
          <DropZonePlaceholder />
        </FileDropZone>
      )}
      {error ? (
        <BasicAlert type='danger' className='mt-2 text-break'>
          {error}
        </BasicAlert>
      ) : null}
    </EditorTemplate>
  );
};
