import { useDispatch } from 'react-redux';
import { loadUniversalTrackerModal } from '../../../actions/universalTrackerModal';
import IconButton from '../../button/IconButton';
import SimpleTooltip from '../../simple-tooltip';
import { CustomMetric } from '@api/custom-metrics';
import { AccessType } from '@g17eco/types/metricGroup';
import { SURVEY } from '@constants/terminology';

enum ButtonType {
  Edit = 'edit',
  ViewDetails = 'view-details',
  DataHistory = 'data-history',
  Delete = 'delete',
  InheritedTooltip = 'inherited-tooltip',
}

interface MenuButtonProps {
  universalTracker: CustomMetric;
  handleOpenEditUtr: (utrId: string) => void;
  handleReadOnlyUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utr: CustomMetric) => void;
  isDisabled: boolean;
}

const getTooltip = (tooltip: string, isDisabled: boolean) => {
  return isDisabled ? `Custom metrics that have been added to ${SURVEY.PLURAL} cannot be deleted` : tooltip;
};

export const CTAButtons = ({
  universalTracker,
  handleOpenEditUtr,
  handleReadOnlyUtr,
  handleOpenDeleteUtr,
  isDisabled,
}: MenuButtonProps) => {
  const dispatch = useDispatch();

  const utrId = universalTracker._id;
  const buttons = [
    {
      key: ButtonType.Edit,
      tooltip: 'Edit details',
      button: (
        <IconButton
          icon='fal fa-pen-to-square'
          color='transparent'
          size='xs'
          className='icon-button--size-xs'
          outline={false}
          onClick={() => handleOpenEditUtr(utrId)}
        />
      ),
    },
    {
      key: ButtonType.ViewDetails,
      tooltip: 'View details',
      button: (
        <IconButton
          icon='fal fa-eye'
          color='transparent'
          size='xs'
          className='icon-button--size-xs'
          outline={false}
          onClick={() => handleReadOnlyUtr(utrId)}
        />
      ),
    },
    {
      key: ButtonType.DataHistory,
      tooltip: 'Open data history',
      button: (
        <IconButton
          icon='fal fa-clock-rotate-left'
          color='transparent'
          size='xs'
          className='icon-button--size-xs'
          outline={false}
          onClick={() => dispatch(loadUniversalTrackerModal({ universalTrackerId: utrId }))}
        />
      ),
    },
    {
      key: ButtonType.Delete,
      tooltip: getTooltip('Delete metric', isDisabled),
      button: (
        <IconButton
          icon='fal fa-trash-can'
          color='danger'
          className='icon-button--size-xs'
          size='xs'
          onClick={() => handleOpenDeleteUtr(universalTracker)}
          disabled={isDisabled}
          outline={!isDisabled}
        />
      ),
    },
    {
      key: ButtonType.InheritedTooltip,
      tooltip: 'This metric has been inherited and cannot be deleted or edited',
      button: (
        <IconButton
          icon='fal fa-circle-info fs-6'
          color='transparent'
          size='xs'
          outline={false}
          disabled
        />
      ),
    },
  ];

  const renderButtons = () => {
    switch (universalTracker.accessType) {
      case AccessType.Custom:
        return buttons.filter((button) =>
          [ButtonType.Edit, ButtonType.DataHistory, ButtonType.Delete].includes(button.key)
        );
      case AccessType.Inherited:
        return buttons.filter((button) =>
          [ButtonType.ViewDetails, ButtonType.DataHistory, ButtonType.InheritedTooltip].includes(button.key)
        );
      default:
        return [];
    }
  };

  return (
    <div className='d-flex align-items-center gap-2 pr-3'>
      {renderButtons().map((item) => (
        <SimpleTooltip key={item.key} text={item.tooltip}>
          {item.button}
        </SimpleTooltip>
      ))}
    </div>
  );
};
