import { SelectedGroup } from '@components/downloads/util/downloadReportHandler';
import { Action } from '@constants/action';
import { frameworks, getGroup, getGroupChildrenTags, Group, GroupTypes, sdgGroupFull, standards } from '@g17eco/core';
import { MetricGroup } from '@g17eco/types/metricGroup';
import { Scope, ScopeGroups } from '@models/surveyData';

export const getDefaultScope = (): Scope => ({
  [ScopeGroups.Sdg]: [],
  [ScopeGroups.Materiality]: [],
  [ScopeGroups.Standards]: [],
  [ScopeGroups.Frameworks]: [],
  [ScopeGroups.Custom]: [],
});

export const applyScopeAction = <T = string>(scopeTypeData: T[] = [], updateData: T[], action: Action): T[] => {
  if (action === Action.Remove) {
    const removeCodes = new Set(updateData.map(String));
    return scopeTypeData.filter((code) => !removeCodes.has(String(code)));
  }

  const addCodes = new Set(scopeTypeData.map(String));
  updateData.forEach((code) => {
    if (!addCodes.has(String(code))) {
      scopeTypeData.push(code);
    }
  });

  return scopeTypeData;
};

export const mergeSurveysScopes = (
  surveysToAggregate: { scope?: Scope & { toObject?: () => Scope } }[],
  options?: { parentGroupsOnly?: boolean },
  initialScope: Scope = getDefaultScope()
): Scope => {
  return surveysToAggregate.reduce((a, c) => {
    if (!c.scope) {
      return a;
    }

    const plainScope = c.scope.toObject ? c.scope.toObject() : c.scope;
    Object.entries(plainScope).forEach((data) => {
      if (data[0] === 'custom') {
        a.custom = applyScopeAction(a.custom, data[1], Action.Add);
        return a;
      }

      const [key, scopeValue] = data as [Exclude<keyof Scope, 'custom'>, string[]];
      const groupsCodes = options?.parentGroupsOnly ? scopeValue.filter((code) => getGroup(key, code)) : scopeValue;
      a[key] = applyScopeAction(a[key], groupsCodes, Action.Add);
    });

    return a;
  }, initialScope);
};

export const SCOPES = [ScopeGroups.Standards, ScopeGroups.Frameworks, ScopeGroups.Custom, ScopeGroups.Sdg];

export const getParentGroup = (
  groupType: GroupTypes,
  code: string,
  type: ScopeGroups,
  allParents: Group[],
  getDisplayName?: (group: Group) => string
) => {
  const group = getGroup(groupType, code);
  if (group) {
    return {
      code: group.code,
      name: getDisplayName?.(group) ?? group.shortName ?? group.name,
      checked: true,
      type,
    };
  }
  const parent = allParents.find((group) => getGroupChildrenTags(group).includes(code));
  if (parent) {
    return {
      code: parent.code,
      name: getDisplayName?.(parent) ?? parent.shortName ?? parent.name,
      checked: true,
      type,
    };
  }
  return undefined;
};

export const getSDGName = (group: Group) => `Goal ${group.code} ${group.shortName ?? group.name}`;

const allParents = [...Object.values(standards), ...Object.values(frameworks)];

const getSurveyScopeInfo = ({
  codes,
  type,
  metricGroups,
}: {
  codes: string[];
  type: ScopeGroups;
  metricGroups: Pick<MetricGroup, '_id' | 'groupName' | 'universalTrackers'>[];
}): SelectedGroup[] => {
  if (type === ScopeGroups.Custom) {
    return metricGroups
      .filter((metricGroup) => codes.includes(metricGroup._id))
      .map((metricGroup) => {
        return {
          code: metricGroup._id,
          name: metricGroup.groupName,
          universalTrackers: metricGroup.universalTrackers,
          type,
          checked: true,
        };
      });
  }
  if (type === ScopeGroups.Sdg) {
    const sdgGroupsMap = codes.reduce((acc, code) => {
      const group = getParentGroup('sdg', code, type, sdgGroupFull, getSDGName);
      if (group) {
        acc.set(group.code, group);
      }
      return acc;
    }, new Map<string, SelectedGroup>());
    return Array.from(sdgGroupsMap.values());
  }
  const groupsMap = codes.reduce((acc, code) => {
    const group = getParentGroup('standards-and-frameworks', code, type, allParents);
    if (group) {
      acc.set(group.code, group);
    }
    return acc;
  }, new Map<string, SelectedGroup>());
  return Array.from(groupsMap.values());
};

export const getScopeModules = ({
  scope,
  metricGroups,
}: {
  scope: Scope;
  metricGroups: Pick<MetricGroup, '_id' | 'groupName' | 'universalTrackers'>[];
}) =>
  SCOPES.reduce((acc, key) => {
    const scopeGroup = getSurveyScopeInfo({ codes: scope[key], type: key, metricGroups });
    return [...acc, ...scopeGroup];
  }, [] as SelectedGroup[]);

export const getModulesFromScope = (scope: Partial<Scope>): string[] => {
  const modules = Object.values(scope)
    .map((items) => items)
    .flat();
  const unifiedModules = new Set<string>(modules);
  return Array.from(unifiedModules);
};

export const getScopesDifferent = (scope1: Scope, scope2: Scope) => {
  return (Object.keys(scope2) as (keyof Scope)[]).reduce((acc, key) => {
    acc[key] = scope2[key].filter((code) => !scope1[key].includes(code));
    return acc;
  }, {} as Scope);
};
