import UniversalTracker from '@models/UniversalTracker';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { DisaggregationResponse } from '@api/disaggregation';
import { ColumnDef } from '@tanstack/react-table';
import { HistoryData, ValueAggregation, valueAggregationMap } from '@g17eco/types/disaggregation';
import { MinimalUtrv, UniversalTrackerValueView } from '@features/universal-tracker-value';
import { Table } from '@g17eco/molecules';
import { Button } from 'reactstrap';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { useHistory } from 'react-router-dom';
import { DATE, formatDateUTC } from '@utils/date';
import { getPeriodName } from '@utils/universalTracker';
import { useAppSelector } from '../../../../reducers';
import { getInitiativeTree } from '@selectors/initiativeTree';
import { getBranchInitiativeNameText } from '@routes/initiative-structure/utils';

interface Props {
  utr?: UniversalTracker;
  utrv?: SurveyModelMinimalUtrv;
  data?: DisaggregationResponse;
}

export const AggregatedBreakdownDetail = (props: Props) => {
  const { utr, utrv, data } = props;
  const history = useHistory();
  const tree = useAppSelector(getInitiativeTree);

  if (!utr || !utrv || !data) {
    return null;
  }

  const columns: ColumnDef<HistoryData>[] = [
    {
      header: 'Subsidiary',
      cell: ({ row }) => {
        return getBranchInitiativeNameText({
          initiativeTree: tree,
          initiativeId: row.original.initiativeId,
          showInitiativeId: true,
        });
      },
    },
    {
      header: 'Date',
      cell: ({ row }) => formatDateUTC(row.original.date ?? '', DATE.MONTH_YEAR_SHORT),
    },
    {
      header: 'Period',
      cell: ({ row }) => getPeriodName(row.original.period),
    },
    {
      header: 'Answer',
      cell: ({ row }) => {
        if (row.index !== 0 && data.valueAggregation === ValueAggregation.LatestAggregator) {
          return <div className='text-danger'>Ignore</div>;
        }
        const minimalUtrv: MinimalUtrv = {
          _id: row.original.utrvId,
          status: utrv.status,
          value: row.original.value,
          valueData: row.original.valueData,
        };
        return <UniversalTrackerValueView utr={utr} utrv={minimalUtrv} valueType={utr.getValueType()} />;
      },
    },
    {
      id: 'view',
      header: '',
      cell: ({ row }) => {
        if (!row.original.surveyId) {
          return null;
        }
        const surveyPath = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
          initiativeId: row.original.initiativeId,
          surveyId: row.original.surveyId,
          page: 'question',
        });
        return (
          <Button color='link' onClick={() => history.push({ pathname: `${surveyPath}/${row.original.utrvId}` })}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <p>Aggregation calculation: {valueAggregationMap[data.valueAggregation]}</p>
      <Table responsive={true} columns={columns} data={data.disaggregatedData} />
    </>
  );
};
