import { getMetricUnit, getTextColor } from './utils';
import { GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { getChartDataByValues, getValueWithFallback, sortUtrvByDate } from '@utils/charts';
import { isNumeric } from '@utils/number';
import NumberFormat from '@utils/number-format';
import { Note } from '@routes/custom-dashboard/items/charts/common/Note';
import Chart from 'react-google-charts';
import { ChartType, UtrVariable } from '@routes/summary/insights/utils/constants';
import { getSparklineChartProps } from '@routes/summary/insights/utils/helpers';
import { TakenFromText } from '@routes/custom-dashboard/items/charts/common/TakenFromText';
import { UtrvType } from '@constants/status';
import { HistoricalGenerated, IntegrationData } from '@g17eco/types/integration';

type Props = {
  integrationData: IntegrationData | undefined;
  hasSparkLine?: boolean;
  item: Pick<GridDashboardChartItem, 'calculation' | 'unitText' | 'variables' | 'note'>
};


interface LatestValueData {
  utrsData: HistoricalGenerated[];
  variables: Record<string, UtrVariable>;
}

const getLatestValueData = ({ utrsData, variables }: LatestValueData) => {

  const [variable] = Object.values(variables);
  const utrData = variable ? utrsData.find((data) => data.utr.code === variable.code) : undefined;

  if (!utrData) {
    return {
      variablesWithValues: {},
    };
  }

  const chartData = getChartDataByValues(utrData.utrvs, variable.valueListCode);

  const sortedUtrvs = utrData.utrvs.slice().sort(sortUtrvByDate).reverse();
  const latestActualUtrv = sortedUtrvs.find((utrv) => utrv.type === UtrvType.Actual);

  const variablesWithValues = {
    [variable.code]: latestActualUtrv ? getValueWithFallback(
      latestActualUtrv,
      variable.valueListCode,
      { isMultiRowsTable: false, fallback: '' }
    ) : '',
  };

  return {
    actualValue: variablesWithValues[variable.code] || '',
    period: latestActualUtrv?.period || '',
    effectiveDate: latestActualUtrv?.effectiveDate || '',
    variablesWithValues,
    chartData,
  };

}

/**
 * WIP - A single widget until the direction of generated utr/utrvs is clear
 */
export const SingleValueIntegration = (props: Props) => {
  const { integrationData, item, hasSparkLine = false } = props;

  const { calculation, unitText, variables, note, } = item;

  if (!integrationData?.utrsData.length || !variables) {
    return null;
  }

  const { utrsData } = integrationData;


  const metricUnit = unitText || getMetricUnit(integrationData.utrsData);
  const isSuffix = metricUnit === '%';
  const decimalPlaces = calculation?.values[0].decimalPlaces ?? 3;

  const latestValueData = getLatestValueData({ utrsData, variables });
  const { actualValue, effectiveDate, period, variablesWithValues, chartData } = latestValueData;


  const textColor = getTextColor({ actualValue, targetValue: undefined });

  return (
    <>
      <div className='w-100 h-100 d-flex flex-column justify-content-center'>
        {isNumeric(actualValue) ? (
          <NumberFormat
            className={`h1 m-0 text-center ${textColor}`}
            value={actualValue}
            prefix={isSuffix ? '' : metricUnit}
            suffix={isSuffix ? metricUnit : ''}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <div className='h1 m-0 text-center text-ThemeHeadingLight'>-</div>
        )}
        {note ? (
          <Note note={note} variablesWithValues={variablesWithValues} className='text-center text-ThemeTextLight' />
        ) : null}
      </div>
      {hasSparkLine ? (
        <Chart
          height={'100%'}
          chartType={ChartType.LineChart}
          data={chartData}
          options={getSparklineChartProps({ isRestated: false })}
        />
      ) : null}
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
