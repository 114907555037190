import Chart from 'react-google-charts';
import { HistoricalUtrs } from '../../../../../api/insights';
import {
  CalculationType,
  ChartDataLoaded,
  GridDashboardChartItem
} from '../../../../../types/insight-custom-dashboard';
import { tryCalculation } from '@utils/formula';
import { getChartOptions } from '@routes/summary/insights/utils/chartStyles';
import { ChartType } from '../../../../summary/insights/utils/constants';
import { getActualUtrvsGroupByDate, getSumOfVariables, getVariablesWithValues, getVAxisTitle } from './utils';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { useEffect, useState } from 'react';
import { generateGroupedStageData, getActualUtrsDataGrouped } from '@services/aggregation/utrData';
import { DATE } from '@utils/date';
import { getChartMaxValue } from '../../../../../utils/charts';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'unitText'> & {
  utrsData: HistoricalUtrs[];
  overrideOptions?: ReactGoogleChartProps['options'];
};

const getChartData = async ({
  utrsData,
  variables,
  calculation,
}: Required<Pick<Props, 'calculation' | 'variables' | 'utrsData'>>) => {
  const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData);

  const fallback = 0;

  let chartData: (string | number)[][] = [];
  switch (calculation.type) {
    case CalculationType.Stages: {
      chartData = await Promise.all(getActualUtrsDataGrouped(utrsData, DATE.MONTH_YEAR_SHORT).map(async ({ date, utrsData }) => {
        return generateGroupedStageData({
          fallback,
          date,
          calculation,
          variables,
          utrsData
        });
      }));
      break;
    }
    case CalculationType.Sum:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });

        return [date, getSumOfVariables(variablesWithValues)];
      });

      break;
    case CalculationType.Percentage:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });
        const sum = getSumOfVariables(variablesWithValues);

        return [
          date,
          ...calculation.values.map((value) => {
            const calculatedValue = tryCalculation({
              formula: value.formula || '',
              variables: variablesWithValues,
              fallback,
            });

            return (100 * calculatedValue) / sum;
          }),
        ];
      });

      break;
    case CalculationType.Formula:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });

        return [
          date,
          ...calculation.values.map((value) => {
            return tryCalculation({
              formula: value.formula || '',
              variables: variablesWithValues,
              fallback,
            });
          }),
        ];
      });

      break;
  }

  const linesNames = calculation.values.map((value) => value.name);
  chartData.unshift(['Time', ...linesNames]);

  return { chartData };
};

export const LineChart = ({ utrsData, variables, calculation, unitText, overrideOptions }: Props) => {
  const [data, setData] = useState<ChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation })
        .then(data => setData(data))
    }
  }, [calculation, utrsData, variables]);

  if (!calculation || !data?.chartData.length) {
    return null;
  }

  const vAxisTitle = getVAxisTitle({ unitText, calculation, utrsData });
  const vAxisMaxValue = getChartMaxValue(data?.chartData);

  return (
    <div className='w-100 h-100'>
      <Chart
        chartType={ChartType.LineChart}
        data={data.chartData}
        width={'100%'}
        height={'100%'}
        options={
          overrideOptions ??
          getChartOptions({
            chartType: ChartType.LineChart,
            vAxisTitle,
            vAxisMaxValue,
          })
        }
      />
    </div>
  );
};
